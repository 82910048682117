<template>
  <div>
    <l-polygon
      v-for="(area, index) in activeRegions"
      :key="index"
      :lat-lngs="area.points"
      :color="getColor(index)"
      :fill-color="getColor(index)"
    >
      <l-popup v-if="popup">
        <div class="delivery-area-tooltip">
          <div class="delivery-area-tooltip__title">{{ area.name }}</div>
          <DeliveryAreaPricelist :delivery-area="area" />
        </div>
      </l-popup>
    </l-polygon>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { LPolygon, LPopup } from 'vue2-leaflet'
import { DELIVERY_AREA_COLORS } from '@/common/constants'
import DeliveryAreaPricelist from './DeliveryAreaPricelist'

export default {
  name: 'Regions',
  components: {
    LPolygon,
    LPopup,
    DeliveryAreaPricelist,
  },
  props: {
    popup: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      DELIVERY_AREA_COLORS,
    }
  },
  computed: {
    ...mapGetters({
      currency: 'currency',
      regions: 'deliveryAreas/regions',
      activeRegions: 'deliveryAreas/activeRegions',
    }),
  },
  mounted() {
    if (this.regions.length === 0) this.getRegions({ loading: true })
  },
  methods: {
    ...mapActions('deliveryAreas', {
      getRegions: 'getRegions',
    }),
    getColor(index) {
      if (index >= this.DELIVERY_AREA_COLORS.length) return 'rgba(0,0,0,0.5)'
      return this.DELIVERY_AREA_COLORS[index]
    },
  },
}
</script>
<style lang="scss">
.delivery-area-tooltip {
  &__title {
    font-weight: 600;
  }
}
</style>