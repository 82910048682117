<template>
  <ul class="list list-style-none">
    <li class="list__item">
      <div class="font-weight-bold">{{ $t('message.navMain.papuHelp') }}</div>

      <div>Tel: <a href="tel:+48616006825">+48 616 006 825</a></div>
      <div>E-mail: <a href="mailto:help@papu.io">help@papu.io</a></div>
    </li>

    <li v-if="!onlyCs" class="list__item">
      <div class="font-weight-bold mt-3">Michał Strecker</div>
      <div>CTO</div>
      <div>
        <div>Tel: <a href="tel:+48881401106">+48 881 401 106</a></div>
        <div>E-mail: <a href="mailto:m.strecker@papu.io">m.strecker@papu.io</a></div>
      </div>
    </li>

    <li v-if="!onlyCs" class="list__item">
      <div class="font-weight-bold mt-3">Sebastian Woźniak</div>
      <div>CEO</div>
      <div>
        <div>Tel: <a href="tel:+48881228541">+48 881 228 541</a></div>
        <div>E-mail: <a href="mailto:s.wozniak@papu.io">s.wozniak@papu.io</a></div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'HelpData',
  props: ['onlyCs'],
}
</script>
