<template>
  <div class="widget">
    <div class="widget-body-wrapper">
      <b-row>
        <b-col cols="12" md="6">
          <b-row>
            <b-col>
              <form>
                <div class="form-group">
                  <b-row>
                    <b-col>
                      <b-form-input
                        id="customer-phone-number"
                        v-model="customerPhone"
                        v-validate="validatePhone"
                        name="phoneNumber"
                        :class="customerPickupType === PICKUP_TYPE_DELIVERY && `required-input`"
                        :placeholder="$t('message.orderFormFull.phoneNumber')"
                        autocomplete="off"
                        @input="findCustomersByPhone"
                      >
                      </b-form-input>
                      <div
                        v-if="findCustomersLoading"
                        class="position-absolute d-flex align-items-center"
                        style="top: 0; right: 40px; height: 100%"
                      >
                        <b-spinner small variant="primary" class="mr-2"></b-spinner>
                        <span class="text-primary text-xs">{{ $t('searching_customers') }}</span>
                      </div>
                      <span v-if="customerPickupType === PICKUP_TYPE_DELIVERY" class="text-danger ml-2">*</span>
                      <FoundCustomers @select="setCustomerData" />
                    </b-col>
                  </b-row>
                  <input-error-message :_errors="errors" field="phoneNumber" :small="false"></input-error-message>
                </div>

                <div id="customer-address-form">
                  <b-row>
                    <b-col :class="{ 'mb-3': !errors.first('customerAddress') }">
                      <div>
                        <b-form-input
                          id="customer-address"
                          ref="customerAddressInput"
                          v-model="customerAddress"
                          v-validate="validateAddress"
                          type="text"
                          name="customerAddress"
                          autocomplete="chrome-off"
                          :class="validateAddress && `required-input`"
                          :placeholder="$t('message.orderFormFull.address')"
                          :disabled="[PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT].includes(cart.customer.pickupType)"
                          @blur="(event) => checkAreaListenerCallback(event)"
                        />
                        <span v-if="validateAddress" class="text-danger ml-2">*</span>
                        <AddressHint
                          :prompt="customerAddress"
                          @select="setAddressFromHint"
                          @toggle="(visible) => (addressHintVisible = visible)"
                        />
                      </div>
                      <input-error-message :_errors="errors" field="customerAddress" :small="false" />
                    </b-col>
                    <b-col v-if="cityFieldVisible" lg="4">
                      <b-form-input
                        id="customer-city"
                        v-model="customerCity"
                        v-validate="validateCity"
                        name="customerCity"
                        class="mb-3 lg:mb-0"
                        type="text"
                        :disabled="[PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT].includes(cart.customer.pickupType)"
                        :placeholder="getLocalizationCity"
                      />
                      <input-error-message :_errors="errors" field="customerCity" :small="true" />
                    </b-col>
                  </b-row>
                </div>
                <b-alert ref="areaWarning" variant="warning" :show="!!areaWarning">
                  {{ areaWarning }}
                </b-alert>
                <div>
                  <b-form-textarea
                    id="customer-address-comment"
                    v-model="customerAddressComment"
                    :placeholder="$t('message.orderFormFull.addressComment')"
                  >
                  </b-form-textarea>
                </div>
                <b-row class="mt-3">
                  <b-col cols="8">
                    <b-form-group :label="$t('message.cart.deliveryArea')">
                      <div v-if="deliveryAreaLoading" class="d-flex align-items-center">
                        <b-spinner small variant="primary" class="mr-2"></b-spinner>
                        <span class="text-primary text-xs">{{ $t('searching_delivery_area') }}</span>
                      </div>
                      <div v-else-if="deliveryArea" class="d-flex justify-content-between">
                        <span :class="{ 'delivery-area--changed': deliveryAreaChanged }">{{ deliveryArea.name }}</span>
                        <div v-if="deliveryArea.deliveryPrice" class="ml-1">
                          <b-button
                            id="clear-delivery-price-btn"
                            size="sm"
                            variant="outline-primary"
                            :disabled="deliveryPriceFromArea === cart.deliveryPrice"
                            class="text-nowrap"
                            @click="clearDeliveryPrice"
                          >
                            {{ deliveryPriceFromArea + ' ' + currency }} <i class="fas fa-arrow-right ml-1"></i>
                          </b-button>
                          <TooltipButton id="deliveryPriceFromArea" button-class="defaultTooltipButton" class="ml-1"
                            >?
                            <template #tooltipText>{{ $t('message.orderFormFull.deliveryPriceFromArea') }}</template>
                          </TooltipButton>
                        </div>
                      </div>

                      <div v-else-if="!deliveryArea" class="no-delivery-area">
                        {{ $t('message.orderFormFull.noDeliveryArea') }}
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <price-input
                      id="delivery-price-input"
                      :value="cart.deliveryPrice"
                      :label="$t('message.cart.deliveryPrice')"
                      class="mb-3"
                      :class="{ 'delivery-price--changed': deliveryPriceChanged }"
                      :disabled="[PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT].includes(cart.customer.pickupType)"
                      :append-text="currency"
                      @blur="setManualDeliveryPrice"
                    >
                    </price-input>
                  </b-col>
                </b-row>
                <b-form-input
                  id="customer-name"
                  ref="customerNameInput"
                  v-model="customerName"
                  type="text"
                  class="mb-3"
                  :placeholder="$t('message.orderFormFull.name')"
                >
                </b-form-input>
                <b-form-input
                  v-if="hasFiscal"
                  id="customer-tax-id"
                  v-model="customerTaxID"
                  type="text"
                  class="mb-3"
                  :placeholder="$t('message.orderFormFull.customerTaxID')"
                  @input="customerTaxIDformatter('customerTaxID')"
                >
                </b-form-input>
              </form>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="6">
          <b-row class="mb-3">
            <b-col>
              <source-change
                v-model="menuSource"
                :change-menu-source="changeMenuSource"
                :response-errors="responseErrors"
              />
            </b-col>
          </b-row>
          <div class="section p-0 mb-3" :class="{ 'section--error': errors.first('pickupType') }">
            <div class="section-header">
              <div class="section-header__title">
                {{ $t('message.orderFormFull.pickupType') }}<span class="text-danger ml-1">*</span>
              </div>
            </div>
            <div class="section-body">
              <div class="btn-group btn-group-toggle btn-group-justified">
                <label
                  v-for="pickupType in pickupTypes"
                  :key="'pickupType' + pickupType.value"
                  class="btn btn-light btn-lg"
                  :class="{ active: pickupType.value === cart.customer.pickupType }"
                >
                  <input
                    :id="'pickupType' + pickupType.value"
                    v-model="customerPickupType"
                    v-validate="validatePickupType"
                    type="radio"
                    name="pickupType"
                    :value="pickupType.value"
                  />
                  <i v-if="pickupType.value === PICKUP_TYPE_ON_SITE" class="fas fa-home"></i>
                  <i v-else-if="pickupType.value === PICKUP_TYPE_TAKOUT" class="fas fa-sign-out-alt"></i>
                  <i v-else-if="pickupType.value === PICKUP_TYPE_DELIVERY" class="fas fa-truck"></i>
                  <span>{{ pickupType.name }}</span>
                </label>
              </div>
              <input-error-message :_errors="errors" field="pickupType" :small="false" />
            </div>
          </div>
          <div
            v-if="
              (cart.customer.pickupType === PICKUP_TYPE_DELIVERY || cart.customer.paymentType !== '') && !hasSplitBills
            "
            class="section p-0"
            :class="{ 'section--error': errors.first('paymentType') }"
          >
            <div class="section-header">
              <div class="section-header__title">
                {{ $t('message.invoiceModal.paymentType')
                }}<span v-if="customerPickupType === PICKUP_TYPE_DELIVERY" class="text-danger ml-1">*</span>
              </div>
            </div>
            <div class="section-body">
              <div class="btn-group btn-group-toggle btn-group-justified">
                <label
                  v-for="paymentType in getPaymentTypes"
                  :key="'paymentType' + paymentType.value"
                  class="btn btn-light btn-lg"
                  :class="{ active: paymentType.value === cart.customer.paymentType }"
                  @click.prevent.stop="paymentClick(paymentType)"
                >
                  <input
                    :id="'paymentType' + paymentType.value"
                    v-model="customerPaymentType"
                    type="radio"
                    name="paymentType"
                    :value="paymentType.value"
                  />
                  <i v-if="paymentType.value === 'cash'" class="fas fa-money-bill-wave"></i>
                  <i v-else-if="paymentType.value === 'card'" class="fas fa-credit-card"></i>
                  <i v-else-if="paymentType.value === 'paid'" class="fas fa-clipboard-check"></i>
                  <span>{{ paymentType.name }}</span>
                </label>
              </div>
              <input-error-message
                v-if="cart.customer.pickupType === PICKUP_TYPE_DELIVERY"
                :_errors="errors"
                field="paymentType"
                :small="false"
              />
            </div>
          </div>
          <b-row>
            <b-col class="mt-3 mb-3">
              <b-form-checkbox
                v-if="hasFiscal && customerPickupType === PICKUP_TYPE_DELIVERY && !isEdit"
                id="print-bill-checkbox"
                v-model="printBill"
                button
                name="printBill"
                button-variant="outline-primary"
              >
                <i v-if="!printBill" class="far fa-square"></i>
                <i v-else class="fas fa-check-square"></i>
                <span>{{ $t('message.orderFormFull.printBill') }}</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="row mb-2">
        <b-col v-if="!isEdit" cols="12">
          <div class="section-header__title mb-2">{{ $t('message.orderFormFull.deliveryType') }}</div>
          <div class="btn-group btn-group-toggle btn-group-justified">
            <label
              v-for="deliveryType in deliveryTypes"
              :key="'deliveryType' + deliveryType.value"
              class="btn btn-light btn-lg"
              :class="{ active: deliveryType.value === cart.customer.deliveryType }"
            >
              <input
                :id="'deliveryType' + deliveryType.value"
                v-model="customerDeliveryType"
                type="radio"
                name="deliveryType"
                :value="deliveryType.value"
              />
              <i v-if="deliveryType.value === 'asap'" class="fas fa-shipping-fast"></i>
              <i v-else-if="deliveryType.value === 'ontime'" class="far fa-clock"></i>
              <span>{{ deliveryType.name }}</span>
            </label>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="section mb-2"
          :class="{
            'section--error':
              errors.first('customerPromisedTime') ||
              errors.first('customerPromisedTimeValue') ||
              responseErrors['promised_time'],
          }"
        >
          <div class="section-header__title mb-2">
            {{ $t('message.orderFormFull.promisedTime') }}<span class="text-danger ml-1">*</span>
            <span
              v-if="getPredictionsRequest && $hasModuleAccess('show-promised-time-prediction')"
              class="text-primary ml-3"
            >
              <b-spinner small></b-spinner>
              {{ $t('message.promisedTime.predictingPromisedTime') }}
            </span>
          </div>
          <b-row>
            <b-col cols="12">
              <PromisedTime
                :pick-time="isPromisedTimePickTime"
                :custom-promised-time="customerPromisedTime"
                :is-edit="isEdit"
                :estimated-delivery-time="estimatedDeliveryTime"
                @input="changePromisedTime"
                @pickerShown="submittedButtonDisabled = $event"
              ></PromisedTime>
            </b-col>
          </b-row>
          <input-error-message :_errors="errors" field="customerPromisedTime" :small="false" />
          <input-error-message :_errors="errors" field="customerPromisedTimeValue" :small="false" />
          <response-error-msg field="promised_time" :response-errors="responseErrors" />
        </b-col>
      </b-row>
      <Cart ref="cart" :full="true" :cart="cart" />
      <p v-if="errors.first('emptyCart')" class="text-danger">{{ $t('message.orderFormFull.emptyCart') }}</p>
      <AdditionalFeesForm />
    </div>
    <OrderFormFooter
      :response-errors="responseErrors"
      :submitted-button-disabled="submittedButtonDisabled"
      :total-price="orderTotalPrice"
      :on-confirm="placeOrUpdateOrder"
      :is-edit="isEdit"
      :is-loading="placeOrderLoading"
    />
  </div>
</template>

<script>
import {
  PICKUP_TYPE_ON_SITE,
  PICKUP_TYPE_TAKOUT,
  PICKUP_TYPE_DELIVERY,
  MENU_SOURCE_CHOICES,
  MENU_SOURCE_COLORS,
  DELIVERY_TYPES,
  DISCOUNT_CHOICES,
} from '@/common/constants'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'
import { mapFields } from 'vuex-map-fields'
import { responseErrorsHandler, printBillMixins, taxIdMixins, menuSourcesMixins } from '@/mixins'
import Decimal from 'decimal.js'
import PriceInput from '@/components/PriceInput'
import InputErrorMessage from '@/components/InputErrorMessage'
import PromisedTime from '@/components/PromisedTime'
import TooltipButton from '@/components/TooltipButton'
import SourceChange from '@/components/source-change/SourceChange'
import Cart from '@/components/cart/Cart'
import AdditionalFeesForm from './AdditionalFeesForm'
import OrderFormFooter from './OrderFormFooter'
import AddressHint from './AddressHint'
import FoundCustomers from './FoundCustomers.vue'
const STORE = '_orderEdit'

export default {
  name: 'OrderFormFull',
  components: {
    SourceChange,
    TooltipButton,
    InputErrorMessage,
    Cart,
    PriceInput,
    PromisedTime,
    AdditionalFeesForm,
    OrderFormFooter,
    AddressHint,
    FoundCustomers,
  },
  mixins: [responseErrorsHandler, printBillMixins, taxIdMixins, menuSourcesMixins],
  data: function () {
    return {
      submittedButtonDisabled: false,
      MENU_SOURCE_COLORS: MENU_SOURCE_COLORS,
      PICKUP_TYPE_ON_SITE: PICKUP_TYPE_ON_SITE,
      PICKUP_TYPE_TAKOUT: PICKUP_TYPE_TAKOUT,
      PICKUP_TYPE_DELIVERY: PICKUP_TYPE_DELIVERY,
      DELIVERY_TYPES: DELIVERY_TYPES,
      hidden: false,
      focusedFoundCustomer: -1,
      deliveryAreaChanged: false,
      deliveryPriceChanged: false,
      pickupTypes: [
        { name: this.$t('message.orderFormFull.pickupTypes.onSite'), value: PICKUP_TYPE_ON_SITE },
        { name: this.$t('message.orderFormFull.pickupTypes.takeaway'), value: PICKUP_TYPE_TAKOUT },
        { name: this.$t('message.orderFormFull.pickupTypes.delivery'), value: PICKUP_TYPE_DELIVERY },
      ],
      deliveryTypes: [
        { name: this.$t('message.orderFormFull.deliveryTypes.asap'), value: 'asap' },
        { name: this.$t('message.orderFormFull.deliveryTypes.onTime'), value: 'ontime' },
      ],
      paymentTypes: [
        { name: this.$t('message.orderFormFull.paymentTypes.cash'), value: 'cash' },
        { name: this.$t('message.orderFormFull.paymentTypes.paid'), value: 'paid' },
        { name: this.$t('message.orderFormFull.paymentTypes.card'), value: 'card' },
      ],
      placeOrderLoading: false,
      addressHintVisible: false,
      initTime: null,
      addressSetFromCustomer: false,
    }
  },
  computed: {
    ...mapState({
      baseBillId: (state) => state[STORE].baseBill?.id || null,
    }),
    ...mapGetters({
      getPredictionsRequest: 'getPredictionsRequest',
    }),
    ...mapGetters(STORE, [
      'cart',
      'isEdit',
      'courierDeliveryArea',
      'externalOrder',
      'getServiceChargePercent',
      'cartTotalPrice',
      'orderTotalPrice',
      'showOrderForm',
      'getDeliveryPriceFromArea',
      'deliveryPrice',
      'getCartDiscounts',
      'deliveryAreaLoading',
    ]),
    ...mapGetters('order', ['foundCustomers', 'findCustomersLoading', 'foundCustomersVisible']),
    ...mapGetters('auth', ['getLocalizationCity']),
    ...mapGetters(['currency', 'menu']),
    ...mapFields('_orderEdit', {
      // Plugin do mutowania stanu w store
      customerPhone: 'cart.customer.phone',
      customerAddress: 'cart.customer.address',
      customerAddressComment: 'cart.customer.addressComment',
      customerCity: 'cart.customer.city',
      customerName: 'cart.customer.name',
      customerTaxID: 'cart.customer.tax_id',
      customerPickupType: 'cart.customer.pickupType',
      customerPaymentType: 'cart.customer.paymentType',
      customerPromisedTimeValue: 'cart.customer.promisedTimeValue',
      customerPromisedTime: 'cart.customer.promisedTime',
      customerDeliveryType: 'cart.customer.deliveryType',
      menuSource: 'cart.menuSource',
      deliveryArea: 'deliveryArea',
      areaWarning: 'areaWarning',
      estimatedDeliveryTime: 'estimatedDeliveryTime',
      cartDeliveryPrice: 'cart.deliveryPrice',
      cartManualDeliveryPrice: 'cart.manualDeliveryPrice',
      cartTotalPriceChanged: 'cartTotalPriceChanged',
      deliveryAreaAndPriceChangedPopup: 'deliveryAreaAndPriceChangedPopup',
      initialAreaDeliveryPrice: 'initialAreaDeliveryPrice',
    }),
    orderData() {
      const { customer } = this.cart

      const { discountChoice, discountValue } = this.getCartDiscounts

      const address = {
        city: !this.isEdit ? customer.city || this.getLocalizationCity : customer.city,
        comment: customer.addressComment,
      }
      if (address.city) address.address = customer.address
      else address.full_address = customer.address

      let data = {
        customer: {
          name: customer.name,
          // setting phone to empty string if contains only prefix (without number)
          phone: this.customerPhone.match(/^\+\d\d$/) ? '' : customer.phone,
          address: address,
          email: customer.email,
          geocoded_by: customer.geocoded_by,
          address_processed_through_heuristics: customer.address_processed_through_heuristics,
          real_address_geolocalized: customer.real_address_geolocalized,
          distance_from_localization: customer.distance_from_localization,
        },
        company_name: customer.company_name,
        company_tax_id: customer.tax_id,
        menu: this.menu.id,
        menu_source: this.cart.menuSource,
        pickup_type: customer.pickupType,
        promised_time: customer.promisedTime, // assigning full date and time
        price: this.orderTotalPrice,
        payment: customer.paymentType ? [customer.paymentType] : [],
        comment: this.cart.comment,
        delivery_price: parseFloat(this.cart.deliveryPrice),
        package_price: this.cart.packagePrice ? this.priceDecimal(this.cart.packagePrice) : '0.00',
        discount_choice: discountChoice,
        discount_value: discountValue,
        discount_code: this.cart.discountCodeApplied && this.cart.discountCode ? this.cart.discountCode : null,
        meals_json: this.cart.meals,
        delivery_type: customer.deliveryType,
        service_charge_percent: this.getServiceChargePercent,
        service_charge_value: this.cart.serviceChargeValue,
        lat: this.cart.lat,
        lon: this.cart.lon,
        predicted_promised_time: this.cart.predictedPromisedTime,
        print_bill: this.isEdit ? false : this.printBill,
      }
      return data
    },
    validateAddress() {
      return [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT].includes(this.customerPickupType) ? '' : 'required'
    },
    validateCity() {
      return 'regex:^[a-zA-Z0-9ąĄćĆęĘłŁńŃóÓśŚżŻźŹ -]+$'
    },
    validatePickupType() {
      return this.pickupTypes.some((pickupType) => {
        return pickupType.value === this.customerPaymentType
      })
        ? ''
        : 'required'
    },
    validatePaymentType() {
      return this.paymentTypes.some((paymentType) => {
        return paymentType.value === this.customerPaymentType
      }) || this.customerPickupType !== PICKUP_TYPE_DELIVERY
        ? ''
        : 'required'
    },
    validateCustomerPromisedTime() {
      return (this.customerDeliveryType === DELIVERY_TYPES.ONTIME && this.customerPromisedTime === '') ||
        (this.customerDeliveryType === DELIVERY_TYPES.ASAP && this.customerPromisedTimeValue === '')
        ? 'required'
        : ''
    },
    validatePhone() {
      return {
        required: this.customerPickupType === PICKUP_TYPE_DELIVERY,
        regex: /^([+0-9]+)$/,
        // minimum length validation for delivery or phone which doesn't contain only prefix
        min:
          this.customerPhone &&
          (this.customerPickupType === PICKUP_TYPE_DELIVERY || !this.customerPhone.match(/^\+\d\d$/))
            ? this.customerPhone[0] === '+'
              ? 12
              : 9
            : '',
      }
    },
    validateCart() {
      return this.cart.meals.length > 0
    },
    getPaymentTypes() {
      // Filters paymentTypes list dependent on chosen menuSource
      if (this.menuSource === MENU_SOURCE_CHOICES.UBEREATS) {
        return this.paymentTypes.filter(function (paymentType) {
          if (['paid'].includes(paymentType['value'])) {
            return paymentType
          }
        })
      } else {
        return this.paymentTypes
      }
    },
    deliveryPriceFromArea() {
      return this.deliveryArea ? this.getDeliveryPriceFromArea : null
    },
    areaWarning() {
      // Compute areaWarning message
      let areaWarning = ''
      if (this.deliveryArea) {
        if (this.deliveryArea === 'none') areaWarning = this.$t('message.orderFormFull.noDeliveryArea')
        else if (this.deliveryArea === 'outside') areaWarning = this.$t('message.orderFormFull.outOfDeliveryArea')
        else if (this.deliveryArea === 'processing') areaWarning = ''
        else if (
          this.deliveryArea.minOrderValue !== null &&
          Decimal(this.deliveryArea.minOrderValue).greaterThan(Decimal(this.cartTotalPrice))
        ) {
          areaWarning =
            this.$t('message.orderFormFull.minValue') +
            ` ${(this.deliveryArea.minOrderValue - this.cartTotalPrice).toFixed(2)} ${this.currency}`
        } else if (
          this.deliveryArea.freeDeliveryFromValue !== '0.00' &&
          this.deliveryArea.freeDeliveryFromValue !== null &&
          Decimal(this.deliveryArea.freeDeliveryFromValue).greaterThan(Decimal(this.cartTotalPrice))
        ) {
          areaWarning =
            this.$t('message.orderFormFull.minValueFrom') +
            ` ${(this.deliveryArea.freeDeliveryFromValue - this.cartTotalPrice).toFixed(2)} ${this.currency}`
        } else areaWarning = ''
      }
      return areaWarning
    },
    isPromisedTimePickTime() {
      return this.isEdit || (!this.isEdit && this.customerDeliveryType === DELIVERY_TYPES.ONTIME)
    },
    hasSplitBills() {
      return this.$route.params.hasSplitBills || false
    },
    hasFreeDeliveryDiscount() {
      const { discountChoice } = this.getCartDiscounts
      return discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_FREE_DELIVERY
    },
    cityFieldVisible() {
      if (this.menuSource === MENU_SOURCE_CHOICES.DEFAULT) {
        return true
      } else {
        return this.customerCity.length > 0 || !this.isEdit
      }
    },
  },
  watch: {
    deliveryPriceFromArea: function (newValue, oldValue) {
      // firing modal to overwrite deliveryPrice when deliveryArea price has changed due to e.g. reaching free delivery
      if (
        ![null, 'processing'].includes(oldValue) &&
        ![null, 'processing'].includes(newValue) &&
        this.isEdit &&
        this.cartManualDeliveryPrice !== null &&
        this.deliveryArea &&
        this.deliveryArea.deliveryPrice &&
        this.cartManualDeliveryPrice !== newValue &&
        this.initialAreaDeliveryPrice !== null &&
        newValue !== this.initialAreaDeliveryPrice &&
        this.cartDeliveryPrice !== null &&
        !this.hasFreeDeliveryDiscount
      ) {
        this.deliveryPriceFromAreaChangedModal()
      }
    },
    cartDeliveryPrice: function (newValue, oldValue) {
      // sets cartDeliveryPrice - only when it's null - to deliveryPrice compute result
      if (newValue === null) this.cart.deliveryPrice = this.deliveryPrice
      // indicate change of deliveryPrice if price not set manually
      else if (this.cart.manualDeliveryPrice === null && oldValue !== null) {
        this.deliveryPriceChanged = true
        setTimeout(() => {
          this.deliveryPriceChanged = false
        }, 2000)
      }
    },
    deliveryPrice: function (newValue) {
      // Sets cartDeliveryPrice whenever new value is calculated
      this.cart.deliveryPrice = newValue
      if (this.hasFreeDeliveryDiscount) this.cart.discountValue = newValue
    },
    foundCustomersVisible: function (newValue) {
      // disables enter functionality for placeing/updating order when customers list is visible
      this.submittedButtonDisabled = this.foundCustomersVisible
    },
    customerPickupType: function (newPickupType) {
      this.customerAddress = ''
      if ([PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT].includes(newPickupType)) {
        // if pickup_type changed to on_site/pickup to delivery - should remove deliveryArea data
        // also EstimatedDeliveryTime should be cleaned
        this.estimatedDeliveryTime = NaN
        this.deliveryArea = null
        this.printBill = false
      }
      if ([PICKUP_TYPE_DELIVERY].includes(newPickupType)) {
        this.cart.serviceChargePercent = ''
        this.cart.serviceChargeValue = '0.00'
      }
    },
    deliveryAreaAndPriceChangedPopup: function (newValue) {
      if (newValue && !this.hasFreeDeliveryDiscount) this.showDeliveryAreaAndPriceChangedModal()
    },
    deliveryArea: function (newValue, oldValue) {
      // indicate deliveryArea change when there was not area before
      if (newValue && newValue !== oldValue && oldValue === 'processing') this.indicateDeliveryAreaChange()
    },
    addressHintVisible: function (visible) {
      if (!visible) {
        this.$refs.customerAddressInput.focus()
      }
    },
  },
  mounted() {
    this.initTime = new Date().getTime()

    if (this.cartTotalPriceChanged) {
      this.setEstimatedDeliveryTime(null)
      this.getPredictions()
    }
    // this is run every time user enters orderFormFull view
    if (this.cart.deliveryPrice === null) this.cart.deliveryPrice = this.deliveryPrice
    if (sessionStorage.getItem('callerID') && this.customerAddress === '') {
      this.findCustomersByPhone()
    }
    // if edited delivery order, fetch deliveryArea data from order
    if (this.deliveryArea === null && this.isEdit && this.customerPickupType === PICKUP_TYPE_DELIVERY) {
      let areaData = this.courierDeliveryArea
      if (areaData) {
        this.setDeliveryArea(areaData)
        // InitialDeliveryArea computed price
        if (this.initialAreaDeliveryPrice === null) {
          // if cart price reached free delivery value
          if (
            this.deliveryArea.freeDeliveryFromValue &&
            Decimal(this.deliveryArea.freeDeliveryFromValue).lessThanOrEqualTo(Decimal(this.cartTotalPrice))
          ) {
            this.initialAreaDeliveryPrice = Decimal('0').toFixed(2)
            // otherwise set price from area data
          } else this.initialAreaDeliveryPrice = this.deliveryArea.deliveryPrice
        }
      }
    }
    // firing modal to overwrite deliveryPrice when deliveryArea price has changed due to e.g. reaching free delivery
    if (
      this.cartTotalPriceChanged &&
      this.isEdit &&
      this.cartManualDeliveryPrice !== null &&
      this.initialAreaDeliveryPrice !== null &&
      this.initialAreaDeliveryPrice !== this.deliveryPriceFromArea &&
      !this.hasFreeDeliveryDiscount
    ) {
      this.deliveryPriceFromAreaChangedModal()
    }
    if (this.cartTotalPriceChanged && !this.edit) {
      this.cart.deliveryPrice = null
      this.cartTotalPriceChanged = false
    }
    // show modal to overwrite deliveryPrice if deliveryArea and it's price changed, but didn't show modal yet
    if (this.deliveryAreaAndPriceChangedPopup && !this.hasFreeDeliveryDiscount)
      this.showDeliveryAreaAndPriceChangedModal()
    // attaching initial validation rules
    this.$validator.attach({ name: 'paymentType', rules: this.validatePaymentType })
    this.$validator.attach({ name: 'customerPromisedTime', rules: this.validateCustomerPromisedTime })
    this.customerPaymentType = Array.isArray(this.customerPaymentType)
      ? this.customerPaymentType[0]
      : this.customerPaymentType
  },
  methods: {
    ...mapMutations({
      setPromisedTime: '_orderEdit/setPromisedTime',
      setMenuSource: '_orderEdit/setMenuSource',
      setPaymentType: '_orderEdit/setPaymentType',
      setEstimatedDeliveryTime: '_orderEdit/setEstimatedDeliveryTime',
    }),
    ...mapActions('_orderEdit', [
      'updateServiceChargeValue',
      'getPredictions',
      'setDeliveryArea',
      'checkArea',
      'clearDeliveryPrice',
      'getBills',
    ]),
    ...mapActions('order', ['findCustomers']),
    deliveryPriceFromAreaChangedModal() {
      // modal asking to overwrite deliveryPrice with area's deliveryPrice
      // should fire on edit when computed deliveryArea changed during update due to e.g. reached free delivery
      this.submittedButtonDisabled = true
      this.$bvModal
        .msgBoxConfirm(
          this.$t('message.orderFormFull.deliveryPriceChangeDueToCart', {
            deliveryPrice: `${this.getDeliveryPriceFromArea} ${this.currency}`,
          }),
          {
            cancelTitle: this.$t('message.orderFormFull.no'),
            okTitle: this.$t('message.orderFormFull.yes'),
            centered: true,
          }
        )
        .then((result) => {
          // resets cartDeliveryPriceto be automatically computed
          if (result) {
            this.clearDeliveryPrice()
          }
          this.submittedButtonDisabled = false
        })
    },
    changePromisedTime(promisedTimeValue) {
      // set promisedTime directly if received full date (in string) or by mutation if value
      if (typeof promisedTimeValue === 'string' && promisedTimeValue !== '')
        this.customerPromisedTime = promisedTimeValue
      else this.setPromisedTime(promisedTimeValue)
    },
    setManualDeliveryPrice(value) {
      this.clearDeliveryPrice()
      this.$nextTick(() => {
        if (value) this.cartManualDeliveryPrice = Decimal(value).toFixed(2)
        else this.cartManualDeliveryPrice = Decimal('0').toFixed(2)
      })
    },
    placeOrUpdateOrder() {
      // updates validation rules
      this.$validator.fields.find({ name: 'paymentType' }).update({ rules: this.validatePaymentType })
      this.$validator.fields.find({ name: 'customerPromisedTime' }).update({ rules: this.validateCustomerPromisedTime })
      this.$validator.validate().then((result) => {
        if (!this.validateCart) {
          this.errors.add({
            field: 'emptyCart',
            msg: 'Pusty koszyk',
          })
          result = false
        }
        if (result) {
          this.placeOrderLoading = true
          const onError = (error) => {
            const data = error.response.data
            this.submittedButtonDisabled = false
            this.setResponseErrors(data)
            if (data.discount_code || data.price) {
              this.$refs.cart.setResponseErrors(error.response.data)
            } else {
              this.showErrorToast(data)
            }
            this.placeOrderLoading = false
          }
          if (this.isEdit) {
            this.submittedButtonDisabled = true
            this.$store.dispatch('_orderEdit/updateOrder', {
              order: this.orderData,
              onSuccess: (responseData) => {
                this.placeOrderLoading = false
                gtag('event', 'edit_order', {
                  id: responseData.id,
                  time_of_creating: Math.floor((new Date().getTime() - this.initTime) / 1000),
                  address_hint_requests: this.$store.state._orderEdit.addressHintRequests,
                  address_hint_clicks: this.$store.state._orderEdit.addressHintClicks,
                  address_set_from_customer: this.addressSetFromCustomer,
                })
              },
              onError: onError,
            })
          } else {
            this.submittedButtonDisabled = true
            this.$store.dispatch('_orderEdit/placeOrder', {
              order: this.orderData,
              onSuccess: async (responseData) => {
                this.placeOrderLoading = false
                if (this.customerPickupType === PICKUP_TYPE_DELIVERY && this.printBill) {
                  this.getBillsAndPrintBill(responseData)
                }
                sessionStorage.removeItem('callerID')
                this.clearResponseErrors()

                gtag('event', 'new_order', {
                  id: responseData.id,
                  time_of_creating: Math.floor((new Date().getTime() - this.initTime) / 1000),
                  address_hint_requests: this.$store.state._orderEdit.addressHintRequests,
                  address_hint_clicks: this.$store.state._orderEdit.addressHintClicks,
                  address_set_from_customer: this.addressSetFromCustomer,
                })
              },
              onError: onError,
            })
          }
        }
      })
    },
    async getBillsAndPrintBill(data) {
      try {
        await this.getBills(data.id)
        this.printBillRequest(data.id, this.baseBillId, false)
      } catch {}
    },
    showErrorToast(data) {
      const firstKey = Object.keys(data)[0]
      this.$toasted.error(data[firstKey], {
        duration: 5000,
      })
    },
    findCustomersByPhone() {
      // gets customers data if phone's length is 4 (not counting prefix) after seconds
      const customerPhone = this.customerPhone.trim()
      if (
        (customerPhone[0] === '+' && customerPhone.length >= 7) ||
        (customerPhone[0] !== '+' && customerPhone.length >= 4)
      ) {
        if (this.customerDataTimer) {
          clearTimeout(this.customerDataTimer)
        }
        this.customerDataTimer = setTimeout(async () => {
          if (customerPhone) {
            this.findCustomers(customerPhone)
          }
        }, 1000)
      }
    },
    setCustomerData(customer) {
      console.log('setCustomerData', customer)
      this.customerPhone = customer.phone
      this.customerName = customer.name
      this.customerAddress = customer.address || customer.full_address
      if (customer.city && customer.city !== this.getLocalizationCity) {
        this.customerCity = customer.city
      }
      if (customer.lat && customer.lon) {
        this.cart.lat = customer.lat
        this.cart.lon = customer.lon
      }
      this.customerAddressComment = customer.comment || ''
      this.$refs.customerNameInput.focus()
      this.checkArea({ lat: customer.lat, lon: customer.lon })
      this.addressSetFromCustomer = true
    },
    changeMenuSource(source) {
      // if menuSource changed to 'ubereats', set paymentType to 'paid'
      if (source === parseInt(MENU_SOURCE_CHOICES.UBEREATS)) {
        this.setPaymentType('paid')
      }
      // change menuSource to source
      this.setMenuSource(source)
    },
    indicateDeliveryAreaChange() {
      this.deliveryAreaChanged = true
      setTimeout(() => {
        this.deliveryAreaChanged = false
      }, 2000)
    },
    checkAreaListenerCallback(event) {
      if (this.addressHintVisible) return
      let newTarget = event.relatedTarget
      let oldTarget = event.target
      let addressInputs = ['customer-address', 'customer-city']
      // send geolocalization request only if new target is not address related inputs (addressInputs)
      if (oldTarget && addressInputs.includes(oldTarget.id) && (!newTarget || !addressInputs.includes(newTarget.id))) {
        this.checkArea()
      }
    },
    showDeliveryAreaAndPriceChangedModal() {
      this.submittedButtonDisabled = true
      // if there was old deliveryPrice - set it as actual deliveryPrice - price from new area can be set by modal
      this.$bvModal
        .msgBoxConfirm(
          this.$t('message.orderFormFull.useNewAreaDeliveryPrice', {
            calculatedDeliveryPrice: `${this.getDeliveryPriceFromArea} ${this.currency}`,
          }),
          {
            title: this.$t('message.orderFormFull.deliveryAreaChanged'),
            okTitle: this.$t('message.orderFormFull.yes'),
            cancelTitle: this.$t('message.orderFormFull.no'),
            centered: true,
          }
        )
        .then((result) => {
          this.submittedButtonDisabled = false
          this.deliveryAreaAndPriceChangedPopup = false
          // if deliveryPrice should be overwritten from deliveryArea - set new deliveryPrice
          if (result) this.clearDeliveryPrice()
          // indicate change of deliveryArea and maybe change of deliveryPrice
          this.deliveryAreaChanged = true
          this.deliveryPriceChanged = !!result
          setTimeout(() => {
            this.deliveryAreaChanged = this.deliveryPriceChanged = false
          }, 2000)
        })
    },
    paymentClick(paymentType) {
      this.customerPaymentType = paymentType.value
    },
    setAddressFromHint(result) {
      if (!result?.street) return
      this.addressSetFromCustomer = false
      this.customerAddress = result.street
      this.customerCity = result.city
      this.$refs.customerAddressInput.focus()
    },
  },
}
</script>

<style scoped lang="scss">
.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.btn-group-justified .btn,
.btn-group-justified .btn-group {
  float: none;
  display: table-cell;
  width: 1%;
  line-height: 40px;
}
.btn-group-justified .btn .btn,
.btn-group-justified .btn-group .btn {
  width: 100%;
}
.btn-group-justified .btn .dropdown-menu,
.btn-group-justified .btn-group .dropdown-menu {
  left: auto;
}

.btn-light {
  border-color: #e5e8ea;
}

.list-group-item {
  cursor: pointer;
}
.delivery-price--changed {
  animation-name: changeIndicatorBorder;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: 4;
  border-color: transparent;
}
.delivery-area--changed {
  animation-name: changeIndicatorBackground;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: 4;
  background-color: transparent;
}
.required-input {
  display: inline-flex;
  width: calc(100% - 15px);
}

.btn-group-toggle {
  .btn-lg {
    @include media-breakpoint-down(lg) {
      padding: 0.5rem 0.2rem;
      i + span {
        white-space: nowrap;
        font-size: 13px;
      }
    }
    @include media-breakpoint-down(md) {
      padding: 0.5rem 0.1rem;
      i {
        font-size: 15px;
      }
      i + span {
        margin-left: 5px;
      }
    }
  }
}
.no-delivery-area {
  font-size: 12px;
  opacity: 0.5;
  background: #eeeef3;
  padding: 8px 12px;
  border-radius: 2px;
}
</style>
