<template>
  <b-list-group-item
    :active="isActive"
    class="custom-list-group-item list-group-item--draggable"
    @click="onClick(category)"
  >
    <div class="d-flex align-items-center">
      <i class="fas fa-align-justify fa-fw mr-3 drag-icon" :class="isActive ? 'text-white' : ''"></i>
      <span>{{ category.name }}</span>
    </div>

    <div class="d-flex align-items-center">
      <TooltipButton
        v-if="category.is_default"
        :id="'category_is_default' + category.id"
        variant="link"
        button-class="p-1"
      >
        <b-badge :variant="isActive || !category.sizes_count ? 'success' : 'success'" pill>
          {{ $t('default_a') }}
        </b-badge>

        <template #tooltipText>{{ $t('message.menu.categories.defaultTooltipText') }}</template>
      </TooltipButton>

      <TooltipButton :id="'category_sizes' + category.id" variant="link" button-class="p-1">
        <b-badge :variant="isActive || !category.sizes_count ? 'light' : 'light'" pill>
          <i class="fas fa-ruler mr-1" />
          {{ category.sizes_count }}
        </b-badge>

        <template #tooltipText>{{ $t('message.menu.sizesCount') }}</template>
      </TooltipButton>

      <TooltipButton :id="'category_meals' + category.id" variant="link" button-class="p-1">
        <b-badge :variant="isActive || !category.meals_count ? 'light' : 'light'" pill>
          <i class="fas fa-hamburger mr-1" />
          {{ category.meals_count }}
        </b-badge>

        <template #tooltipText>{{ $t('message.menu.mealsCount') }}</template>
      </TooltipButton>
    </div>
  </b-list-group-item>
</template>

<script>
import TooltipButton from '@/components/TooltipButton'

const STORE = '_menuEdit'

export default {
  name: 'CategoriesListElement',
  components: { TooltipButton },
  props: {
    category: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('onClick', this.category)
    },
  },
}
</script>
<style lang="scss">
.choice-title {
  margin-left: 20px;
  opacity: 0.9;
  font-size: 12px;
  &__size {
    font-size: 11px;
  }
}
</style>
