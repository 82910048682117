import { IOrderState } from '@/store/modules/order/index'

const foundCustomers = (state: IOrderState) => state.foundCustomers
const findCustomersLoading = (state: IOrderState) => state.findCustomersLoading
const foundCustomersVisible = (state: IOrderState) => state.foundCustomersVisible

export default {
  foundCustomers,
  findCustomersLoading,
  foundCustomersVisible,
}
