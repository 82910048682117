<template>
  <div>
    <b-form-group>
      <div class="d-flex align-items-center">
        <label for="external-map-link">{{ $t('external_map_link') }}</label>

        <b-button v-b-modal.instructionsModal variant="info" class="defaultTooltipButton mb-2 ml-auto" size="sm" pill>
          ?
        </b-button>
      </div>
      <div class="d-flex">
        <b-form-input
          id="external-map-link"
          v-model="externalMapLink"
          type="text"
          class="form-control"
          name="external-map-link"
          :disabled="isLoading"
          @input="debounceExtractCoords"
        />
        <div v-if="isLoading" class="ml-2 d-flex align-items-center">
          <b-spinner small></b-spinner>
        </div>
      </div>
      <small v-if="successMessage" class="text-success">{{ successMessage }}</small>
      <small v-if="error" class="text-danger">{{ error }}</small>
    </b-form-group>

    <InstructionsModal />
  </div>
</template>

<script>
import { API } from '@/services/api/api'
import { debounce } from 'lodash'
import InstructionsModal from './InstructionsModal.vue'

const TARGEO_URL = 'mapa.targeo.pl'

export default {
  name: 'ExternalMapInput',
  components: {
    InstructionsModal,
  },
  data() {
    return {
      externalMapLink: '',
      error: '',
      successMessage: '',
      isLoading: false,
    }
  },
  methods: {
    debounceExtractCoords: debounce(function () {
      this.extractCoordsFromExternalMapLink()
    }, 500),
    async extractCoordsFromExternalMapLink() {
      this.error = ''
      this.successMessage = ''

      if (!this.externalMapLink) return

      this.isLoading = true

      try {
        let coords

        if (this.externalMapLink.includes(TARGEO_URL)) {
          coords = this.extractTargeoCoords(this.externalMapLink)
        } else {
          coords = await this.extractGoogleShortUrlCoords(this.externalMapLink)
        }

        if (coords) {
          this.successMessage = this.$t('coordinates_updated_from_link')
          this.$emit('coords-extracted', coords)
        } else {
          this.error = this.$t('no_coordinates_found')
        }
      } catch (error) {
        this.error = this.$t('error_resolving_url')
      } finally {
        this.isLoading = false
      }

      setTimeout(() => {
        this.successMessage = ''
        this.error = ''
      }, 10000)
    },
    extractTargeoCoords(url) {
      const regex = /(\d+\.\d+),(\d+\.\d+)/
      const match = url.match(regex)
      if (match) {
        return { lat: parseFloat(match[2]), lon: parseFloat(match[1]) }
      }
      return null
    },
    async extractGoogleShortUrlCoords(url) {
      const { error, data } = await API.getCoordsFromGoogleShortUrl(url)
      if (error || !data) return null
      return data
    },
  },
}
</script>
