<template>
  <div
    :class="[
      'orders-list-item',
      { 'orders-list-item--chosen': chosen, 'orders-list-item--hovered': hoveredOrderFromMapMarker },
    ]"
    @click="openOrderDetails()"
    @mouseenter="() => setOrderHovered(order.id)"
    @mouseleave="() => setOrderHovered(null)"
  >
    <!-- COL -->
    <div>
      <div v-if="actionColVisible" class="d-flex flex-column" style="width: 30px">
        <input
          v-show="showCheckbox"
          class="orders-list-item__choose"
          type="checkbox"
          :checked="chosen"
          @click.stop
          @change="addOrderToChosenOrders()"
        />
      </div>
    </div>

    <!-- COL -->
    <div class="d-flex flex-column">
      <!-- ROW -->
      <div class="d-flex">
        <div style="width: 60px">
          <countdown
            :date="order.promised_time"
            :is-map="true"
            :set-border-radius="true"
            :on-time="order.delivery_type === DELIVERY_TYPES.ONTIME"
          />
        </div>

        <div class="ml-2">
          <div class="orders-list-item__number">#{{ order.number }}</div>

          <div class="orders-list-item__status-icons">
            <TooltipButton
              v-if="order.is_printed"
              :id="'orderPrintedTooltipButton_' + order.id"
              class="tooltip__button"
              size="md"
              variant="light"
              button-class="smallTooltipButton"
            >
              <i class="fas fa-receipt" />

              <template #tooltipText>{{ $t('message.ordersList.printed') }}</template>
            </TooltipButton>

            <TooltipButton
              v-if="order.comment"
              :id="'orderCommentTooltipButton_' + order.id"
              class="tooltip__button"
              size="md"
              variant="light"
              button-class="smallTooltipButton"
            >
              <i class="far fa-comment" />

              <template #tooltipText>{{ order.comment }}</template>
            </TooltipButton>
          </div>
        </div>

        <div class="ml-auto">
          <template v-if="showAssignCourierButton">
            <b-button
              :id="`open-assign-courier-modal-btn_${order.id}`"
              v-b-tooltip.hover.left
              block
              :variant="!!order.courier ? 'primary' : 'warning'"
              :class="['h-100', { 'p-1': !!order.courier, 'btn-recommendation': hasRecommendation }]"
              data-toggle="modal"
              data-target="#assign-courier-modal"
              :title="$t('message.ordersList.assignCourier')"
              :disabled="openAssignCourierButtonDisabled"
              style="width: 120px"
              @click.stop="openAssignCourier()"
            >
              <span>
                <small v-if="order.courier">{{ order.courier.name }}</small>
                <small v-else><i class="fas fa-user-plus" /></small>
              </span>
            </b-button>

            <b-tooltip
              v-if="openAssignCourierButtonDisabled"
              :target="`open-assign-courier-modal-btn_${order.id}`"
              triggers="hover"
              placement="left"
            >
              {{ $t('message.ordersList.cantAssignCourierNotAllMealsReady') }}
            </b-tooltip>
          </template>

          <div v-else class="orders-list-item__delivery-btn">
            <span>{{ order.pickup_type_translated }}</span>
          </div>
        </div>
      </div>

      <!-- ROW -->
      <div class="d-flex mt-2">
        <!-- COL -->
        <div class="d-flex flex-column">
          <div v-if="hasAddress" class="orders-list-item__address-container mb-1">
            <div v-if="isInRegeocode" class="regeocode">
              <b-spinner type="grow" small />

              <span class="regeocode-text">{{ $t('message.regeocodeOrders.relocating') }}</span>
            </div>

            <span v-if="geolocalizationError">
              <i
                v-b-tooltip.hover
                class="fas fa-exclamation-circle text-danger"
                :title="$t('message.ordersList.noAddress')"
              />
            </span>

            <i v-else-if="hasAddress" class="fas fa-map-marker-alt orders-list-item__address-icon fa-fw mr-1" />

            <span v-if="hasAddress" class="text-truncate">
              {{ getOrderFullAddress(order) }}
            </span>

            <b-button
              v-b-tooltip.hover="$t('edit_geolocalization')"
              variant="warning"
              class="orders-list-item__edit-geo-btn d-none d-sm-block"
              @click.stop="$emit('geoEditClick')"
            >
              <i class="fas fa-pencil-alt" />
            </b-button>
          </div>

          <div v-if="$hasModuleAccess(MODULES.meals)" class="orders-list-item__meals text-truncate">
            <b-badge
              v-if="$hasModuleAccess('mapping') && isMappingRequired"
              v-b-tooltip.hover.bottomleft
              class="mr-2"
              variant="danger"
              :title="$t('message.mapping.mappingBtnTooltip')"
            >
              <i class="fas fa-unlink" />
            </b-badge>

            <MealStatusIcon v-if="$hasModuleAccess('kds')" :status="mealsStatus" class="mr-2" :size="16" />

            <MealsTextTruncate :meals="order.meals" />
          </div>
        </div>

        <!-- COL -->
        <div class="orders-list-item-payment">
          <span class="orders-list-item-payment__price">{{ priceDecimal(order.price) }}</span>
          <small class="orders-list-item-payment__type">{{ order.payment_translated }} </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DELIVERY_TYPES,
  MEAL_STATUSES,
  ORDER_STATUSES,
  PICKUP_TYPE_DELIVERY,
  PICKUP_TYPE_ON_SITE,
  PICKUP_TYPE_TAKOUT,
} from '@/common/constants'
import { orderMixins } from '@/mixins'
import Countdown from '@/components/Countdown'
import MealStatusIcon from '@/components/meal/MealStatusIcon'
import TooltipButton from '@/components/TooltipButton'
import MealsTextTruncate from '@/components/MealsTextTruncate'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderListItem',
  components: {
    TooltipButton,
    Countdown,
    MealsTextTruncate,
    MealStatusIcon,
  },
  mixins: [orderMixins],
  props: {
    order: {
      default: () => {
        return {}
      },
      type: Object,
    },
    chosen: {
      default: false,
      type: Boolean,
    },
    actionColVisible: {
      default: true,
      type: Boolean,
    },
    hasRecommendation: {
      default: false,
      type: Boolean,
    },
    setOrderHovered: {
      type: Function,
      required: true,
    },
    hoveredOrderFromMapMarker: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      PICKUP_TYPE_ON_SITE: PICKUP_TYPE_ON_SITE,
      PICKUP_TYPE_TAKOUT: PICKUP_TYPE_TAKOUT,
      PICKUP_TYPE_DELIVERY: PICKUP_TYPE_DELIVERY,
      DELIVERY_TYPES: DELIVERY_TYPES,
      ORDER_STATUSES: ORDER_STATUSES,
    }
  },
  computed: {
    ...mapGetters({
      ordersInRegeocode: '_orders/ordersInRegeocode',
    }),
    ...mapGetters('settings', ['localizationPreferences']),
    showCheckbox() {
      return this.order.status !== ORDER_STATUSES.ORDER_STATUS_NEW && this.order.pickup_type === PICKUP_TYPE_DELIVERY
    },
    isInRegeocode() {
      return this.ordersInRegeocode.includes(this.order.id)
    },
    isMappingRequired() {
      return !this.order.is_mapped
    },
    openAssignCourierButtonDisabled() {
      return (
        this.$hasModuleAccess('kds') &&
        this.localizationPreferences.kds_block_assign_courier_to_order_with_meals_not_ready &&
        this.mealsStatus !== MEAL_STATUSES.DONE
      )
    },
    showAssignCourierButton() {
      return (
        ![PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT].includes(this.order.pickup_type) &&
        this.order.status !== ORDER_STATUSES.ORDER_STATUS_NEW
      )
    },
    geolocalizationError() {
      return this.order.pickup_type === PICKUP_TYPE_DELIVERY && !this.order.lat && !this.order.lon
    },
    hasAddress() {
      return this.order.order_customer?.address
    },
  },
  methods: {
    openOrderDetails() {
      this.$eventBus.$emit('onShowDetailsModal', { id: this.order.id, uuid: this.order.uuid })
    },
    openAssignCourier() {
      this.$emit('onOpenAssignCourier', this.order)
    },
    addOrderToChosenOrders() {
      this.$emit('onAddOrderToChosenOrders', this.order.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.orders-list-item {
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 13px;

  display: grid;
  grid-template-columns: max-content 1fr;

  &:hover {
    background-color: #f3f5f7;
  }

  &--chosen {
    background-color: $blue-50;

    &:hover {
      background-color: $blue-100;
    }
  }

  &--hovered {
    box-shadow: inset 0 0 0px 2px $blue-500;
  }

  &__address-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    max-width: 250px;
    &:empty {
      display: none;
    }
  }
  &__meals {
    display: flex;
    flex-direction: row;
    color: $gray-600;
    font-size: 12px;
    align-items: center;
    max-width: 250px;
  }

  &__address-icon {
    color: #bdc2cd;
  }

  &__choose {
    width: 20px;
    height: 20px;
    transition: transform 120ms ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__status-icons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 120px;
  }

  &__number {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: bold;
  }

  &__delivery-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 100%;
    border-radius: 2px;
    background-color: $gray-200;
    color: $gray-700;
    font-size: 12px;
    border: 1px solid $gray-400;
  }

  &-payment {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &__price {
      font-weight: bold;
      font-size: 15px;
      line-height: 15px;
    }
    &__type {
      font-size: 12px;
      color: $gray-700;
    }
  }

  &__edit-geo-btn {
    width: 20px;
    height: 20px;
    position: absolute;
    left: -30px;
    padding: 0;
    font-size: 12px;
  }
}

.regeocode {
  display: flex;
  align-items: center;
  &-text {
    font-size: 12px;
    margin-left: 6px;
  }
  > .spinner-grow-sm {
    width: 12px;
    height: 12px;
  }
  &-failed-icon {
    font-size: 12px;
  }
}
</style>
