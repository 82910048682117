<template>
  <div class="widget">
    <RegionsMobile v-if="isMobile" />
    <div v-else class="widget-body-wrapper">
      <div class="d-flex flex-direction-row h-100">
        <Map />
        <regions-list />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Map from './_components/Map'
import RegionsList from './_components/RegionsList'
import { isMobile, addResizeEvent, removeResizeEvent } from '@/utils/common'
import RegionsMobile from './_components/RegionsMobile'

export default {
  name: 'Regions',
  components: {
    Map,
    RegionsList,
    RegionsMobile,
  },
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {},
  created() {
    this.setIsMobile()
  },
  mounted() {
    this.getRegions({ loading: true })
    addResizeEvent(this.setIsMobile)
  },
  destroyed() {
    removeResizeEvent(this.setIsMobile)
  },
  methods: {
    ...mapActions('deliveryAreas', {
      getRegions: 'getRegions',
    }),
    setIsMobile() {
      this.isMobile = isMobile()
    },
  },
}
</script>
