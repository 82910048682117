<template>
  <div v-if="deliveryArea" class="delviery-area-pricelist">
    <div class="delviery-area-pricelist__col">
      <div>{{ $t('message.common.price') }}</div>
      <div>{{ deliveryArea.delivery_price }} {{ currency }}</div>
    </div>
    <div class="delviery-area-pricelist__col">
      <div>Min.</div>
      <div>{{ deliveryArea.min_order_value || '---' }} {{ currency }}</div>
    </div>
    <div class="delviery-area-pricelist__col">
      <div>Free</div>
      <div>{{ deliveryArea.free_delivery_from_value || '---' }} {{ currency }}</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DeliveryAreaPricelist',
  props: {
    deliveryArea: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currency: 'currency',
    }),
  },
}
</script>
<style lang="scss">
.delviery-area-pricelist {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border: 1px solid $gray-500;
  overflow: hidden;

  border-radius: 4px;
  text-align: center;

  &__col {
    &:not(:last-child) {
      border-right: 1px solid $gray-500;
    }
    > div {
      padding: 1px;
      white-space: nowrap;
      background-color: $gray-100;

      &:first-child {
        background-color: $gray-200;
        border-bottom: 1px solid $gray-500;
      }
    }
  }
}
</style>