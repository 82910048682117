import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted'
import moment from 'moment-timezone'
import VeeValidate, { Validator } from 'vee-validate'
import pl from 'vee-validate/dist/locale/pl'
import en from 'vee-validate/dist/locale/en'
import de from 'vee-validate/dist/locale/de'
import { BootstrapVue } from 'bootstrap-vue'
import VueShortkey from 'vue-shortkey'
import PortalVue from 'portal-vue'
import { i18n } from './utils/i18n'
import { validatorDictionary } from './utils/validator'
import { customRules } from './utils/vee-validate'
import { rollbar } from './plugins/rollbar'
import { posthogPlugin } from './plugins/posthog'
import InputErrorMessage from './components/InputErrorMessage.vue'
import ResponseErrorsMessages from './components/ResponseErrorsMessages.vue'
import TooltipButton from './components/TooltipButton.vue'
import { DATETIME_TIMEZONE } from '@/common/constants'
import { UtilsMixins } from '@/mixins'
import { ph } from '@/plugins/posthog'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import '@/scss/main.scss'

Vue.config.productionTip = false

/* eslint-disable no-new */
// MODULES ENABLE & CONFIG
moment.tz.setDefault(DATETIME_TIMEZONE)
Vue.prototype.$moment = moment
// VALIDATION
Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields',
  locale: 'pl',
  dictionary: validatorDictionary,
})
const validatorLocales: any = {
  pl: pl,
  en: en,
  de: de,
}
let locale = navigator.language.split('-')[0]
if (!validatorLocales.hasOwnProperty(locale)) {
  locale = 'en'
}
Validator.localize(locale, validatorLocales[locale])
Object.entries(customRules).forEach(([ruleName, rule]) => {
  Validator.extend(ruleName, rule)
})
Vue.use(Toasted, {
  iconPack: 'fontawesome',
  position: 'bottom-right',
})
Vue.prototype.$eventBus = new Vue()

Vue.use(VueShortkey)

Vue.config.keyCodes = {
  'arrow-keys': [37, 38, 39, 40],
}
Vue.use(BootstrapVue)

// Papupos components
Vue.component('InputErrorMsg', InputErrorMessage)
Vue.component('ResponseErrorMsg', ResponseErrorsMessages)
Vue.component('TooltipButton', TooltipButton)

store.dispatch('initStateFromSessionStorage') // Init state from session storage before APP init

// ROLLBAR CONFIG
Vue.prototype.$rollbar = rollbar

Vue.prototype.$posthog = ph
Vue.use(PortalVue)

// CUSTOM GLOBAL METHODS (run by this.{method_name}
Vue.mixin(UtilsMixins)

Vue.use(posthogPlugin)

// CUSTOM DIRECTIVES
Vue.directive('click-outside', {
  //tslint:disable-next-line
  bind: function (el, binding, vnode: any) {
    //tslint:disable-next-line
    el.event = function (event: { target: Node | null }) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    //tslint:disable-next-line
    document.body.addEventListener('click', el.event)
  },
  unbind: function (el) {
    // @ts-expect-error: Property 'event' does not exist on type 'HTMLElement'.
    document.body.removeEventListener('click', el.event)
  },
})
;(async () => {
  if (store.getters['auth/getLoggedIn'] && store.getters['modules/hasModuleAccess']('get-menus')) {
    const _appElement = document.getElementById('app')
    if (_appElement)
      _appElement.innerHTML = `<div class="app-menu-loader"><span aria-hidden="true" class="spinner-border text-primary"></span><span>${i18n.t(
        'message.auth.loadingMenu'
      )}</span></div>`
    await store.dispatch('getMenus')
  }
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
})()
