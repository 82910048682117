<template>
  <div class="found-customers-list">
    <b-list-group v-if="foundCustomersVisible" v-click-outside="closeCustomersList">
      <b-list-group-item
        v-for="(customerAddress, index) in customersAddresses"
        :key="'customer_' + index"
        ref="foundCustomers"
        v-shortkey="focusedCustomer === index ? ['enter'] : 'null'"
        class="customer-address"
        :active="focusedCustomer === index"
        @mouseenter="focusedCustomer = index"
        @keyup.prevent.stop.arrow-keys="selectCustomer"
        @shortkey="chooseCustomerAddress(customerAddress)"
        @click="chooseCustomerAddress(customerAddress)"
      >
        <strong>{{ customerAddress.phone }}</strong> {{ customerAddress.address || customerAddress.full_address }}

        <b-badge v-if="customerAddress.address && customerAddress.city" class="ml-1 customer-address-city">
          {{ customerAddress.city }}
        </b-badge>

        <b-badge
          v-if="customerAddress.located_manually"
          v-b-tooltip.hover
          variant="success"
          class="ml-1"
          :title="$t('address_verified_and_corrected_manually')"
        >
          <i class="fas fa-map-marked"></i>
        </b-badge>

        <b-button
          id="customer-address-remove-btn"
          v-b-tooltip.hover
          variant="danger"
          class="customer-address-remove-button"
          :title="$t('delete_address_from_customer')"
          @click.stop="openRemoveAddressModal(customerAddress)"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
export default {
  name: 'FoundCustomers',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focusedCustomer: -1,
    }
  },
  computed: {
    ...mapGetters('order', ['foundCustomers', 'foundCustomersVisible']),
    customersAddresses() {
      let allAddresses = []
      this.foundCustomers.forEach((customer) => {
        customer.addresses.forEach((address) => {
          allAddresses.push({
            customer_id: customer.id,
            name: customer.name,
            phone: customer.phone,
            full_address: address.full_address,
            address: address.address,
            city: address.city,
            comment: address.comment,
            address_id: address.id,
            lat: address.lat,
            lon: address.lon,
            located_manually: address.located_manually,
          })
        })
      })
      return allAddresses
    },
  },
  methods: {
    ...mapMutations({
      setFoundCustomers: 'order/setFoundCustomers',
      setFoundCustomersVisible: 'order/setFoundCustomersVisible',
    }),
    ...mapActions({
      removeCustomerAddress: 'order/removeCustomerAddress',
    }),
    selectCustomer(event) {
      const { keyCode } = event
      const length = this.customers.length
      if (length <= 0) return false
      if (keyCode === 40 && this.focusedCustomer < length - 1) {
        this.focusedCustomer = this.focusedCustomer + 1
      } else if (keyCode === 38 && this.focusedCustomer > 0) {
        this.focusedCustomer = this.focusedCustomer - 1
      }
      if (this.focusedCustomer >= 0 && this.$refs.foundCustomers[this.focusedCustomer]) {
        this.$refs.foundCustomers[this.focusedCustomer].focus()
      }
    },
    chooseCustomerAddress(customerAddress) {
      this.$emit('select', customerAddress)
      this.closeCustomersList()
    },
    openRemoveAddressModal(customer) {
      this.$bvModal
        .msgBoxConfirm(this.$t('message.orderFormFull.removeAddressConfirm'), {
          title: `${customer.name} (${customer.phone}) - ${customer.full_address}`,
          headerClass: 'w-100',
          centered: true,
          okTitle: this.$t('delete'),
          okVariant: 'danger',
          cancelTitle: this.$t('cancel'),
        })
        .then((result) => {
          if (result) {
            customer = {
              addressId: customer.address_id,
              customerPhoneNumber: customer.phone,
            }
            this.removeCustomerAddress(customer)
          }
          this.backFromRemoveAddressModal = true
        })
    },
    closeCustomersList() {
      if (this.backFromRemoveAddressModal) {
        this.backFromRemoveAddressModal = false
        return
      }
      this.setFoundCustomersVisible(false)
    },
  },
  watch: {
    foundCustomers: function (newValue) {
      this.setFoundCustomersVisible(newValue.length > 0)
    },
  },
}
</script>

<style scoped lang="scss">
.found-customers-list {
  position: absolute;
  z-index: 100;
  margin-top: 4px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.12);
}
.customer-address {
  padding-right: 10px;
}
.customer-address-city {
  font-size: 13px;
  line-height: normal;
}
.customer-address-remove-button {
  margin-left: 10px;
  padding: 0 5px;
  height: 25px;
  width: 25px;
}
.list-group-item {
  cursor: pointer;
}
</style>
