import { IOrderState } from '@/store/modules/order/index'
import { ICustomer } from '@/types/interface/customer'

const setFoundCustomers = (state: IOrderState, value: Array<ICustomer>) => {
  state.foundCustomers = value
}

const setFindCustomersLoading = (state: IOrderState, value: boolean) => {
  state.findCustomersLoading = value
}

const setFoundCustomersVisible = (state: IOrderState, value: boolean) => {
  state.foundCustomersVisible = value
}

export default {
  setFoundCustomers,
  setFindCustomersLoading,
  setFoundCustomersVisible,
}
