import { i18n } from '@/utils/i18n'
import moment from 'moment-timezone'

export const PICKUP_TYPE_ON_SITE = 1
export const PICKUP_TYPE_TAKOUT = 2
export const PICKUP_TYPE_DELIVERY = 3

export const DELIVERY_TYPES = {
  ASAP: 'asap',
  ONTIME: 'ontime',
}

export const PAYMENT_TYPES = {
  CASH: 'cash',
  CARD: 'card',
  PAID: 'paid',
}

export const MENU_SOURCE_CHOICES = {
  DEFAULT: '1',
  PYSZNE: '2',
  ERESTAURANT: '3',
  RESTAUMATIC: '4',
  UPMENU: '5',
  UBEREATS: '7',
  NEW_ERESTAURANT: '8',
  GROUPON: '9',
  CONSUMPTION: '10',
  WOLT: '11',
  GLOVO: '12',
  GLODNY: '13',
  ZJEMY_CO: '14',
  ORDER_SMART: '21',
  RESTABLO: '27',
  DOTYKACKA: '28',
}

export const MENU_POS_LIST = [28]

export const MENU_SOURCE_COLORS = {
  DEFAULT: '#f7f6f6',
  PYSZNE: '#ff8000',
  ERESTAURANT: '#f7f6f6',
  RESTAUMATIC: '#1b1543',
  UPMENU: '#8b38cb',
  UBEREATS: '#21A453',
  NEW_ERESTAURANT: '#f7f6f6',
  GROUPON: '#53a318',
  CONSUMPTION: '#71c6ff',
  WOLT: '#f7f6f6',
  GLOVO: '#ffc244',
  GLODNY: '#cc2229',
  ZJEMY_CO: '#ffd60c',
  DOTYKACKA: '#2cb34a',
}

export const ENTRY_TYPES = {
  SINGLE_CHOICE_ENTRY: 'single_choice_entry',
  MULTI_CHOICE_ENTRY: 'multi_choice_entry',
}

export const DEFAULT_ORDERS_VIEW = {
  COURIERS_MAP: 1,
  ORDERS_LIST: 2,
}

export const ORDER_STATUSES = {
  ORDER_STATUS_NEW: 'new',
  ORDER_STATUS_ACCEPTED: 'accepted',
  ORDER_STATUS_DELIVERING_IN: 'delivering_in',
  ORDER_STATUS_PICKED_UP: 'picked_up',
  ORDER_STATUS_FINISHED: 'finished',
}

export const BILL_STATUSES = {
  CREATING: 100,
  IN_QUEUE: 101,
  SKIPPED: 102,
  CREATED: 103,
  CREATED_AND_PRINTED: 0,
  CONNECTION_ERROR: -1,
  VALUE_ERROR: -2,
  UNKNOWN_ERROR: -3,
  HARDWARE_ERROR: -4,
}

export const MEAL_STATUSES = {
  TODO: 1,
  DOING: 2,
  IN_OVEN: 3,
  DONE: 4,
}

export const FINISHED_TYPES = {
  FINISHED_ORDER_DELIVERED: 'delivered',
  FINISHED_ORDER_NOT_DELIVERED: 'not delivered',
  FINISHED_ORDER_CANCELED: 'canceled',
  FINISHED_ORDER_PICKED_UP: 'finished',
}

export const HALF_PIZZA_PRICE = {
  AVERAGE: 1,
  MORE_EXPENSIVE_HALF: 2,
}

export const MEALSET_DISCOUNT_CHOICES = {
  MEALSET_DISCOUNT: 1,
  MEALSETITEM_DISCOUNT: 2,
}

export const COMPLEX_MEAL_TYPES = {
  HALF_PIZZA: 1,
  MEAL_SET: 2,
}

export const MEALSET_TYPES = {
  MEAL_SET: 1,
  HALF_PIZZA: 2,
}

export const MEALSET_ITEM_CONTEXT_CHOICES = {
  CONTEXT_CATEGORY: 1,
  CONTEXT_SIZE: 2,
  CONTEXT_MEAL: 3,
}

export const VAT_CHOICES_PL = {
  B: 'B',
  A: 'A',
  C: 'C',
  D: 'D',
  E: 'E',
}

export const VAT_CHOICES_DE = {
  19: '19%',
  7: '7%',
  10.7: '10.7%',
  5.5: '5.5%',
  0: '0%',
}

export const VAT_RATES_PL = [
  { text: '8% B', value: VAT_CHOICES_PL.B },
  { text: '23% A', value: VAT_CHOICES_PL.A },
  { text: '5% C', value: VAT_CHOICES_PL.C },
  { text: '0% D', value: VAT_CHOICES_PL.D },
  { text: i18n.t('message.cart.vatExempt') + ' E', value: VAT_CHOICES_PL.E },
  { text: i18n.t('message.cart.none'), value: null },
]

export const VAT_RATES_DE = [
  { text: '19%', value: VAT_CHOICES_DE['19'] },
  { text: '7%', value: VAT_CHOICES_DE['7'] },
  { text: '10.7%', value: VAT_CHOICES_DE['10.7'] },
  { text: '5.5%', value: VAT_CHOICES_DE['5.5'] },
  { text: '0%', value: VAT_CHOICES_DE['0'] },
  { text: i18n.t('message.cart.none'), value: null },
]

export const ROLE = {
  OWNER: 'owner',
  MANAGER: 'manager',
  WAITER: 'waiter',
  COURIER: 'courier',
  COOK: 'cook',
}

type Notification = {
  type: number;
  name: string;
  color: string;
};

export const NOTIFICATION: { [key: string]: Notification } = {
  MANUAL: { type: 1, name: 'manual', color: '#4A9CFD' },
  AUTOMATIC: { type: 2, name: 'automatic', color: '#FDC64A' },
  CONNECTION_FAILED: { type: 3, name: 'connection-failed', color: '#FD4A4A' },
  CONNECTION_ESTABLISHED: { type: 4, name: 'connection-established', color: '#00CF85' },
  PRINTING: { type: 5, name: 'printing', color: '#FDC64A' },
  SUBSCRIPTION_ENDS: { type: 6, name: 'subscription-ends', color: '#FD4A4A' },
  NEW_FEATURE: { type: 7, name: 'new-feature', color: '#00CF85' },
  REGEOCODE: { type: 8, name: 'regeocode', color: '#FDC64A' },
  DAYS_7_UNTIL_PURCHASED_PLAN_FINISHES: { type: 9, name: '7-days-subscription-ends', color: '#FDC64A' },
  DAYS_3_UNTIL_PURCHASED_PLAN_FINISHES: { type: 10, name: '3-days-subscription-ends', color: '#FD4A4A' },
  DAY_1_UNTIL_PURCHASED_PLAN_FINISHES: { type: 11, name: '1-day-subscription-ends', color: '#FD4A4A' },
  HOUR_1_UNTIL_PURCHASED_PLAN_FINISHES: { type: 12, name: '1-hour-subscription-ends', color: '#FD4A4A' },
  PURCHASED_PLAN_FINISHED: { type: 13, name: 'subscription-ends', color: '#FD4A4A' },
  ORDER_CANCELED: { type: 14, name: 'order-canceled', color: '#FDC64A' },
};

export const NOTIFICATION_TYPE_MAP: { [key: number]: keyof typeof NOTIFICATION }  = {
  1: 'MANUAL',
  2: 'AUTOMATIC',
  3: 'CONNECTION_FAILED',
  4: 'CONNECTION_ESTABLISHED',
  5: 'PRINTING',
  6: 'SUBSCRIPTION_ENDS',
  7: 'NEW_FEATURE',
  8: 'REGEOCODE',
  9: 'DAYS_7_UNTIL_PURCHASED_PLAN_FINISHES',
  10: 'DAYS_3_UNTIL_PURCHASED_PLAN_FINISHES',
  11: 'DAY_1_UNTIL_PURCHASED_PLAN_FINISHES',
  12: 'HOUR_1_UNTIL_PURCHASED_PLAN_FINISHES',
  13: 'PURCHASED_PLAN_FINISHED',
  14: 'ORDER_CANCELED',
};

export const DISCOUNT_CHOICES = {
  DISCOUNT_CHOICE_PERCENT: 1,
  DISCOUNT_CHOICE_VALUE: 2,
  DISCOUNT_CHOICE_PROMO_PRICE: 3,
  DISCOUNT_CHOICE_FREE_DELIVERY: 4,
}

export const RECEIPT_TEMPLATE_CHOICES = {
  ALL_MEALS: 1,
  REMAINING_MEALS: 2,
  WHITELISTED_MEALS: 3,
}

export const DEFAULT_RECEIPT_TEMPLATE_DESIGN = 2

export const CURRENCY = {
  PLN: 'zł',
  USD: '$',
  EUR: '€',
  GBP: '£',
}

export const FISCAL_TYPES = {
  NO_FISCAL: 1,
  PAPU: 2,
  BSX: 3,
  K2: 4,
}

export const DATETIME_TIMEZONE = 'Europe/Warsaw'
export const POS_API_DATETIME_INPUT_FORMAT = 'DD-MM-YYYY HH:mm'
export const COMMON_DATETIME_INPUT_FORMAT = 'YYYY-MM-DD HH:mm'

export const DEFAULT_PAGE_SIZE = 25
// const choices = [
//   {
//     name: this.$t('message.orderDetails.finishOrderChoices.canceled'),
//     value: 'canceled',
//     pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT, PICKUP_TYPE_DELIVERY]
//   },
//   {
//     name: this.$t('message.orderDetails.finishOrderChoices.delivered'),
//     value: 'delivered',
//     pickupTypes: [PICKUP_TYPE_DELIVERY]
//   },
//   {
//     name: this.$t('message.orderDetails.finishOrderChoices.notDelivered'),
//     value: 'not delivered',
//     pickupTypes: [PICKUP_TYPE_DELIVERY]
//   },
//   {
//     name: this.$t('message.orderDetails.finishOrderChoices.pickedUp'),
//     value: 'finished',
//     pickupTypes: [PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT]
//   }
// ]

export const SOUNDS = {
  CORRECT: 'correct',
  EXQUISITE: 'exquisite',
  POINT: 'point',
  POSSITIVE: 'possitive',
  CRICKETS: 'crickets',
  NOTIFICATION: 'notification',
  NOTIFY: 'notify',
  MELODY: 'melody',
  OMINANT: 'ominant',
  REQUEST: 'request',
}

export const getStartDateToday = () =>
  moment(moment()).tz(DATETIME_TIMEZONE).set({ hour: 0, minute: 0 }).format(COMMON_DATETIME_INPUT_FORMAT)

export const getEndDateToday = () => moment().tz(DATETIME_TIMEZONE).format(COMMON_DATETIME_INPUT_FORMAT)

export const MODULES = {
  administration: 'administration',
  auth: 'auth',
  billPrint: 'bill-print',
  billSplit: 'orders-split-bill',
  billSplitEdit: 'orders-split-bill-edit',
  billingPeriodDetails: 'billing-period-details',
  billingPeriods: 'billing-periods',
  billingPeriodsCurrent: 'current-billing-periods',
  couriersMap: 'couriers-map',
  demo: 'demo',
  discount: 'discount',
  discountList: 'discounts-list',
  editOrder: 'edit-order',
  employee: 'employee',
  employeeAdd: 'employee-add',
  employeesList: 'employees-list',
  erestaurant: 'erestaurant',
  getMenus: 'get-menus',
  help: 'help',
  helpButton: 'help-button',
  helpdeskChat: 'helpdesk-chat',
  integrations: 'integrations',
  invoicePrint: 'invoice-print',
  kds: 'kds',
  logout: 'logout',
  mapping: 'mapping',
  meals: 'meals',
  mealSets: 'mealsets',
  mealsetEdit: 'mealset-edit',
  mealsetsList: 'mealsets-list',
  menuEdit: 'menu-edit',
  newOrder: 'new-order',
  newOrderDelivery: 'new-order-delivery',
  newOrderOnSite: 'new-order-on-site',
  newOrderPapu3: 'new-order-papu3',
  notFound404: 'notFound404',
  order: 'order',
  ordersArchive: 'orders-archive',
  ordersList: 'orders-list',
  ordersSummary: 'orders-summary',
  papu_3: 'papu-3',
  passwordReset: 'password-reset',
  passwordResetConfirm: 'password-reset-confirm',
  paymentMethodEdit: 'payment-method-edit',
  paymentsPackagePrice: 'payments-package_price',
  paymentsServiceCharge: 'payments-service_charge',
  paymentsTips: 'payments-tips',
  permissions: 'permissions',
  promisedTimePredictionShow: 'show-promised-time-prediction',
  receiptPrint: 'receipt-print',
  receiptPrintersEdit: 'receipt-printer-edit',
  receiptPrintersList: 'receipt-printers-list',
  receiptPrintersSettings: 'receipt-printers-settings',
  regions: 'regions',
  restoreOrder: 'restore-order',
  settings: 'settings',
  shiftDetails: 'shift-details',
  shifts: 'shifts',
  shiftsCurrent: 'current-shifts',
  subscriptions: 'subscriptions',
  tableNumberEdit: 'table-number-edit',
  takeawayApi: 'takeaway-api',
  workstationEdit: 'workstation-edit',
  workstationsList: 'workstations-list',
  recommendations: 'recommendations',
  kitchenSettings: 'kitchen-settings',
}

export const DELIVERY_AREA_COLORS = ['#4ed60b', '#2aebec', '#2a7fec', '#efea21', '#ec8517', '#ef0808', '#444444']

export const COURIER_TRANSPORT_TYPES = {
  CAR: 'A',
  BICYCLE: 'R',
  MOTORCYCLE: 'M',
}

export const COURIER_CONDITIONS = {
  IN_RESTAURANT: 'in_restaurant',
  COMING_BACK: 'coming_back',
  IN_DELIVERY: 'in_delivery',
}

export const RECOMMENDATION_ACTIONS = {
  MAKE_PHASE_1: 'make_phase_1',
  MAKE_PHASE_2: 'make_phase_2',
  MAKE_PHASE_3: 'assign_to_courier',
  COURIER_SEND: 'send_courier',
}
