<template>
  <div
    v-b-tooltip.hover.right
    :title="$t('showDeliveryAreas')"
    :class="[
      'leaflet-control-zoom leaflet-bar leaflet-control m-0 mt-2 enlarge-button',
      { 'leaflet-control--active': isVisible },
    ]"
  >
    <a class="" @click="toggleVisibility">
      <i v-if="loading" class="fas fa-spinner fa-spin"></i>
      <i v-else class="fas fa-draw-polygon"></i>
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DeliveryAreasToggleButton',
  props: {
    initialVisible: {
      type: Boolean,
      default: false,
    },
    useLocalStorage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      deliveryAreasRequest: 'deliveryAreas/regionsRequest',
    }),
    loading() {
      return this.deliveryAreasRequest === 'loading'
    },
  },

  created() {
    this.initializeVisibility()
  },
  methods: {
    initializeVisibility() {
      if (this.useLocalStorage) {
        const storedValue = localStorage.getItem('deliveryAreasVisible')
        this.isVisible = storedValue !== null ? JSON.parse(storedValue) : this.initialVisible
      } else {
        this.isVisible = this.initialVisible
      }
      this.$emit('change', this.isVisible)
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible
      this.$emit('change', this.isVisible)

      if (this.useLocalStorage) {
        localStorage.setItem('deliveryAreasVisible', JSON.stringify(this.isVisible))
      }
    },
  },
}
</script>
