export const en = {
  message: {
    demo: {
      company: 'Company',
      localization: 'Localization',
      createAccount: 'Create demo account',
      thankYou: 'Thank you!',
      successMessage:
        'A message with further details will be sent to the e-mail address provided within 5 minutes. In the meantime, check out our knowledge base:',
      errorMessage: 'Please correct the form and try again.',
      nip: 'Tax ID',
      company_email: 'E-mail address',
      menu_url: 'Menu URL (Pyszne.pl / Lieferando.de)',
      name: 'Localization name',
      city: 'City',
      address: 'Address',
      phone: 'Phone number',
      localization_email: 'E-mail address',
      fineprint:
        'Your personal data Controller is PapuKurier Sp. z o.o., located in Poznań (60-284), ul Sowińskiego 18A, and you can contact us at: kontakt@papu.io. Your personal data will be processed to contact you, mainly to present our commercial offer to you. In connection with personal data processing, you have the right to access your data, rectify them, remove them, limit their processing, transfer them, object to their processing and file a complaint to a supervisory body. Detailed information is available in the <a href="https://papu.io/en/privacy-policy/" target="_blank">Privacy Policy</a>.',
      phoneCalls: 'I consent to phone calls and e-mails.',
      pl: 'Poland',
      uk: 'United Kingdom',
      de: 'Germany',
      country: 'Country',
      street: 'Street',
      house: 'Apartment',
      kitchenType: 'Kitchen type',
      kitchenTypeOptions: {
        1: 'American',
        2: 'Bistro',
        3: 'Burger',
        4: 'Indian',
        5: 'Kebab',
        6: 'Mix house',
        7: 'Pizza & Kebab',
        8: 'Pizzeria',
        9: 'Ramen',
        10: 'Steak house',
        11: 'Sushi',
        12: 'Thai',
        13: 'Turkish',
        14: 'Vege',
        15: 'Italian',
      },
      logo: 'Logo',
    },
    common: {
      courier: 'Courier',
      reset: 'Reset',
      pickTime: 'Pick time',
      weekTimeTable: {
        bothTimeFieldsMustBeFilled: 'Both time fields for a given day must be filled.',
      },
      cancel: 'Cancel',
      edit: 'Edit',
      save: 'Save',
      delete: 'Delete',
      savingWait: 'Saving. Please wait...',
      default: 'Default',
      continue: 'Continue',
      selectFromList: 'Select from the list',
      other: 'Other',
      confirm: 'Confirm',
      card: 'Card',
      cash: 'Cash',
      paid: 'Paid',
      end: 'End',
      bill: 'Bill',
      all: 'All',
      printAgain: 'Print again',
      enterCash: 'Enter cash',
      price: 'Price',
      rest: 'Rest',
      cashCalculator: 'Cash calculator',
      order: 'Order',
      move: 'move',
      paymentMethod: 'Payment method',
      or: 'Or',
      anErrorOccured: 'An error occured',
      discardChangesQuestion: 'Do you wish to discard unsaved changes?',
      discardChanges: 'Discard changes',
      stayOnPage: 'Stay on the page',
      back: 'Back to previous page',
      none: 'None',
      noData: 'No data',
      close: 'Close',
      menu: 'Menu',
      saving: 'Saving',
      source: 'Source',
      sourceMenu: 'Menu source',
      name: 'Name',
      size: 'Size',
      group: 'Group',
      category: 'Category',
      errorOccured: 'Error',
      color: 'Color',
      user: 'User',
      menuDefault: 'Default menu',
      choose: 'Choose',
    },
    auth: {
      signIn: 'Sign in',
      signUp: 'Sign up',
      email: 'Email address',
      password: 'Password',
      confirmPassword: 'Confirm password',
      signInButton: 'Sign in',
      signUpButton: 'Sign up',
      username: 'Username',
      courierCanNotLogin: 'Couriers are not allowed to login to PapuPOS!',
      appDownload: 'Download Papu.io courier app',
      loggedOutInvalidToken: "You've been logged out (probably by clicked 'Logout' button on different device).",
      forgotPassword: 'Forgot password?',
      passwordReset: 'Reset your password',
      send: 'Send',
      setNewPassword: 'Set new password',
      resetLinkExpired: 'Password reset link has expired. You can reset your password again.',
      canSignIn: 'You can now sign in.',
      selectLocalization: 'Select a localization',
      loadingMenu: 'Downloading menu',
    },
    employeeForm: {
      employeeType: 'Employee type',
      employeeTypes: {
        waiter: 'Waiter',
        courier: 'Courier',
        manager: 'Manager',
        owner: 'Owner',
        cook: 'Cook',
      },
      employeeName: 'Employee name',
      phoneNumber: 'Phone number',
      email: 'Email address',
      password: 'Password',
      confirmPassword: 'Confirm password',
      isActive: 'Is active',
      addUserButton: 'Add user',
      saveChangesButton: 'Save changes',
      courierType: 'Courier type',
      courierTypes: {
        core: 'Core',
        uber: 'Uber',
      },
      courierTransportMethod: 'Courier transport method',
      courierTransportMethods: {
        car: 'Car',
        motorbike: 'Motorbike',
        bike: 'Bike',
      },
      sendApp: 'Send app',
      appSent: 'App has been sent',
      appInfo:
        'I order to get the application for drivers, go to rest.papu.io website on your mobile' +
        'and click the button for AppStore/GooglePlay site or search the store for "papu.io" and download the application.',
      salaryPerKm: 'Salary per km',
      salaryPerHour: 'Salary per hour',
      salaryPerDelivery: 'Salary per delivery',
      salaryPerZoneDelivery: 'Salary per zone delivery',
      verifyEmailMessage:
        'A verification link has been sent to the e-mail address provided. Click on it and confirm the e-mail address in the ADMIN PAPU.io application.',
      updateEmployeeSuccess: 'The user has been saved.',
    },
    employeeList: {
      showOnlyActive: 'Show only active employees',
      showAllEmployees: 'Show all employes',
      name: 'Full name',
      username: 'Username',
      phone: 'Phone',
      active: 'Active',
      type: 'Type',
      edit: 'Edit',
      yes: 'Yes',
      no: 'No',
      courier: 'Courier',
      waiter: 'Waiter',
      manager: 'Manager',
      owner: 'Owner',
      loggedIn: 'Logged in',
      remove: 'Remove',
      removeEmployee: 'Remove employee',
      areYouSure: 'Are you sure that You want to remove employee',
    },
    orderDetails: {
      orderNumber: 'Number',
      orderSource: 'Source',
      orderStatus: 'Status',
      customerDetails: 'Customer details',
      customerData: 'Customer data',
      orderContent: 'Order content',
      edit: 'Edit',
      close: 'Close',
      changeOrderLocalization: 'Change order localization',
      changeOrderLocalizationInfo: 'Send the order to another restaurant',

      changeOrderLocalizationDescription:
        'Order will disappear from current orders list and will be visible ' + 'only for chosen localization',
      transferedFrom: 'Order transfered from',
      transferOrder: 'Transfer order',
      finishOrderButton: 'Finish',
      finishOrder: 'Finish order',
      printingError: 'Error occured',
      printingErrorTryAgain: 'Try again',
      printOrderConfirmation: {
        btn: 'Receipt',
        success: 'Printing order confirmation is in progress',
      },
      printBill: 'Receipt',
      closeOrderOnPrintBill: 'Automatically close order',
      finishOrderChoices: {
        canceled: 'Canceled',
        delivered: 'Delivered',
        notDelivered: 'Not delivered',
        pickedUp: 'Picked up',
        serviceCharge: 'Service charge',
        gloVoServiceCharge: 'Glovo service charge',
        tip: 'Tip',
      },
      accept: 'Accept',
      acceptFor: 'Accept for',
      acceptInfo: 'This order will be able to be fulfilled by your restaurant',
      decline: 'Decline',
      declineTransferInfo: 'This order will return to the restaurant {oldLocalizationName}',
      changePaymentMethod: 'Change payment method',
      changeFinishOrderButton: 'Change',
      selectPromisedTime: 'Select time promised to customer',
      changeOrAcceptPromisedTime: 'Change or accept time promised to customer',
      billAlreadyPrinted: 'This receipt has already been printed',
      printInvoice: 'FV',
      restoreOrder: 'Restore',
      orderRestored: 'Order has been restored',
      printInvoiceResult: {
        noVatrate: 'VAT rates not set',
        noOrder: 'No such order',
        alreadyExists: 'Invoice already exists',
        unknownError: 'Unknown error',
        localizationMismatch: 'Localizations mismatch',
        successPrintInvoice: 'Invoice printing in progress',
        successSendInvoiceByEmail: 'Invoice sent by email',
      },
      paymentTypeNotSet: 'Payment type not set',
      paymentTypeUpdateError: 'Error occurred during changing payment method',
      tableNumberUpdateError: 'Error occurred during changing table number',

      paymentTypeChanged: 'Payment method changed',
      tableNumberChanged: 'Table number changed',
      orderNotAccepted:
        'To accept this order: click Accept below (or choose promised time), and next click Ok. ' +
        'Order will show on orders list. For a moment (up to 30 secs) order will not have data filled, ' +
        "but don't worry - it will process automatically.",
      table: {
        nameTable: 'Table',
        missingTable: 'No table',
      },
      today: 'Today',
    },
    mealDetails: {
      chooseSize: 'Choose size',
      chooseAdditives: 'Choose additives',
      discount: 'Discount',
      valueDiscount: 'Value discount',
      promotionalPrice: 'Promotional price',
      quantity: 'Quantity',
      addComment: 'Add comment',
      splitBill: 'Split bill',
      main: 'Main',
      billNo: 'Bill number',
      createNew: 'Create new',
      totalPrice: 'Total price',
      add: 'Add',
      save: 'Save',
      delete: 'Delete',
      price: 'Price',
      changeHalfPizza: 'Change pizza',
      changeMeal: 'Change meal',
      finalView: 'Summary',
      discountDisabled: 'Discount unavailable.',
      discountDisabledInfo: 'Order discount is unavailable due to discount set on order.',
      selectionRequired: 'Selection required',
      mealRemovedFromMenu:
        'The meal has been removed from the menu. All you can do with this meal is remove it from the order.',
      left: 'Left',
      right: 'Right',
      chooseMeal: 'Choose meal',
      none: 'None',
      submealsNotFilled: 'Choose all sub-meals of the meal set',
    },
    mealsMenu: {
      search: 'Search',
      mealsList: 'List of meals',
    },
    categories: {
      mealSets: 'MEAL SETS',
    },
    mealsModal: {
      title: 'Select pizza',
    },
    multiChoiceEntryModal: {
      search: 'Search',
      ingredients: 'Ingredients',
      selectedAdditives: 'Selected additives',
      paidAdditives: 'Paid additives',
      freeAdditives: 'Free additives',
      save: 'Save',
      additives: 'Additives',
      noIngredients: 'No ingredients',
      noAdditives: 'No additives',
      noAdditivesToChoose: 'No additives to choose',
      noIngredientsToChoose: 'No ingredients to choose',
      deletedIngredients: 'Deleted ingredients',
      removedMultiChoiceEntries: 'Removed additives',
    },
    cart: {
      title: 'Order on site',
      name: 'Name',
      quantity: 'Quantity',
      unitPrice: 'Unit price',
      discountLabel: 'Discount',
      discountValue: 'Value discount',
      cart_percent_discount: 'Order discount',
      cart_promotional_price: 'Order promotional price',
      percent: 'Percent',
      value: 'Value',
      promotionalPrice: 'Promotional price',
      freeDelivery: 'Free delivery',
      discount: 'Meal discount',
      sum: 'Sum',
      edit: 'Edit',
      totalPrice: 'Total price',
      totalBillPrice: 'Bill printed with value',
      deliveryPrice: 'Delivery price',
      deliveryPriceIncluded: 'Including the delivery price',
      comment: 'Comment',
      vat: 'VAT',
      vatExempt: 'Exempt',
      none: 'None',
      discount_all: 'Sum of discounts',
      deliveryArea: 'Delivery area',
      actualMeal: 'Actual meal',
      discountDisabled: 'Discount unavailable.',
      discountDisabledInfo: 'Order discount is unavailable due to discount set on 1 of meals.',
      discountCode: 'Discount code',
      applyCode: 'Apply code',
      tableNumber: 'Table number',
      differentTableNumber: 'Please enter a different number',
      printBill: 'Print bill',
      closeOrder: 'Close order',
      paymentMethod: 'Payment method',
      releaseType: 'Release type',
      insertCash: 'Insert cash',
      price: 'Price',
      change: 'Rest',
      noMeals: 'No meals',
      mainBill: 'Main bill',
      tip: 'Tip',
      packagePriceIncluded: 'Including the package price',
      packagePrice: 'Package price',
      additionalFees: 'Additional fees',
      orderContent: 'Order content',
      addMeal: 'Add meal',
    },
    orderFormFull: {
      customerDetails: 'Customer details',
      phoneNumber: 'Phone number',
      address: 'Address',
      name: 'Name',
      customerTaxID: 'Tax number',
      pickupType: 'Pickup type',
      pickupTypes: {
        onSite: 'On site',
        takeaway: 'Takeaway',
        delivery: 'Delivery',
      },
      deliveryTypes: {
        asap: 'ASAP',
        onTime: 'On time',
      },
      paymentType: 'Payment type',
      paymentTypes: {
        cash: 'Cash',
        card: 'Card',
        paid: 'Paid',
      },
      promisedTime: 'Promised time',
      placeOrder: 'Place order',
      updateOrder: 'Update order',
      deliveryType: 'Delivery type',
      emptyCart: 'Cart is empty, please add some meals.',
      minValue: 'Minimal order value missing',
      minValueFrom: 'Free delivery from',
      noDeliveryArea:
        'No defined delivery areas. Please log in on manager account and add delivery areas and values in settings.',
      outOfDeliveryArea: 'Address outside the delivery area.',
      orderSource: 'Order source',
      choose: 'Choose',
      changeOrderSource: 'Change order source',
      printBill: 'Print bill',
      addressComment: 'Address comment',
      removeAddressConfirm: "Are you sure you want to remove this client's address?",
      yes: 'Yes',
      no: 'No',
      deliveryPriceFromArea: 'Set delivery price',
      useNewAreaDeliveryPrice:
        'Do you want to overwrite delivery price with delivery price {calculatedDeliveryPrice} from new area?',
      deliveryAreaChanged: 'Delivery area has been changed',
      deliveryPriceChangeDueToCart:
        'For this value and order address, the delivery amount resulting from the specified ' +
        'delivery areas is {deliveryPrice}. Set delivery amount to {deliveryPrice} in this order?',
      payment: 'Payment',
      newOrder: 'New Order',
    },
    orderFormShort: {
      number: 'Number',
      customer: 'Customer',
      address: 'Address',
      changeDetails: 'Change details',
      addOrderOnSite: 'Add order on site',
      order: 'Order',
      new: 'New',
    },
    promisedTime: {
      ownPromisedTime: 'Own',
      recommendedByAi:
        'Our artificial intelligence predicted the following delivery time. ' +
        'If you do not accept it, choose a different time manually.',
      futurePromisedTimeInfo:
        'Order with promised time more than 24 hours from actual moment will not be visible in list of current orders',
      predictingPromisedTime: 'Predicting promised time',
    },
    futureDateConfirmModal: {
      chosenDateBigger: 'The time selected is greater than 12 hours from the present time',
      chosenDateBiggerInfo:
        "You're setting {dateType} to {dateTimeDisplay} - it's {hoursToDate} " + 'away from that moment. Are you sure?',
      promisedTime: 'promised time',
      shiftFinishedAt: 'shift finished at date',
    },
    assignCourier: {
      assignCourierToOrder: 'Assign courier to order',
      assignCourierToOrders: 'Assign courier to orders',
      none: 'Remove courier',
      emptyCouriersList: 'No couriers are logged in.',
    },
    ordersMap: {
      clearSelectedOrders: 'Unselect all orders',
      selectOrders: 'Select all orders',
      assignCourierToOrders: 'Assign courier to orders',
      regeocodeOrders: 'Re-geolocate',
    },
    ordersList: {
      search: 'Search',
      searchInfo: 'Search - enter meal name, order number, price, address, phone number or customer name',
      accepted: 'Accepted',
      unaccepted: 'Unaccepted',
      todo: 'To do',
      deliveringIn: 'Delivering in',
      futureOrders: 'In future',
      remainingPromisedTime: 'Remaining time',
      address: 'Address',
      payment: 'Payment',
      meals: 'Meals',
      courier: 'Courier',
      status: 'Status',
      source: 'Sources',
      assignCourier: 'Assign courier',
      cantAssignCourierNotAllMealsReady: "You can't assign courier if not all meals are ready",
      price: 'Price',
      priceTooltip: 'Products price + Delivery price = Order price',
      onSite: 'On site',
      takeaway: 'Takeaway',
      delivery: 'Delivery',
      filterByEmployee: 'Employees',
      filterByPaymentMethod: 'Payment methods',
      createdAt: 'Created at',
      finishedAt: 'Finished at',
      promisedTime: 'Promised time',
      orderStatus: 'Order status',
      statusFinished: 'Finished',
      statusCurrent: 'Ongoing',
      none: 'None',
      finishedStatus: 'Finished type',
      all: 'All',
      noAddress: 'Address has been not recognized - order is not visible on the map!',
      restaurants: 'Restaurants',
      restaurant: 'Restaurant',
      total: 'A total of',
      for: 'orders for',
      discountsFor: 'Discounts for',
      billValueHigherInOrders: 'Bill values higher than the value of orders by',
      noOrders: 'No orders for chosen filters.',
      discountFilter: 'Only with discount',
      billValueHigherFilter: 'Only with the bill value higher than the order price',

      discount: 'Discount',
      uberDiscountFilter: 'Only with UberEats discount (discounts calculated from May 6, 2021)',
      uberDiscountFor: 'Total discounts from UberEats',
      onTime: 'Order ontime.',
      printed: 'Bill printed.',
      orderBillValuesMismatch: 'The price of this order is lower than the value of the bills.',

      customerPhone: 'Contact number',
      filterThroughDateType: 'Filter orders by',
      browserPreventsSoundNotifications:
        'The browser has prevented autoplay of the media. Please click "PLAY" to start the media.',
      browserPreventsSoundNotificationsActionText: 'PLAY',
      filterByIsPrinted: 'Receipt',
      filterByHasInvoice: 'Invoice',
      isTransferred: 'Order transferred',
      yes: 'Yes',
      no: 'No',
      isPrinted: 'Printed',
      isNotPrinted: 'Not printed',
      tipsFilter: 'Only with tips',
      tips: 'Tips',
      tipsFor: 'Tips for',
      splittedBills: 'Splitted bills',
      filters: 'Filters',
      showFilters: 'Show filters',
      hideFilters: 'Hide filters',
    },
    summary: {
      filterFromDate: 'Filter from date',
      filterToDate: 'Filter to date',
      filterByEmployee: 'Filter by employee',
      name: 'Name',
      role: 'Role',
      paidByCash: 'Paid by cash',
      paidByCard: 'Paid by card',
      alreadyPaid: 'Already paid',
      unrealized: 'Unrealized',
      realized: 'Realized',
      ordersNumber: 'All',
      discounts: 'Discounts',
      deliveries: 'For deliveries',
      total: 'Total',
      inProgress: 'In progress',
      uberDiscount: 'Discounts by UberEats',
      uberDiscountTooltip:
        'UberEats should return this amount to the restaurant (applies to orders of any status in Papu)',
      uberDiscountStartDateWarning: 'UberEats discounts are calculated from May 6, 2021',
      tips: 'Tips',
      canceled: 'Canceled',
      billValueTooHigh: 'Certain orders have lower price than printed bills.',
      billValueTooHighOrdersBaseLink: 'See them',
      info: {
        summaryChanges:
          'Dear Customers, \n Regarding the introduction of splitting bills the way of accounting with employees has changed a bit. In order to find out how much cash an employee took, how many card payments and how many online payments (paid) we have to base on the bills, because now one order can have for example a bill paid with a card and a bill paid with cash.',
        billsTableTip:
          'In the table below, there are bills by form of payment in columns, assigned to employees (in rows). From here, please take information on how much the employee took in card payments, how much in cash, and how much online (paid).',
        ordersTableTip:
          'In this table, the only change that has occurred is that we have added a "split payment" column that indicates how many orders there were for which payment was accepted in different forms.',
      },
      orders: 'Orders',
      bills: 'Bills',
      withSplitPayments: 'Split payments',
      realizedOrders: 'Realized orders',
      realizedBills: 'Realized bills',
    },
    settings: {
      payments: 'Payments',
      settings: 'Management',
      management: 'Management',
      addEmployee: 'Add employee',
      employeesList: 'Employees list',
      orderBase: 'Order base',
      ordersSummary: 'Orders summary',
      subscriptions: 'Subscriptions',
      salaries: 'Salaries',
      payrollList: 'Payroll list',
      manHours: 'Man-hours',
      penalties: 'Penalties',
      bonuses: 'Bonuses',
      advances: 'Advances',
      kilometrage: 'Kilometrage',
      restaurantCash: 'Restaurant cash',
      daySummary: 'Day summary',
      currentShifts: 'Current shifts',
      shifts: 'Shifts',
      shiftsList: 'Shifts list',
      addEditEmployee: 'Add or edit employee',
      regions: 'Delivery regions',
      currentBillingPeriods: 'Current billing periods',
      billingPeriods: 'Billing periods list',
      billings: 'Billings',
      employees: 'Employees',
      orders: 'Orders',
      billingPeriod: 'Billing Period',
      receiptPrintersSettings: 'Receipt printers settings',
      integrations: 'Integrations',
      kdsSettings: 'KDS Settings',
      editWorkstation: 'Add new or edit workstation',
      goToAdmin: 'Go to admin.papu.io',
      actions: 'Actions',
      openDrawer: 'Open drawer',
      openingDrawer: 'Opening drawer...',
    },
    receiptPrintersSettings: {
      name: 'Receipt printers settings',
      singlePrinterView: 'Manage receipt printers',
      printerName: 'Receipt printer name',
      edit: 'Edit',
      free: 'Unassign',
      assign: 'Assign',
      delete: 'Delete',
      templates: 'Templates',
      freeTemplates: 'Unused templates',
      save: 'Save',
      create: 'Create',
      printAllMeals: 'Print all meals',
      printRemainingMeals: 'Print meals that were not matched against other templates',
      printWhitelistedMeals: 'Print meals only from the list',
      templateName: 'Template name',
      no_of_copies: 'Number of copies',
      templateType: 'Template type',
      whitelist: 'List of key phrases, that meals printed on receipt contain. Each phrase finished with new line.',
      deletePrinterModalTitle:
        'Are you sure you want to delete printer? All templates assigned to this printer will be freed.',
      cancel: 'cancel',
      saved: 'Saved',
      newPrinter: 'Add new printer',
      newPrinterDetails: 'Details about new printer',
      newTemplate: 'Add new template',
      template: 'Template',
      ownReceiptDesign: 'Custom design (contact papu.io for more information)',
      receiptDesign: 'Receipt design',
      expand: 'Expand',
      shrink: 'Shrink',
      printerInternalId: 'Printer internal windows name',
      newPrinterNameTooltipText: 'Name of printer that printer will be visible with in our system. E.g. "Bar", "Grill"',
      newPrinterInternalIdTooltipText:
        'Name under which selected printer is visible in your Windows system.To know what your printer name is go to Control Panel and "Devices and printers".',
      assignedTemplates: 'Assigned templates',
      howItWorksCollapsible: 'How it works?',
      howItWorksCollapsibleDescription:
        'Templates decide what meals are printed on receipt, how many copies of receipt are created, what printer receipt is printed on and what the receipt looks like.\n"Assigned templates" is a set of templates assigned to specified printer.\n"Free templates" is a set of templates which are not assigned to any printer. If template is free, it does not affect printing receipts at all.\nBE CAREFUL - if no templates are assigned to printer, it will not print any receipts.',
      exampleCollapsible: 'Example of usage',
      exampleCollapsibleDescription:
        '1. We assign template "Kitchen" with number of copies equal to 2 and key phrases "pizza" and "spaghetti".\n2. To printer named "Grill" we assign template with number of copies equal to 1 and key phrase "steak".\n3. Guest orders an order with meals named: "pizza itealiana", "spaghetti napoli", "tuna steak", "tomato soup".\n4. After pressing button printing receipt: printer "Kitchen" will print two exactly the same receipts with meals "pizza italiana", "spaghetti napoli" on each of them. Printer "Grill" will print one receipt with meal named "tuna steak". Tomato soup in this scenerio will no to visible on any receipt!\n5. To print tomato soup you need to add to one of the printers template REMAINING MEALS or add tomato soup to key phrases list of one of the used templates or create a new template and add tomato soup to list of key phrases.\n6. One template = one receipt. If two templates are assigned to a printer, two different receipts in two different quantities specified in number of copies field (two templates, each with number of copies equal to two will print together 4 receipts) are going to be printed. Such solution might be considered useful in case where you`d like to print on bar`s printer both drinks and appetizers, but on different receipts.',
      allMealsRemainingMealsCollapsible: 'ALL MEALS/REMAINING MEALS',
      allMealsRemainingMealsCollapsibleDescription:
        'By default two non-deletable base receipt templates are created for each restaurant: ALL MEALS and REMAINING MEALS.\n1. If template ALL MEALS is assigned to printer, then receipt with all meals of given order will be printed.\n2. If REMAINING MEALS template is assigned to any of the printers, then meals that were not matched to any of the listed key phrases of other template will be printed on the given printer.\nALL MEALS template behave always in the same manner, no matter what their quantity is. If we have two of these templates assigned to our printers, both printers will print receipts with every meal on them.\n4. If you would like the printer to print two receipts with all meals, the recommended way is to increase the number of copies rather than creating second template with type ALL MEALS. Second template should be created only in order to accomplish printing all meals on two receipts but with different template design.',
      deleteReceiptTemplate: 'Are you sure you want to delete this template? Its data will be removed permanently',
      addNewTemplateTemplateType: 'What kind of template would you like to add?',
      whitelistTemplateType: 'Template with list of key phrases',
      allMealsTemplateType: 'Template with type ALL MEALS',
      remainingMealsTemplateType: 'Template with type REMAINING MEALS',
      ALLMEALS: 'ALL MEALS',
    },
    navMain: {
      orders: 'Orders',
      ordersCompletedToday: 'Orders completed today',
      settings: 'Settings',
      newOrder: 'New order',
      logout: 'Logout',
      loggedInAs: 'Logged in ',
      couriersMap: 'Map',
      chooseRestaurant: 'Choose a restaurant',
      addFunds: 'Add funds',
      newDelivery: 'New delivery',
      onSite: 'On site',
      scrapeSuccess: 'Getting',
      confirmMealChangeLoss: 'Do you wish to discard unsaved meal changes?',
      discardChanges: 'Discard changes',
      saveChanges: 'Save changes',
      papuHelp: 'Papu.io help',
      helpManager: 'Customer Service Manager',
      help: 'Help',
      canWeHelp: 'Can we help you?',
      addOrder: 'Add order',
      createNewOrder: 'Create new order',
      delivery: 'Delivery',
      chat: 'Contact us via chat',
    },
    subscriptions: {
      balance: 'Balance',
      orders: 'orders',
      days: 'days',
      run: 'Run',
      addFunds: 'Add funds',
      acceptTerms: 'Accept {0}',
      terms: 'terms and conditions',
      termsAcceptRequired: 'Terms and conditions acceptance is required',
      paymentsHistory: 'Payments history',
      numberOfOrders: 'Number of orders',
      created: 'Created at',
      value: 'Value',
      inSubscription: 'In subscription',
      left: 'Left',
      used: 'Used',
      downloadInvoice: 'Download invoice',
      download: 'Download',
      gross: 'gross',
      trial: 'Trial',
      paymentTrue: 'Thank you! Your payment has been accepted.',
      paymentFalse:
        'We are sorry but there has been an error during payment. Please try to buy a subscription again. If you have any questions please feel free to contact us.',
      net: 'net',
      name: 'Name',
      address: 'Address',
      postal_code: 'Postal code',
      city: 'City',
      nip: 'Tax ID',
      email: 'E-mail address',
      emailTooltip: 'An invoice will be sent to the e-mail address provided',
      update: 'Update',
      edit: 'Update company data',
      editInfo: 'Below data will be used to fill in the invoice for the purchased subscription.',
      required: 'This field is required',
      companySaved: 'Company data saved!',
      additionalInvoiceData: 'Additional invoice data',
      additionalInvoiceDataInfo: "This data will be additionally placed under the company's data",
    },
    billing: {
      name: 'Name',
      startedAt: 'Started at',
      manHours: 'Man-hours',
      penalties: 'Penalties',
      bonuses: 'Bonuses',
      advances: 'Advances',
      kilometrage: 'Kilometrage',
      numberOfOrders: 'Number of orders',
      toBePaidOut: 'To be pay out',
      finishedAt: 'End of period',
      showRemovedEmployees: 'Show removed employees',
      hideRemovedEmployees: 'Hide removed employees',
      filterByEmployee: 'Filter by employee',
      closePeriod: 'Close period',
      addAdvance: 'Add advance',
      addPenalty: 'Add penalty',
      addBonus: 'Add bonus',
      amount: 'Add amount',
      comment: 'Add comment',
      cancel: 'Add cancel',
      add: 'Add',
      confirmation: 'Confirmation of salary payment will reset and close the current billing period.',
      confirm: 'Confirm',
      confirmAndPrint: 'Confirm and print',
      closed: 'Closed',
      start: 'Start',
      end: 'End',
      operationalCash: 'Operational costs',
      paymentsFromCustomer: 'Payments from customer',
      balance: 'Balance',
      shiftStart: 'Date and time of shift start',
      shiftFinish: 'Date and time of shift end',
      initialMileage: 'Initial mileage',
      finalMileage: 'Final mileage',
      kilometers: 'Kilometers',
      mileageStart: 'Mileage (start)',
      mileageFinish: 'Mileage (finish)',
      terminalNumber: 'Terminal number',
      returnedOperationalCash: 'Returned operational cash',
      returnedCashForOrders: 'Returned cash for orders',
      operationalCashOut: 'operational cash given',
      terminalReturned: 'Terminal returned',
      save: 'Save',
      startDay: 'Start day',
      endDay: 'End day',
      newShift: 'New Shift',
      role: 'Role',
      noBillingPeriodAlert: 'Please enable billing period for this user',
      noSalaryConfigAlert: 'Go to employee edit and configure salary config',
      operationalBalance: 'Operational balance',
      cashFromOrders: 'Payments from orders',
      details: 'Details',
      closeBillingPeriod: 'Close also billing period',
      period: {
        component: 'Component',
        amount: 'Amount',
        value: 'Value',
      },
      pendingOrdersWarning:
        'Nie wszystkie zamówienia zostały zakończone. Zakończenie ich przez pracownika, może wpłynąć na wysokość salda zmiany tego pracownika (nawet jeśli zmiana jest zakończona)',
      billingPeriodIsFinished: 'Billing period is finished.',
      acceptedCash: 'Accepted',
      givenBack: 'Given back',
      nameLastName: 'First and last name',
      date: 'Date',
      type: 'Type',
      none: 'None',
      notAllOrdersWithDA: 'Not all orders have delivery area assigned.',
      ordersToDA: 'Orders to delivery areas',
      orders: 'Orders',
      operationalCashBalance: 'Operational cash balance',
      beginning: 'Beginning',
      all: 'All',
      closeAndSettle: 'Close and settle billing period',
      closedDate: 'Closed date',
      shifts: 'Shifts',
      shiftEdit: 'Edit',
      shiftEditInfo: 'Shift editing is available only for manager.',
      shiftEditFinishedBillingInfo: 'Shift editing is available only for last shift of last unfinished billing period',
      reopenBillingPeriod: 'Reopen and correct billing period',
      reopenBillingPeriodInfo: 'Billing period can be reopened only by manager.',
      reopenBillingPeriodLastInfo: 'Billing period editing is available only for last billing period of employee.',
    },
    shifts: {
      restaurantCash: 'Restaurant cash',
      value: 'Value',
      cashForOrders: 'Cash for order',
      operationalCashBalance: 'Operational cash balance',
      sum: 'Sum',
      salary: 'Salary',
      quantity: 'Amount',
      employeePrice: 'Price',
      orders: 'Orders',
      notAllOrdersWithDA: 'Not all orders have delivery area assigned.',
      manHours: 'Worked hours',
      kilometres: 'Kilometres',
      date: 'Date',
      type: 'Type',
      amount: 'Amount',
      comment: 'Comment',
      none: 'None',
      data: 'Data',
      shiftStart: 'Shift start',
      shiftEnd: 'Shift end',
      startKilometres: 'Kilometres - start',
      endKilometres: 'Kilometres - end',
      lasts: 'Lasts',
      no: 'No',
      address: 'Address',
      deliveryArea: 'Delivery area',
      price: 'Price',
      payment: 'Payment',
      status: 'Status',
      finishedType: 'Finished type',
      orderNotAssignedDA: 'Order not assigned to delivery area.',
      beginning: 'Beginning',
      end: 'End',
      billingPeriod: 'Okres rozliczeniowy',
      billingPeriodLink: 'Go to',
      shift: 'Shift',
    },
    regions: {
      title: 'Delivery regions',
      addNewBtnText: 'Add new region',
      noRegions: 'No delivery regions',
      regionName: 'Region name',
      active: 'Active',
      save: 'Save',
      cancel: 'Cancel',
      courierPrice: 'Courier price',
      freeDeliveryFromValue: 'Free delivery value',
      minOrderValue: 'Min order value',
      deliveryPrice: 'Delivery price',
      errors: {
        noPolygon: 'There is no drawed region. Please draw region on map.',
        polygonMinVertexes: 'Region need to have min of 3 vertexes.',
        areaRadiusInput: 'Or input radius',
      },
      list: 'Regions list',
      map: 'Map',
      regionUpdateSuccess: 'Delivery area saved successfully',
    },
    invoiceModal: {
      titleEdit: 'Edit invoice',
      title: 'Print Invoice',
      companyName: 'Company name',
      streetAddress: 'Street address',
      postalCode: 'Postal code',
      city: 'City',
      taxIDNumber: 'Tax identification number',
      billNumber: 'Bill number',
      hideDetails: 'Whithout details',
      allFieldsRequired: 'Fields marked with * are required',
      hideName: 'Entry name',
      hideNames: {
        default: '‘Usługa gastronomiczna’',
        custom: 'Custom',
      },
      paymentType: 'Payment type',
      paymentTypes: {
        order: 'From order',
        custom: 'Custom',
        cash: 'cash',
        card: 'card',
        paid: 'online',
      },
      sendInvoiceByEmail: 'Send invoice by email',
      printInvoice: 'Print invoice',
    },
    administration: {
      settings: 'Customization',
      localizationConfig: {
        message: 'Restaurant settings',
        localizationName: 'Restaurant name',
      },
      ordersSummaryTimeFilters: {
        message: 'Default date filters for orders summary and date range for order numbering',
        weekday: 'Day of week',
        start: 'Start',
        end: 'End',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },
      openingHours: {
        message: 'Restaurant opening hours',
      },
      integrationsConfig: {
        message: 'Choose integrations for which orders should be printed automatically',
        receiptAuto: 'Automatic printing of receipts',
        phone: 'Phone orders',
        pyszne: 'Pyszne.pl orders',
        restaumatic: 'Restaumatic.com orders',
        upmenu: 'Upmenu.pl orders',
        erestaurant: 'Erestaurant orders',
        ubereats: 'Ubereats.com orders',
        groupon: 'Groupon.pl orders',
        new_erestaurant: 'Erestaurant orders',
        ordersmart: 'OrderSmart orders',
      },
      integrationsNewOrderStatus: {
        message: 'Choose default tab for new orders from integrations',
      },
      orders: {
        message: 'Order on site',
        orderOnSite: 'Order on site',
        printBillCheck: 'Default check for bill printing option',
        finishOrderCheck: 'Default check for order finish option',
        orderDelivery: 'Delivery orders',
        printBillDeliveryCheck: 'Default check for bill printing option',
        orderDetails: 'Order details',
        closeOrderOnPrintBill: 'Close order after pressing "Receipt" button',
        ordersCommonConfig: 'Orders - general',
        manualSourceChoiceRequired:
          'Require that an order source be selected for new orders ' +
          'and hide the Default source in the list of order sources.',
      },
      deliveryOnBill: {
        message: 'Delivery on bill',
        delivery: 'Delivery',
        vatRate: 'VAT rate',
        positionNameOnBill: 'Position name on bill (optional)',
        skipDeliveryOnBillAndInvoice: 'Skip delivery on bill and invoice',
      },
      tip: {
        message: 'Tips on bill',
        tips: 'Tips',
        vatRate: 'VAT rate',
        positionNameOnBill: 'Position name on bill (optional)',
        skipTipOnBillAndInvoice: 'Skip tips on bill and invoice',
      },
      defaultPosView: {
        message: 'Choose default view for Papu.io system',
        defaultView: 'Default view',
        map: 'Map',
        list: 'Orders list',
      },
      halfPizza: {
        message: 'Fifty-fifty pizza calculation',
        halfPizzaPrice: 'Half pizza price',
        additionalPrice: 'Additional price for fifty-fifty pizza',
        halfPizzaAvgPrice: 'Average price from both halves + additions prices',
        halfPizzaMoreExpensive: 'More expensive half price + additions prices',
        halfPizzaMcgPrice: "Addition's price of single half pizza",
        halfPizzaMcgHalfPrice: 'Half price of addition',
        halfPizzaMcgFullPrice: 'Full price of addition',
      },
      shifts: {
        message: 'Shifts',
        oneDayBillingPeriods: 'One-day billing periods',
        closeBillingPeriod: 'Close billing periods by default with shift',
      },
      summary: {
        message: 'Summary reports',
        summaries: 'Settings regarding sending summaries to e-mail',
        summary_description:
          'Daily summary - contains information regarding finished orders of last day based ' + 'on set time filters',
        summary_fiscal_description: 'Daily fiscal summary - contains information regarding printed orders of last day',
      },
      integrationsSettings: {
        message: 'Integrations',
        includeUberPromotionDescription:
          'Include UberEats offer amount (code) in order price and receipt value as a discount',
        subtractStampcardFromPrice: 'Subtract stampcards value from order price',
        glovoServiceChargeVat: 'VAT rate of Glovo service charge',
        gloVoServiceChargeNameOnBill: 'Glovo service charge position name on bill (optional)',
        takeawayLabel: 'Takeaway',
      },
      notifications: {
        message: 'Notifications',
        newOrder: 'New order',
        soundNotification: 'Sound notification',
      },
      settingsSaved: 'Settings saved',
      saveChanges: 'Save changes',
    },
    erestaurant: {
      erestaurant: 'E-Restaurant settings',
      message: 'Settings for your E-Restaurant',
      link: 'Link',
      preview: 'Preview',
      logo: 'E-Restaurant logo image',
      background: 'E-Restaurant background image',
      icon: 'E-Restaurant icon',
      orderEmail: 'E-mail address required in order placing - order confirmation will be send to this e-mail',
      differentPrices: 'Should E-Restaurant for {restaurant} have different prices of meals than POS?',
      active: 'E-Restaurant active?',
      activeTooltip: 'Turn on/off online shop',
      complexMealsAvailable: 'Should half by half meals be available in the E-Restaurant?',
      showRestaurantEmail: 'Show restaurant e-mail',
      sendOrderConfirmationEmailToBCC: 'Should we send a copy of the order`s confirmation e-mail to the restaurant?',
      brandActive: 'E-Restaurant active for restaurant {restaurant}?',
      brandActiveTooltip: 'Turn on/off E-restaurant for restaurant {restaurant}',
      openingHours: 'E-Restaurant opening hours for {restaurant}',
      ordersTimeLimit: {
        message: 'E-Restaurant configuration concerning ordering time for {restaurant}',
        pickup: 'Pickup orders',
        delivery: 'Delivery orders',
        minTime:
          'The minimum time from placing an order to its completion. This value is added to the current time ' +
          'for "On time" orders, creating a minimum time for placing an order on the selected date.',
        maxTime:
          'The maximum time for placing an order before closing the restaurant. This value is subtracted ' +
          'from the time the restaurant closes, creating the maximum time that you can order on the selected day.',
      },
      minutes: 'minutes',
      choose: 'Choose',
      loadFile: 'Load file',
      analytics: 'Analytics',
      googleAnalyticsInfo: 'Insert GoogleAnalytics ID (UA-XXXXXXXXX-X)',
      googleTagManagerInfo: 'Insert Google Tag Manager ID (GTM-XXXXXXX)',
      facebookPixelId: 'Facebook Pixel ID',
      facebookPixelIdInfo: 'Insert Facebook Pixel ID',
      facebookPixelMetaTag: 'Facebook Pixel meta-tag ID (field "content")',
      facebookPixelMetaTagInfo: 'Insert Facebook Pixel meta-tag ID (field "content")',
      imageResolution: 'Minimal recommended resolution: 500 x 500 pixels.',
      title: 'Site title',
      description: 'Site description',
      descriptionHtml: 'Site description as HTML',
      descriptionHtmlTooltip:
        'Site description displayed above the page footer. It can contain HTML code without scripts.',
      keywords: 'Keywords',
      descriptionTooltip:
        'DESCRIPTION meta tag. A short description of the page summarizing its content. The main task of an optimized description is to increase the click-through rate. Maximum number of characters 155.',
      keywordsTooltip:
        'These tags describe the content of the site. Keywords are usually lowercase and separated by commas. They are not visible on the page, only in its code. They help in positioning the site.',
      erestoDisableDelivery: 'Disable deliveries in {restaurant}',
      erestoDisableTakeaway: 'Disable takeaways in {restaurant}',
    },
    permissions: {
      permissionsSettings: 'Permissions settings',
      ordersPermissions: "Orders' permissions",
      pastHistoryOrdersView:
        'Decide how old orders your employees shall see (number of days back) on archive view and orders summary view',
      days: 'day | days',
      updateFinishedOrders: 'Employee can change finished type and payment method of finished order',
      orderPaymentPaidChange:
        'Employee can change the payment method from "Paid" to another and from another to "Paid"',
      employeesPermissions: "Employees' permissions",
      manageEmployees: 'Waiter can manage employees list',
      createPaidOrders: 'Employee can add orders with the payment method "Paid".',
    },
    menu: {
      menuSave: 'Save menu',
      menuEdit: 'Menu management',
      imageLoading: 'Uploading image, please wait',
      defaultSizeName: 'Default',
      mealsCount: 'Number of meals in given category',
      sizesCount: 'Number of sizes in given category',
      categorySizeMealsCount: 'Number of meals in chosen category in chosen size',
      sizeMealsCount: 'Number of meals in given size',
      choiceGroupMealsCount: 'Number of meals containing given group',
      menuNotSaved: 'The changes were not saved. Do you want to save?',
      save: 'Save',
      saving: 'Saving...',
      savingWait: 'Saving. Please wait...',
      leaveWithoutSaving: "Don't save and exit the menu edit panel",
      menuSavingError: 'Menu saving error',
      menuSaved: 'The menu has been saved',
      menuChangesAfterWaiterRelog:
        "In order for the changes made to the menu to appear on the waiter's account, " +
        "please log out of the waiter's account and log in again",
      saveMenu: 'Finish editing and save the menu',
      exitMenu: 'Exit the menu management panel',
      editMenuExternalSource: 'External menu',
      vatRate: 'VAT Rate',
      noCategories: 'No categories',
      notFoundMeal: 'We did not find the phrase you were looking for in the meals',
      deleteMsgBox: {
        confirm: 'Are you sure you want to delete the item?',
        ok: 'Yes',
        cancel: 'No',
      },
      publish: 'Publish',
      published: 'Published',
      publishModalText: 'Möchten Sie Menüänderungen vor dem Beenden veröffentlichen??',
      publishModalMenuChangeText: 'Dou want to publish your changes before selecting a different menu?',
      choiceGroups: {
        groupName: 'Group name',
        cloneGroup: 'Clone group',
        required: 'Required',
        requiredInfo: 'Check if choosing modifier from this group needs to be required',
        defaultScge: 'Modifier selected by default',
        defaultScgeInfo: 'It is always initially selected modifier',
        add: 'Add new category',
        name: 'Name',
        price: 'Price',
        addNext: 'Add next',
        availableInEresto: 'Group available in E-Restaurants?',
        mealsWithSelectedChoiceGroups: 'Meals with a selected group of additives',
        chooseCategoryToEdit: 'Select the category for which you want to change ',
        chooseSizeToEdit: 'Select the size for which you want to change ',
        addGroupForSelectedSize: 'Add a new group for the selected size',
        increaseAllEntriesPrice: 'Increase the price of all by 1',
        decreaseAllEntriesPrice: 'Decrease the price of all by 1',
        changeAllEntriesPrice: 'Change the price of all by:',
        multiChoiceGroupTitle: 'Additives',
        singleChoiceGroupTitle: 'Modifiers',
        noEntries: 'You cannot save a group without any items. Add at least one item in the left menu edit panel.',
        noSelectedMeals: 'You must select at least one meal to which this group will be assigned',
        multiChoiceGroupsTitle: 'Additives groups',
        multiChoiceGroup: 'Additives group',
        noMultiChoiceGroups: 'No additives groups.',
        singleChoiceGroupsTitle: 'Modifiers groups',
        singleChoiceGroup: 'Modifiers group',
        noSingleChoiceGroups: 'No modifiers groups.',
        informations: 'Informations',
      },
      meal: {
        title: 'Meals',
        ingredientDuplicatedInfo: 'The meal cannot contain more than 1 ingredient of the same name',
        name: 'Meal name',
        size: 'Size',
        ingredients: 'Meal ingredients - what the meal consists of',
        addIngredient: 'Add an ingredient',
        availableInEresto: 'Is meal available in the E-Restaurant?',
        description: 'Description of the meal',
        visibleInEresto: 'The image of the meal visible in the E-Restaurant',
        chooseCategoryToEdit: 'Select the category for which you want to change meals',
        add: 'Add new meal',
        price: 'Price',
        erestoPrice: 'E-Restaurant price',
        noPrices: 'The field with the price of the meal can not be empty.',
        noMealSizes: 'The meal must be assigned to at least one size.',
        mealNameExists: 'A meal with this name already exists in this category.',
      },
      sizes: {
        title: 'Sizes',
        name: 'Name',
        defaultSize: 'Default size?',
        defaultTooltipText: 'This will always be the initially selected size when creating a new order.',
        fiftyFiftyMeals: 'Can size meals be divided, e.g. pizza boxes in half?',
        availableInEresto: 'Size available in the E-Restaurant?',
        mergeSizes: 'Merge other sizes to this size',
        selectMergeSizes: 'Select the size you want to merge to that size',
        add: 'Add a new size',
        deleteWarning:
          'The selected size from the list will be removed and its meals will be added to the current size (gray).',
      },
      categories: {
        title: 'Categories',
        add: 'Add new category',
        name: 'Category name',
        defaultCategory: 'Default Category?',
        defaultTooltipText: 'This will always be the category initially selected when creating a new order.',
        default: 'Default',
        availableInEresto: 'Category available in the E-Restaurant?',
        visibleInEresto: 'Category image visible in the E-Restaurant',
        addSize: 'Add sizes',
        addNextSize: 'Add next size',
        moreThanOneSizeInCategory: 'Are the meals in the category more than 1 size?',
        noCategories: 'No categories',
        kdsStatuses: 'The status of meals in KDS',
        kdsStatusesModalTitle: 'Select a status set',
        kdsStatusesTooltip:
          'Select a set of meal statuses that will apply in the KDS application for all meals in this category.',
      },
      states: {
        saved: 'Item saved.',
        added: 'Item added.',
        deleted: 'Item deleted.',
      },
      edit: {
        categories: 'Edit category',
        sizes: 'Edit size',
        multi_choice_groups: 'Edit additives group',
        single_choice_groups: 'Edit a group of modifiers',
        meals: 'Edit a meal',
        noEditElementTitle: 'No item to edit.',
        noEditElementBody: 'Select item from menu to edit it.',
      },
      new: {
        categories: 'New category',
        sizes: 'New size',
        multi_choice_groups: 'New additives group',
        single_choice_groups: 'New modifiers group',
        meals: 'New meal',
      },
    },
    mealSets: {
      mealSetsSettings: 'Meal sets',
      discount: 'Discount',
      count: 'Quantity',
      category: 'Category',
      sizes: 'Sizes',
      meals: 'Meals',
      newMealSet: 'New meal set',
      addMealSet: 'Add meal set',
      editMealSet: 'Edit meal set',
      mealSetName: 'Meal set name',
      mealSetNameInput: 'Enter meal set name',
      mealSetItems: 'Meal set items',
      created: 'Created date',
      brandName: 'Restaurant',
      name: 'Name',
      items: 'Meal set items',
      type: 'Type',
      normal: 'Normal',
      halfByHalf: 'Fifty-fifty',
      mealSetInEresto: 'Should meal set be available in E-Restaurant?',
      mealSetDiscount: 'Meal set discount',
      backToMealSetsList: 'Back to meal sets',
      saveMealSet: 'Save meal set',
      addMealSetItem: 'Add item to meal set',
      halfPizzaMealSetItemInfo: 'In fifty-fifty meal set both items are the same.',
      mealSetItemQuantity: 'Quantity of meals from among the categories / sizes / meals selected below',
      mealSetItemDiscount: 'Meal set item discount',
      chooseCategory: 'Choose category',
      chooseSizes: 'Choose sizes',
      chooseMeals: 'Choose meals',
      all: 'All',
      checkUncheckAll: 'Choose/clear all',
      backToMealSet: 'Back to meal set',
      saveMealSetItem: 'Save meal set item',
      allSizesOfCategory: 'All sizes of category',
      allMealsOfCategory: 'All meals of category',
      allMealsOfSizes: 'All meals of sizes',
      noMealSetItems: 'No items in meal set',
      item: {
        itemCount: 'Number of meals from among the categories/sizes/meals selected below',
        itemDiscount: 'Meal set item discount',
        chooseItemCategory: 'Choose meal set item category',
        chooseItemSizes: 'Choose meal set item sizes',
        chooseItemMeals: 'Choose meals available to be chosen for this meal set item',
      },
      discountSort:
        'Discounts for dishes of the same categories and sizes are sorted to assign the least discount to the most expensive dish.',
    },
    mealsetEdit: {
      title: 'Meal sets',
    },
    discounts: {
      discountsSettings: 'Discounts',
      addDiscount: 'Add discount',
      discountView: 'Discount preview',
      noDiscount: 'No discount',
      list: {
        new: 'New',
        created: 'Created at',
        fromDatetime: 'From',
        toDatetime: 'To',
        used_codes: 'Used codes',
        usage: 'Usage',
        discountType: 'Discount type',
        discountValue: 'Discount',
        active: 'Active',
        details: 'Details',
        discountChoiceValue: 'Discount value',
        discountChoicePercent: 'Percent discount',
        discountChoicePromotionalPrice: 'Promotional price',
        emptyList: 'No discounts',
      },
      edit: {
        noLimit: 'No limit',
        limitedTime: 'Limited time',
        discountValuePrice: 'Discount value',
        discountValuePercent: 'Percent discount',
        discountPromotionalPrice: 'Promotional price',
        provideDiscountValue: 'Provide discount value',
        provideDiscountPercent: 'Provide discount percent',
        providePromotionalPrice: 'Provide promotional price',
        codesToGenerate: 'How many unique codes to generate',
        codesToGeneratePlaceholder: 'Provide the number of codes to generate',
        perCodeUsage: 'How many times each code can be used',
        perCodeUsagePlaceholder: 'Provide the number of uses of generated codes',
        addDiscount: 'Add discount',
        removeDiscount: 'Remove discount',
        codes: 'Codes',
        confirmRemoveDiscount: 'Are you sure you want to remove the discount?',
      },
    },
    notifications: {
      deletable: 'After clicking X this message will be removed permanently',
    },
    regeocodeOrders: {
      orderIsAlreadyInRegeocode: 'The order is already being re-geolocalized',
      orderRegeocodeIsBlocked: 'Re-geolocation is blocked for the order',
      regeocodeOrdersSuccess: 'Re-geolocating orders',
      regeocodeOrdersError: 'A re-geolocation error has occurred for an order',
      relocating: 'Re-geolocating...',
      relocatingError: 'The order address could not be geo-located again. Please contact Technical Support.',
    },
    splitBill: {
      split: 'Split',
      splitIntoEqualParts: 'Split into equal parts',
      extractTheAmount: 'Extract the amount',
      addBill: 'Add a bill',
      addFirstBill: 'Add first bill',
      emptyBillDescription: 'The receipt is empty.\n Add an item from an order.',
      emptyBaseBillDescription: 'No items for settlement',
      moveOrderItemsHeading: 'How many items to move?',
      numberOfItems: 'Number of items',
      splitBillHeading: 'How many bills to divide?',
      numberOfBills: 'Number of bills',
      enterAmount: 'Enter an amount',
      enterPercentage: 'Enter a percentage',
      enterQuantity: 'Enter a quantity',
      selectPayment: 'Select payment method',
      mark: 'B',
      bill: 'Bill',
      gettingBills: 'Loading bills',
      deleteBillModal: {
        title: 'Delete a bill',
        description:
          'Are you sure you want to delete the account? \n All items from the account will be transferred to the main account.',
      },
      status: {
        ended: 'Ended',
        printed: 'Printed',
        printError: 'Print error',
      },
      error: {
        closeBill: 'There was an error closing the order.',
        movePosition: 'The selected bill is not editable. Select another bill or create a new one.',
      },
    },
    integrations: {
      active: 'Active',
      wolt: {
        on: 'Turn on restaurant on Wolt',
        off: 'Turn off restaurant on Wolt',
      },
      woltConfirmation: 'The toggle restaurant process on Wolt has been completed.',
      woltFailure: 'An error occurred during toggle restaurant on Wolt.',
      online: 'Restaurant online',
      offline: 'Restaurant offline',
      updateMenu: 'Update menu',
      updateMenuConfirmation:
        'The updating menu process from Dotykačka has been started. Please log out and log in again after ~1 minute for changes to become visible.',
      updateMenuFailure: 'An error occurred while updating the menu.',
    },
    mapping: {
      mappingMenu: 'Mapping menu',
      mapping: 'Mapping',
      mapIt: 'Bind',
      checkMapping: 'Check',
      bind: 'Bind',
      ignore: 'Ignore',
      unblock: 'Unblock',
      editMapping: 'Edit mapping',
      selectMealToMap: 'Select menu item to bind',
      showUnmaped: 'Show items to mapping',
      showMapped: 'Show mapped items',
      showIgnored: 'Show ignored items',
      dynamicModeTitle: 'Mapping missing items in order no. ',
      showMappedPositions: 'Show mapped positions',
      meals: 'Meals',
      singleChoice: 'Modifiers',
      multiChoice: 'Additives',
      mappingBtnTooltip: 'The meal or its additive/modifier is not mapped. Go to mapping view to bind this items.',
      mappingBtnTooltipMapped: "Go to mapping view to check this meal's binding.",
      table: {
        positionFromIntegration: 'Item from integration',
        positionFromMenu: 'Item from integration',
        noMappedPositions: 'No mapped items',
        noPositionsToBind: 'No items to mapping',
        noMapping: 'No mapping',
        noPositionInMenu: 'No items in default menu',
        mealsInGroup: 'Meals in group',
      },
      remove: {
        removeMapping: 'Unbind',
        promptQuestion: 'Are you sure you want to delete binding from this item?',
        success: 'Binding removed from position: ',
      },
      mappingSuccess: 'Position bind',
      ignoreMappingSuccess: "Position ignored and won't be send to integration",
      sourceButtonAllLabel: 'All',
      isArchivedWarningTooltip: 'Meal/Additive/Modifier which is binded to this item has been deleted.',
    },
    workstations: {
      title: 'Workstations',
      addNewWorkstation: 'Add new workstation',
      activeWorkstation: 'Active',
      noWorkstation: 'No workstations',
      userLogout: 'Offline',
    },
    workstationEdit: {
      form: {
        name: 'Workstation name',
        color: 'Color',
        user: 'Assigned users',
        mealCategories: 'Meal categories',
        selectMealCategories: 'Select meal categories',
      },
      addButton: 'Add workstation',
      saveButton: 'Save workstation',
      workstationDeleted: 'Workstation deleted',
      workstationUpdated: 'Workstation updated',
    },
    kds: {
      mealStatus: {
        todo: 'Waiting',
        doing: 'Doing',
        inOven: 'In the oven',
        done: 'Done',
      },
    },
  },
  mealset_in_erestaurant_info: 'Some menu items may not be available for e-restaurants.',
  choose_brand: 'Choose brand',
  package_settings: 'Package settings',
  package_vat_rate: 'VAT rate',
  packages: 'Packages',
  search_by: 'Search by',
  address: 'Address',
  customer_name: 'Customer name',
  phone_number: 'Phone number',
  finished_type_translated: 'Finished status',
  payment_translated: 'Payment method',
  price: 'Price',
  status: 'Status',
  user: 'User',
  change: 'Change',
  field: 'Field',
  value: 'Value',
  change_date: 'Change date',
  old_value: 'Old value',
  new_value: 'New value',
  accepted: 'Accepted',
  finished: 'Finished',
  order_changes_history: 'Order changes history',
  delivering_in: 'In delivery',
  new: 'New',
  picked_up: 'Picked up by courier',
  city: 'City',
  need_help: 'Need help?',
  description: 'Description',
  no_results: 'No results',
  address_hint_general_error: 'Failed to retrieve the hint for this address.',
  erestaurant: 'E-Restaurant',
  erestaurant_banner_url: 'Redirect link from banner',
  erestaurant_banner_url_tooltip:
    'After clicking on the banner, the user will be redirected to the specified URL. This can be any website address or a link to a menu item.',
  courier_in_base: 'In base',
  courier_coming_back: 'Coming back',
  courier_in_delivery: 'In delivery',
  newRecommendation: 'New recommendation',
  assignCourierToOrder: 'Assign courier to order',
  sendCourierWithOrders: 'Send courier with orders',
  deactivate: 'Deactivate',
  deactivate_meal_in_eresto: 'Deactivate meal in E-resto',
  deactivate_meal_in_eresto_tooltip: 'Deactivating this meal in e-resto will deactivate the following sets',
  notification: 'Notification',
  notificationTypes: {
    manual: {
      title: 'Notification',
    },
    automatic: {
      title: 'Notification',
    },
    'connection-failed': {
      title: 'Printer connection error',
    },
    'connection-established': {
      title: 'Printer connection restored',
    },
    printing: {
      title: 'There was a problem printing the PTU',
    },
    'subscription-ends': {
      title: 'Subscription ended',
    },
    'new-feature': {
      title: 'Check out new features',
    },
    regeocode: {
      title: 'Order geolocation',
    },
    'order-canceled': {
      title: 'Order has been canceled',
    },
    alert: {
      title: 'Alert!',
    },
    '7-days-subscription-ends': {
      title: 'Subscription will end in 7 days!',
    },
    '3-days-subscription-ends': {
      title: 'Subscription will end in 3 days!',
    },
    '1-day-subscription-ends': {
      title: 'Subscription will end in 1 day!',
    },
    '1-hour-subscription-ends': {
      title: 'Subscription will end in 1 hour!',
    },
  },
  provide_external_map_url: 'Provide a link to a point on an external map, e.g.',
  coordinates_will_be_reassigned_from_url: 'Geographic coordinates will be overwritten with those from the link',
  fetch_coordinates: 'Fetch coordinates',
  no_delivery_area: 'No delivery area',
  fetching_coordinates_from_url_error: 'Failed to fetch coordinates from the link',
  external_url_is_invalid: 'The link is invalid. Please try providing a link from Google Maps or Targeo.',
  geolocation_for_order_saved: 'Geolocation for order {order_number} has been saved.',
  edit_geo_modal_tip: 'Click on the map to mark the geolocation.',
  latitude: 'Latitude',
  longitude: 'Longitude',
  external_map_link: 'Link to external maps',
  actual: 'Current',
  edit_geolocalization: 'Edit geolocation',
  delivery_area: 'Delivery area',
  courier_free: 'Free',
  courier_busy: 'Busy',
  reject: 'Reject',
  kitchen_settings: 'Kitchen settings',
  save: 'Save',
  save_successfully: 'Saved successfully',
  or: 'or',
  enlargeMap: 'Enlarge map',
  fitMapToOrders: 'Fit map to orders',
  showDeliveryAreas: 'Show delivery areas',
  update_delivery_price_info_by_hand:
    'The delivery price will not update automatically in the order. If you want to change it, please edit the order manually.',
  default: 'Default',
  order_price: 'Order price',
  coordinates: 'Coordinates',
  new_coordinates: 'New coordinates',
  edited: 'Edited',
  address_verified_and_corrected_manually: 'Address verified and corrected manually',
  delete_address_from_customer: 'Delete customer address',
  searching_customers: 'Searching customers...',
  searching_delivery_area: 'Searching delivery area...',
  report_sugestion: 'Report sugestion',
  order_number: 'Order number',
  open_in_external_map: 'Open address in external map',
  driver_will_get_address_not_coordinates: 'The address will be sent to the courier app, not the coordinates.',
  coordinates_updated_from_link: 'Coordinates were updated from link!',
  no_coordinates_found: 'No coordinates found for this link.',
  error_resolving_short_url: 'Error resolving short URL.',
  google_maps: 'Google Maps',
  targeo_map: 'Targeo Map',
  to_get_coordinates_from_google_maps_link: 'To get coordinates from Google Maps',
  to_get_coordinates_from_targeo_map_link: 'To get coordinates from Targeo Map',
  geo_edit_google_instruction_1: 'Open',
  geo_edit_google_instruction_2: 'Find correct coordinates for the given address',
  geo_edit_google_instruction_3: 'Click on the selected address or on the correct coordinates',
  geo_edit_google_instruction_4: 'Click "Share"',
  geo_edit_google_instruction_5: 'Copy the received link',
  geo_edit_google_instruction_6: 'Copied link paste in the "Link to point on maps targeo or google" field',
  geo_edit_google_instruction_7: 'New coordinates will be overwritten with those from the link',
  geo_edit_targeo_instruction_1: 'Open',
  geo_edit_targeo_instruction_2: 'Find correct coordinates for the given address',
  geo_edit_targeo_instruction_3: 'Click right mouse button on the correct coordinates',
  geo_edit_targeo_instruction_4: 'Select from menu "Link to this place"',
  geo_edit_targeo_instruction_5: 'Copy the received link',
  geo_edit_targeo_instruction_6: 'Copied link paste in the "Link to point on maps targeo or google" field',
  geo_edit_targeo_instruction_7: 'New coordinates will be overwritten with those from the link',
  selectMenu: 'Select menu',
  default_a: 'Default',
}
