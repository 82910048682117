<template>
  <l-marker v-if="validateCoords(coords)" :lat-lng="coords" :icon="getIcon()">
    <l-tooltip
      :options="{
        permanent: true,
        interactive: true,
        direction: 'top',
        offset: [0, -15],
        className: 'localization-marker',
      }"
    >
      <div>
        {{ label }}
      </div>
    </l-tooltip>
  </l-marker>
</template>

<script>
import Leaflet from 'leaflet'
import { LMarker, LTooltip } from 'vue2-leaflet'
import localizationMapMarker from '@/../public/static/images/localization-map-marker.svg'
import { validateCoords } from '@/utils/common'
import { mapGetters } from 'vuex'

export default {
  name: 'LocalizationMapMarker',
  components: { LMarker, LTooltip },
  props: {
    ordersCount: { type: Number, default: 0 },
  },
  data() {
    return {
      validateCoords,
    }
  },
  computed: {
    ...mapGetters('auth', ['getLocalizationName', 'getLocalizationLat', 'getLocalizationLon']),
    label() {
      return `${this.getLocalizationName} (${this.ordersCount})`
    },
    coords() {
      if (!this.getLocalizationLat || !this.getLocalizationLon) return false
      return Leaflet.latLng(this.getLocalizationLat, this.getLocalizationLon)
    },
  },
  methods: {
    getIcon() {
      return Leaflet.icon({
        iconUrl: localizationMapMarker,
        iconSize: [30, 30],
        className: 'localizationIcon',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.localization-marker {
  font-size: 12px;
  padding: 1px 2px;
  border-radius: 4px;
  opacity: 1 !important;
}
</style>
