<template>
  <div>
    <template v-if="Object.values(brands).length === 1">
      <span v-if="actualBrand">{{ actualBrand }}</span>
    </template>
    <template v-else>
      <b-dropdown variant="primary" :size="size" class="brand-dropdown">
        <template #button-content>
          <i class="fas fa-store" />
          <span>{{ actualBrand }}</span>
        </template>
        <b-dropdown-item-button
          v-for="(brand, id) in filteredBrands"
          :key="brand.name"
          :active="brand.name === actualBrand"
          @click="onItemClick(id, brand.name)"
        >
          {{ brand.name }} <small v-if="brand.only_online">(online)</small>
        </b-dropdown-item-button>
      </b-dropdown>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'BrandsDropdown',
  props: {
    value: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    filters: {
      default: null,
    },
  },
  data() {
    return {
      actualBrand: '',
    }
  },
  computed: {
    ...mapGetters({
      brands: 'auth/brands',
    }),
    filteredBrands() {
      if (this.filters) {
        let _brands = { ...this.brands }
        this.filters.forEach((filter) => {
          _brands = _.filter(_brands, (brand) => brand[filter.name])
        })
        return _brands
      } else return this.brands
    },
  },
  watch: {
    value() {
      this.updateActualBrand(this.value)
    },
  },
  methods: {
    chooseBrand(id, name) {
      this.updateActualBrand(name)
      this.$emit('choose-brand', { name, brandId: id })
    },
    updateActualBrand(name) {
      this.actualBrand = name
    },
    onItemClick(id, name) {
      this.chooseBrand(id, name)
    },
  },
}
</script>
<style lang="scss">
.brand-dropdown {
  @include media-breakpoint-down(sm) {
    max-width: 120px;
    > button {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
