import Decimal from 'decimal.js'
import { API } from '@/services/api/api'

async function getOrder({ commit, state }) {
  const { error, data } = await API.getOrder(state.orderId)
  if (!error && data) {
    commit('setCart', data)
    commit('setOrder', data)
  }
}
const placeOrder = async ({ dispatch, commit }, payload) => {
  const { order, onError, onSuccess, orderOnSite } = payload
  commit('setEditOrderRequestStatus', 'loading')
  const { error, data } = await API.addOrder(orderOnSite, order)
  if (!error && data) {
    commit('setEditOrderRequestStatus', 'success')
    if (onSuccess) onSuccess(data)
    commit('setEmptyCart')
    dispatch('goToDefaultOrdersList', {}, { root: true })
  } else {
    commit('setEditOrderRequestStatus', 'error')
    if (onError) onError(error)
  }
}
const updateOrder = async ({ dispatch, commit, state }, { order, onError, onSuccess }) => {
  const { error, data } = await API.updateOrder(state.cart.id, order)
  if (!error) {
    if (onSuccess) onSuccess(data)
    commit('setEmptyCart')
    dispatch('goToDefaultOrdersList', {}, { root: true })
  } else {
    if (onError) onError(error)
  }
}

const addMealToCart = ({ dispatch, commit, state }, meal) => {
  commit('addMeal', meal)
}

const updateMealInCart = ({ dispatch, commit, state }, { meal, cartPosition }) => {
  commit('updateMeal', { meal: meal, cartPosition: cartPosition })
}

const updateServiceChargeValue = ({ commit, state, getters }) => {
  let { totalPrice } = state.cart
  let serviceChargePercent = getters.getServiceChargePercent
  let scPercent = new Decimal(serviceChargePercent)
  const scValue = scPercent.times(totalPrice).dividedBy(100).toFixed(2)
  commit('setServiceChargeValue', scValue)
}

const getPredictions = ({ commit, state, getters, dispatch }) => {
  const { lat, lon } = state.cart
  const { address, deliveryType, pickupType } = state.cart.customer
  let price = getters.orderTotalPrice
  const predictionData = {
    lat,
    lon,
    address,
    deliveryType,
    pickupType,
    price,
    onSuccess: (data) => {
      commit('setEstimatedDeliveryTime', data)
    },
  }
  dispatch('getPredictions', predictionData, { root: true })
}

const applyDiscountCode = async ({ commit, state, rootGetters }, payload) => {
  const { onSuccess, onError } = payload
  const brandUUID = rootGetters['currentBrand'].uuid
  const { error, data } = await API.checkDiscountCode(state.cart.discountCode, brandUUID)
  if (!error && data) {
    commit('setDiscount', data)
    if (onSuccess) onSuccess(data)
  } else {
    if (onError && error.response) {
      onError(error.response.data)
    }
  }
}

const setDeliveryArea = ({ commit, state }, area) => {
  commit('setDeliveryAreaData', area)
}

function customerAddressCoords(customerPhone, foundCustomers, addressString) {
  const customerAddresses = foundCustomers.find(customer => customer.phone === customerPhone)?.addresses

  if (customerAddresses) {
    const customerAddress = customerAddresses.find(address => address.full_address === addressString)
    if (customerAddress) {
      return { lat: customerAddress.lat, lon: customerAddress.lon }
    }
  }

  return null
}

const checkArea = async ({ commit, dispatch, state, getters, rootGetters, rootState }, payload = {}) => {
  let { lat, lon } = payload

  let addressString = getters.externalOrder
    ? state.cart.customer.address
    : `${state.cart.customer.address}, ${state.cart.customer.city || rootGetters['auth/getLocalizationCity']}`

  if (!lat || !lon) {
    const coords = customerAddressCoords(state.cart.customer.phone, rootState.order.foundCustomers, addressString)
    if (coords) {
      lat = coords.lat
      lon = coords.lon
    }
  }

  // if old and new geocode address' are the same or address is not filled - don't send request
  if ((state.oldGeocodeAddress && addressString === state.oldGeocodeAddress) || !state.cart.customer.address) return
  else if (!state.oldGeocodeAddress) state.oldGeocodeAddress = addressString
  else state.oldGeocodeAddress = addressString

  commit('setDeliveryAreaLoading', true)

  const { error, data } =
    lat && lon ? await API.getAreaForCoords({ lat, lon }) : await API.checkAreaDelivery(addressString)

  if (!error && data) {
    // set location coordinates
    commit('savePositionInOrder', data)

    let oldDeliveryPrice = state.cart.deliveryPrice
    let oldDeliveryArea = state.deliveryArea
    let newDeliveryArea = data.area
    dispatch('getPredictions')

    // jest wysyłane przy 0 cenie a nie powinno być
    // jest wysyłane po wejściu z menu kidey ju wcześniej zosał wprowadzony, a potem usuninęty adress [cordy się nie usuwają]

    // przewiduje po zmianie na asap - OK. Zeruje adres przy zmianie delivery_type - OK

    // nie ma predykcjię w zaleności od delivery_type i pickup_type, po prostu w zaleności od tego ustawia się przed wysłaniem do orderu

    // set new deliveryArea only if old deliveryArea is different and still in orderEdit view
    if ((oldDeliveryArea && oldDeliveryArea.id !== newDeliveryArea.id) || !oldDeliveryArea) {
      // state.cart.deliveryPrice = null
      state.deliveryArea = 'processing'
      setTimeout(() => {
        commit('setDeliveryAreaData', newDeliveryArea)
      })
      setTimeout(() => {
        // execute this in next tick
        state.cart.deliveryPrice = oldDeliveryPrice
        // modal asking if deliveryPrice should be overwritten by price from area
        // only if actual delivery price is different than new area deliveryPrice
        let deliveryPriceChanged = oldDeliveryPrice !== null && oldDeliveryPrice !== getters.getDeliveryPriceFromArea
        if (deliveryPriceChanged) state.deliveryAreaAndPriceChangedPopup = true
      })
    }
  } else {
    if (error?.response?.data) {
      // check if areaWarning alert is still in view
      if (error.response.data.area.area === 'none') {
        state.deliveryArea = 'none'
        state.cart.deliveryPrice = null
      } else if (error.response.data.area.area === 'outside') {
        state.deliveryArea = 'outside'
        state.cart.deliveryPrice = null
      }
      commit('savePositionInOrder', error.response.data)
      dispatch('getPredictions')
    }
    state.deliveryAreaAndPriceChangedPopup = false
  }
  commit('setDeliveryAreaLoading', false)
}

const clearDeliveryPrice = ({ commit, state }) => {
  commit('clearDeliveryPrice')
}

const getBills = async ({ commit }, orderId) => {
  const { error, data } = await API.getBills({
    order: orderId,
  })
  if (!error) {
    commit('setBills', data)
  } else {
    throw error
  }
}

export default {
  getOrder,
  placeOrder,
  updateOrder,
  addMealToCart,
  updateMealInCart,
  updateServiceChargeValue,
  getPredictions,
  applyDiscountCode,
  setDeliveryArea,
  checkArea,
  clearDeliveryPrice,
  getBills,
}
