<template>
  <l-marker :lat-lng="coords" :icon="getOrderIcon()">
    <l-tooltip
      :options="{
        permanent: true,
        interactive: true,
        direction: 'top',
        offset: [0, -8],
        maxWidth: 200,
        className: 'geolocalization-map-marker-wrapper',
      }"
    >
      <div
        :class="`geolocalization-map-marker ${variant ? 'geolocalization-map-marker--' + variant : ''} ${
          isAnimating ? 'animating' : ''
        }`"
      >
        <div class="geolocalization-map-marker__content">
          <div v-if="label" class="geolocalization-map-marker__label">{{ label }}</div>
          <div class="geolocalization-map-marker__coords">
            <div>{{ coords.lat }}</div>
            <div>{{ coords.lon }}</div>
          </div>
        </div>
      </div>
    </l-tooltip>
  </l-marker>
</template>

<script>
import { LMarker, LTooltip } from 'vue2-leaflet'
import Leaflet from 'leaflet'
import pointImage from '@/../public/static/images/order-map-marker-point.svg'

export default {
  name: 'GeolocalizationMapMarker',
  components: { LMarker, LTooltip },
  props: {
    id: { type: [Number, String], required: true },
    coords: { type: Object, required: true },
    label: { type: String, required: true },
    variant: { type: String, default: '' },
  },
  data() {
    return {
      isAnimating: false,
    }
  },
  watch: {
    coords: {
      handler() {
        this.triggerAnimation()
      },
      deep: true,
    },
  },
  methods: {
    getOrderIcon() {
      return Leaflet.icon({
        iconUrl: pointImage,
        iconSize: [18, 18],
      })
    },
    triggerAnimation() {
      setTimeout(() => {
        this.isAnimating = true
        setTimeout(() => {
          this.isAnimating = false
        }, 300)
      }, 10)
    },
  },
}
</script>

<style lang="scss">
div.leaflet-tooltip.geolocalization-map-marker-wrapper {
  padding: 0;
  border: none;
  opacity: 1 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: transparent;

  &:before {
    display: none;
  }
}

.geolocalization-map-marker {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 12px;

  &.animating {
    animation: jump 0.25s ease-in-out;
  }

  &--primary {
    background-color: $blue-500 !important;
    color: #fff !important;

    &:before {
      border-top-color: $blue-500 !important;
    }
  }

  &__label {
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 4px;
  }

  &__coords {
    font-weight: 500;
    padding: 6px;
  }
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
</style>
