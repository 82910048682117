import { isPointInsidePolygon } from '@/utils/common'
import { RegionsState } from './index'
import _ from 'lodash'
import { Region } from '@/types/interface'

const regions = (state: RegionsState) => _.sortBy(state.regions, 'id')
const activeRegions = (state: RegionsState, getters) => getters.regions.filter((region: Region) => region.active)
const hasAnyRegion = (state: RegionsState) => state.regions.length > 0
const hasAnyActiveRegion = (state: RegionsState, getters) => getters.activeRegions.length > 0
const regionsRequest = (state: RegionsState) => state.regionsRequest
const isAddingNewRegion = (state: RegionsState) => state.addingNewRegion
const isEditing = (state: RegionsState) => state.isEditing
const editedRegionPoints = (state: RegionsState) => state.editedRegionPoints
const validEditedRegionPoints = (state: RegionsState) => {
  let valid = true
  const pointsCount = state.editedRegionPoints.length
  if (pointsCount >= 3) {
    state.editedRegionPoints.forEach((point) => {
      if (!_.isNumber(point.lat) || !_.isNumber(point.lon)) {
        valid = false
        return false
      }
    })
  } else valid = false
  return {
    pointsCount,
    valid,
  }
}
const editedRegionId = (state: RegionsState) => state.editedRegionId

const getAreasForPoint = (state: RegionsState) => ({lat, lon}: {lat: number, lon: number}) => {
  if (!lat || !lon) return []
  return state.regions.filter((region) => {
    return isPointInsidePolygon({ lat, lon }, region.points)
  })
}

const getLowestCostArea = () => (areas: Region[] = []) =>{
  return _.minBy(areas, 'delivery_price')
}

export default {
  regions,
  activeRegions,
  regionsRequest,
  isAddingNewRegion,
  isEditing,
  editedRegionPoints,
  validEditedRegionPoints,
  editedRegionId,
  getAreasForPoint,
  getLowestCostArea,
  hasAnyRegion,
  hasAnyActiveRegion
}
