<template>
  <l-marker :lat-lng="coords" :icon="getIcon" @click="onClick">
    <l-tooltip
      :options="{
        permanent: true,
        interactive: true,
        direction: 'top',
        offset: [0, -14],
        className: 'courier-marker',
      }"
    >
      <div @click="onClick">
        {{ label }} (<span class="font-weight-bold">{{ ordersCount }}</span
        >)
      </div>
    </l-tooltip>
  </l-marker>
</template>

<script>
import Leaflet from 'leaflet'
import { LMarker, LTooltip } from 'vue2-leaflet'
import courierMapMarkerReady from '@/../public/static/images/courier-map-marker-ready.svg'
import courierMapMarkerBusy from '@/../public/static/images/courier-map-marker-busy.svg'

export default {
  name: 'CourierMapMarker',
  components: { LMarker, LTooltip },
  props: {
    coords: { type: Object, required: true },
    ordersCount: { type: Number, default: 0 },
    onClick: { type: Function, required: true },
    label: { type: String, required: true },
  },
  computed: {
    getIcon() {
      return Leaflet.icon({
        iconUrl: this.ordersCount === 0 ? courierMapMarkerReady : courierMapMarkerBusy,
        iconSize: [28, 28],
      })
    },
  },
}
</script>

<style lang="scss">
.courier-marker {
  font-size: 12px;
  padding: 1px 2px;
  border: none;
  border-radius: 4px;
}
</style>
