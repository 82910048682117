<template>
  <div class="view-container" style="z-index: 10">
    <OrdersListFilters
      v-if="!geoEditOrder"
      is-map
      :orders="orders"
      :orders-list-filters="ordersListFilters"
      :primary-filters="primaryFilters"
      @setFilter="setFilter($event.filter, $event.value)"
      @setPrimaryFilter="setPrimaryFilter($event)"
      @clearFilters="clearFilters()"
      @searchKeyword="searchResults = $event"
    />
    <div class="layout">
      <GeolocalizationEditMap v-if="geoEditOrder" :order="geoEditOrder" @close="geoEditOrder = null" />
      <div v-else class="card map-container">
        <div class="map-wrapper">
          <l-map
            ref="map"
            :zoom="zoom"
            :center="center"
            :options="mapOptions"
            style="z-index: 1"
            @update:center="centerUpdate"
            @update:zoom="zoomUpdate"
          >
            <l-tile-layer :url="url" />
            <l-control position="topleft">
              <div
                v-b-tooltip.hover.right
                :title="$t('enlargeMap')"
                :class="[
                  'leaflet-control-zoom leaflet-bar leaflet-control m-0 enlarge-button',
                  { 'leaflet-control--active': mapIsEnlarged },
                ]"
              >
                <a class="" @click="setMapIsEnlarged(!mapIsEnlarged)">
                  <i v-if="mapIsEnlarged" class="fas fa-compress-alt" />
                  <i v-else class="fas fa-expand-alt" />
                </a>
              </div>

              <div
                v-b-tooltip.hover.right
                :title="$t('fitMapToOrders')"
                class="leaflet-control-zoom leaflet-bar leaflet-control m-0 mt-2 enlarge-button"
              >
                <a class="" @click="fitMapBoundsToAllMarkers()">
                  <i class="fas fa-expand" />
                </a>
              </div>

              <!-- <div
                v-b-tooltip.hover.right
                :title="$t('showDeliveryAreas')"
                :class="[
                  'leaflet-control-zoom leaflet-bar leaflet-control m-0 mt-2 enlarge-button',
                  { 'leaflet-control--active': deliveryAreasVisible },
                ]"
              >
                <a class="" @click="deliveryAreasVisible = !deliveryAreasVisible">
                  <i class="fas fa-draw-polygon" />
                </a>
              </div> -->
              <DeliveryAreasToggleButton
                :initial-visible="false"
                :use-local-storage="true"
                @change="(value) => (deliveryAreasVisible = value)"
              />
            </l-control>
            <CourierMapMarker
              v-for="courierMarker in courierMarkers"
              :key="'courier_marker_' + courierMarker.id"
              :coords="courierMarker.coords"
              :orders-count="courierMarker.countCurrentOrders"
              :label="courierMarker.label"
              :on-click="
                () => {
                  setFilter('courier', courierMarker.user_id)
                }
              "
            />

            <LocalizationMapMarker :orders-count="getLocalizationOrdersCount" />

            <OrderMapMarker
              v-for="orderMarker in orderMarkers"
              :id="orderMarker.id"
              :key="'order_marker_' + orderMarker.id"
              :coords="orderMarker.coords"
              :label="orderMarker.label"
              :promised-time="orderMarker.promised_time"
              :courier="orderMarker.courier"
              :number="orderMarker.number || orderMarker.order_number"
              :select-order="(orderId) => selectOrder(orderId, true)"
              :is-selected="ordersListFilters.orders.includes(orderMarker.id)"
              :is-hover-triggered="hoveredOrder === orderMarker.id"
              @onMapMarkerHover="setHoveredOrderFromMarker"
            />

            <DeliveryAreasPolygons v-if="deliveryAreasVisible" />
          </l-map>
          <div class="map-buttons-container">
            <b-button
              id="clear-selected-orders-btn"
              variant="primary"
              class="map-button"
              @click="clearOrSelectAllOrders()"
            >
              <template v-if="ordersListFilters.orders.length === 0">
                <i class="far fa-square mr-2" /><small>{{ $t('message.ordersMap.selectOrders') }}</small>
              </template>
              <template v-else>
                <i class="fas fa-check-square mr-2" /><small>{{ $t('message.ordersMap.clearSelectedOrders') }}</small>
              </template>
            </b-button>
            <template v-if="ordersListFilters.orders.length > 0">
              <b-button
                id="assign-courier-from-map-btn"
                variant="warning"
                class="map-button"
                @click="assignCourierToChosenOrders()"
              >
                <i class="fas fa-user-plus mr-2" /><small>{{ $t('message.ordersMap.assignCourierToOrders') }}</small>
              </b-button>
              <!-- <b-button id="regeocode-orders-btn" variant="warning" class="map-button" @click="_regeocodeOrders()">
                <i class="fas fa-search-location mr-2" /><small>{{ $t('message.ordersMap.regeocodeOrders') }}</small>
              </b-button> -->
            </template>
          </div>
        </div>
      </div>

      <div v-if="!mapIsEnlarged" ref="ordersTable" class="card orders-list">
        <div v-if="ordersListFilters.courier" class="orders-list-courier-filter">
          <span class="orders-list-courier-filter__name">{{ getCourierNameByID(ordersListFilters.courier) }}</span>

          <button
            class="btn btn-primary"
            @click="
              ordersListFilters.status = false
              ordersListFilters.courier = false
            "
          >
            {{ $t('message.orderDetails.close') }}
          </button>
        </div>

        <template v-if="ordersRequest === 'loading'">
          <OrderListItemPlaceholder v-for="n in 9" :key="n" />
        </template>

        <div
          v-else-if="allOrders.length <= 0"
          class="orders-list__empty d-flex justify-content-center align-items-center"
        >
          <span>{{ $t('message.ordersList.noOrders') }}</span>
        </div>

        <div ref="ordersListRef" class="orders-list-items">
          <OrderListItem
            v-for="order in allOrders"
            :key="'order_list_' + order.id"
            :ref="`order_${order.id}`"
            :order="order"
            :chosen="checkOrderChosen(order.id)"
            :action-col-visible="actionColVisible"
            :has-recommendation="hasRecommendationForOrder(order.id)"
            :set-order-hovered="showFullMarker"
            :hovered-order-from-map-marker="hoveredOrderFromMapMarker === order.id"
            @onOpenAssignCourier="openAssignCourier"
            @onAddOrderToChosenOrders="selectOrder"
            @geoEditClick="geoEditOrder = order"
          />
        </div>
      </div>
    </div>

    <AssignCourier
      :order="assignCourierData"
      :orders="assignCourierDataToOrders"
      @onAssignCourierModalHidden="assignCourierModalHidden()"
      @couriersAssigned="
        getOrders({ filters: getOrderFilters, loading: true, papu3: $hasModuleAccess(MODULES.papu_3) })
      "
    />

    <RecommendationMessages v-if="$hasModuleAccess(MODULES.recommendations)" />
  </div>
</template>

<script>
import { ORDER_STATUSES, PICKUP_TYPE_DELIVERY } from '@/common/constants'
import { mapActions, mapGetters, mapState } from 'vuex'
import Leaflet from 'leaflet'
import AssignCourier from '@/components/orders/AssignCourier'
import { LControl, LMap, LTileLayer } from 'vue2-leaflet'
import OrderListItem from '@/components/orders/OrderListItem'
import OrderListItemPlaceholder from '@/components/orders/OrderListItemPlaceholder'
import { orderMixins, ordersListMixins } from '@/mixins'
import OrdersListFilters from '@/components/orders/OrdersListFilters'
import restaurantIcon from '@/../public/static/images/restaurant.svg'
import orderIcon from '@/../public/static/images/order.svg'
import courierIcon from '@/../public/static/images/courier.svg'
import GeolocalizationEditMap from '@/components/geolocalization-edit/GeolocalizationEditMap'
import DeliveryAreasPolygons from '@/components/delivery-areas/DeliveryAreasPolygons'
import OrderMapMarker from './OrderMapMarker'
import LocalizationMapMarker from './LocalizationMapMarker'
import CourierMapMarker from './CourierMapMarker'
import RecommendationMessages from '@/components/scheduler/RecommendationMessages.vue'
import DeliveryAreasToggleButton from '@/components/delivery-areas/DeliveryAreasToggleButton'
import { set } from 'lodash'

const STORE_NAME = '_orders'

export default {
  name: 'CouriersMapComponent',
  components: {
    OrderListItemPlaceholder,
    AssignCourier,
    LMap,
    LTileLayer,
    OrderListItem,
    OrdersListFilters,
    OrderMapMarker,
    LocalizationMapMarker,
    CourierMapMarker,
    LControl,
    GeolocalizationEditMap,
    DeliveryAreasPolygons,
    RecommendationMessages,
    DeliveryAreasToggleButton,
  },
  mixins: [ordersListMixins, orderMixins],
  data: function () {
    return {
      assignCourierDataToOrders: [],
      chosenOrders: [],
      hoveredOrder: null,
      hoveredOrderFromMapMarker: null,
      url: 'https://tiles.papu.io/{z}/{x}/{y}.png',
      center: '',
      zoom: 14,
      mapOptions: {
        zoomSnap: 0.5,
      },
      restaurantIcon: restaurantIcon,
      orderIcon: orderIcon,
      courierIcon: courierIcon,
      mapIsEnlarged: false,
      geoEditOrder: null,
      deliveryAreasVisible: false,
    }
  },
  computed: {
    ...mapState({
      couriers: (state) => state._orders.couriers,
      employees: (state) => state._orders.employees,
    }),
    getOrderFilters() {
      return {
        archive: false,
      }
    },
    orderMarkers() {
      let markers = []

      this.allOrders.forEach((order) => {
        if (order.lat && order.lon && order.pickup_type === PICKUP_TYPE_DELIVERY) {
          let marker = {
            id: order.id || order.uuid,
            coords: Leaflet.latLng(order.lat, order.lon),
            label: this.getOrderFullAddress(order),
            promised_time: order.promised_time,
            courier: order.courier,
            number: order.number,
            order_number: order.order_number, // Papu3
          }
          markers.push(marker)
        }
      })
      return markers
    },
    courierMarkers() {
      let markers = []

      this.couriers.forEach((courier) => {
        if (courier.coords && courier.coords.lat && courier.coords.lon && courier.coords.timestamp) {
          let marker = {
            id: courier.id,
            coords: Leaflet.latLng(courier.coords.lat, courier.coords.lon),
            label: `${courier.name} (${courier.coords.timestamp})`,
            countCurrentOrders: courier.count_current_orders,
            user_id: courier.user_id,
          }
          markers.push(marker)
        }
      })
      return markers
    },
    ...mapGetters(STORE_NAME, {
      orders: 'orders',
      ordersRequest: 'ordersRequest',
      couriers: 'couriers',
      ordersInRegeocode: 'ordersInRegeocode',
    }),
    ...mapGetters({
      recommendation: 'recommendations/recommendation',
    }),
    ...mapGetters(['delayOrdersListRefresh']),
    getLocalizationOrdersCount() {
      let onSiteAndTakeAwayOrders = this.allOrders.filter((order) => {
        return order.pickup_type !== PICKUP_TYPE_DELIVERY
      })
      return onSiteAndTakeAwayOrders.length
    },
    actionColVisible() {
      return this.allOrders.some((order) => order.pickup_type === PICKUP_TYPE_DELIVERY)
    },
  },
  watch: {
    geoEditOrder(value) {
      this.mapIsEnlarged = value
    },
  },
  beforeMount() {
    this.center = Leaflet.latLng(
      this.$store.getters['auth/getLocalizationLat'],
      this.$store.getters['auth/getLocalizationLon']
    )
  },
  mounted() {
    if (this.delayOrdersListRefresh) {
      setTimeout(() => {
        this._getOrders({ loading: true })
      }, this.delayOrdersListRefresh * 1000)
    } else this._getOrders({ loading: true })
    // Setting bounds for map to view all orders and localization markers
    this.firstGetMapCouriers()
    // Play notification every 10 seconds when new order is available
    this.refreshInterval = setInterval(() => {
      this._getOrders()
      this.getMapCouriesList()
    }, 30000)

    this.$eventBus.$on('onCloseDetailsModal', (id) => {
      let orderItem = this.$refs[`order_${id}`]
      if (orderItem !== undefined && orderItem[0] && orderItem[0].$el) {
        this.$refs['ordersTable'].scrollTo(0, orderItem[0].$el.offsetTop)
      }
    })

    this.$eventBus.$on('triggerOrdersRefresh', () => {
      this._getOrders({ loading: false })
    })
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
    this.$eventBus.$off('onCloseDetailsModal')
    this.$eventBus.$off('triggerOrdersRefresh')
  },
  methods: {
    ...mapActions({
      getOrders: '_orders/getOrders',
      getMapCouriesList: '_orders/getMapCouriesList',
      getEmployees: '_orders/getEmployees',
      regeocodeOrders: '_orders/regeocodeOrders',
      getRecommendation: 'recommendations/getRecommendation',
    }),
    async _getOrders({ loading } = { loading: false }) {
      await this.getOrders({
        filters: this.getOrderFilters,
        loading,
        onSuccess: ({ future_orders_count }) => {
          // Play notification immediately
          this.playNotification()
        },
      })

      if (this.$hasModuleAccess(this.MODULES.recommendations)) await this.getRecommendation()
    },
    async firstGetMapCouriers() {
      const { error } = await this.getMapCouriesList()
      if (!error) {
        this.fitMapBoundsToAllMarkers()
      }
    },
    fitMapBoundsToAllMarkers() {
      let coords = this.orderMarkers.map((order) => {
        return order.coords
      })
      coords = coords.concat(
        this.courierMarkers.map((courier) => {
          return courier.coords
        })
      )
      coords.push(this.center)
      const latLngBounds = new Leaflet.latLngBounds(coords)
      if (this.$refs.map && latLngBounds.isValid()) this.$refs.map.fitBounds(latLngBounds)
    },
    openAssignCourier(order) {
      this.assignCourierData = order
      this.assignCourierIsOpen = true
    },
    zoomUpdate(zoom) {
      this.zoom = zoom
    },
    centerUpdate(center) {
      this.center = center
    },
    getCourierNameByID(courierID) {
      let foundCourierName = null
      this.couriers.forEach((courier) => {
        if (courier.user_id === courierID) {
          foundCourierName = courier.name
        }
      })
      return foundCourierName
    },
    selectOrder(orderId, scrollTo = false) {
      let orderAlreadyChosen = this.ordersListFilters.orders.find((id) => {
        return id === orderId
      })
      if (orderAlreadyChosen) {
        this.ordersListFilters.orders = this.ordersListFilters.orders.filter((id) => {
          return id !== orderId
        })
      } else {
        let order = this.orders.find((order) => {
          return order.id === orderId
        })
        // Should not allow selecting NEW orders
        if (order && order.status !== ORDER_STATUSES.ORDER_STATUS_NEW) {
          this.ordersListFilters.orders.push(orderId)
        }

        if (scrollTo) {
          let orderItem = this.$refs[`order_${orderId}`]
          if (orderItem !== undefined && orderItem[0] && orderItem[0].$el) {
            const element = orderItem[0].$el
            const scrollContainer = this.$refs['ordersListRef']

            const elementTop = element.getBoundingClientRect().top
            const containerTop = scrollContainer.getBoundingClientRect().top

            const relativeElementTop = elementTop - containerTop + scrollContainer.scrollTop

            const elementBottom = relativeElementTop + element.offsetHeight

            const isElementTopVisible = relativeElementTop >= scrollContainer.scrollTop
            const isElementBottomVisible = elementBottom <= scrollContainer.scrollTop + scrollContainer.clientHeight

            if (!isElementTopVisible || !isElementBottomVisible) {
              if (!isElementTopVisible) {
                scrollContainer.scrollTo(0, relativeElementTop)
              } else if (!isElementBottomVisible) {
                scrollContainer.scrollTo(0, elementBottom - scrollContainer.clientHeight)
              }
            }
          }
        }
      }
    },
    checkOrderChosen(orderId) {
      return !!this.ordersListFilters.orders.find((id) => id === orderId)
    },
    showFullMarker(orderId) {
      this.hoveredOrder = orderId
    },
    setHoveredOrderFromMarker(orderId) {
      this.hoveredOrderFromMapMarker = orderId
    },
    clearOrSelectAllOrders() {
      if (this.ordersListFilters.orders.length === 0) {
        this.allOrders.filter((order) => {
          if (
            order.pickup_type === PICKUP_TYPE_DELIVERY &&
            [ORDER_STATUSES.ORDER_STATUS_ACCEPTED, ORDER_STATUSES.ORDER_STATUS_DELIVERING_IN].includes(order.status)
          ) {
            this.ordersListFilters.orders.push(order.id)
          }
        })
      } else {
        this.ordersListFilters.orders = []
      }
    },
    assignCourierToChosenOrders() {
      // Open AssignCourier modal
      this.assignCourierDataToOrders = this.ordersListFilters.orders
    },
    assignCourierModalHidden() {
      this.assignCourierData = null
      this.assignCourierDataToOrders = []
    },
    hasRecommendationForOrder(orderId) {
      return this.recommendation?.order_id === orderId
    },
    setMapIsEnlarged(value) {
      this.mapIsEnlarged = value
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize()
      }, 50)
    },
    _regeocodeOrders() {
      const ordersToRegeocode = []
      const ordersAlreadyInRegeocodeNumbers = []
      // const ordersBlockedNames = []
      const ordersToRegeocodeNumbers = []
      this.ordersListFilters.orders.forEach((id) => {
        const order = this.orders.find((o) => o.id === id)
        if (order) {
          // if (order.relocated_twice) {
          //   ordersBlockedNames.push(order.number)
          // } else
          if (this.ordersInRegeocode.includes(id)) {
            ordersAlreadyInRegeocodeNumbers.push(order.number)
          } else {
            ordersToRegeocode.push(id)
            ordersToRegeocodeNumbers.push(order.number)
          }
        }
      })
      // Show error toast for selected orders that are already in regeocode
      if (ordersAlreadyInRegeocodeNumbers.length > 0) {
        this.$toasted.show(
          `${this.$t('message.regeocodeOrders.orderIsAlreadyInRegeocode')}: ${ordersAlreadyInRegeocodeNumbers.join(
            ', '
          )}`,
          {
            type: 'warning',
            icon: {
              name: 'exclamation-circle',
            },
            duration: 5000,
          }
        )
      }
      if (ordersToRegeocode.length > 0) {
        this.regeocodeOrders({
          ordersIds: ordersToRegeocode,
          onSuccess: () => {
            this.$toasted.show(
              `${this.$t('message.regeocodeOrders.regeocodeOrdersSuccess')}: ${ordersToRegeocodeNumbers.join(', ')}`,
              {
                type: 'success',
                icon: {
                  name: 'check',
                },
                duration: 3000,
              }
            )
          },
          onError: () => {
            this.$toasted.show(
              `${this.$t('message.regeocodeOrders.regeocodeOrdersError')}: ${ordersToRegeocodeNumbers.join(', ')}`,
              {
                type: 'error',
                icon: {
                  name: 'exclamation-circle',
                },
                duration: 5000,
              }
            )
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 100%;
  height: 100%;
  overflow: auto;
}

.map {
  &-container {
    border: 1px solid #00000026;
    display: flex;
    overflow-y: auto;
    height: 100%;
    border-radius: 0px;
  }

  &-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &-buttons-container {
    position: absolute;
    z-index: 10;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &-button {
    padding: 5px 10px;
    margin-bottom: 15px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  }
}

.orders-list {
  position: relative;
  height: 100%;
  min-width: 400px;
  max-width: 400px;
  width: 400px;
  overflow-x: hidden;
  overflow-y: hidden;
  border-radius: 0px;
  &-items {
    overflow-y: auto;
    overflow-x: hidden;
  }
  &__empty {
    height: 100%;
    width: 100%;
    font-size: 16px;
    opacity: 0.5;
  }

  &-courier-filter {
    border-bottom: 1px solid $gray-400;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 10px;
    align-items: center;

    &__name {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

@media (max-width: 576px) {
  .orders-list {
    display: none;
  }
  .layout {
    gap: 0 0;
  }
  .enlarge-button {
    display: none;
  }
  .map-buttons-container {
    position: absolute;
    z-index: 10;
    top: 5px;
    right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .map-button {
    margin-bottom: 10px;
  }
}
@media (min-width: 1280px) {
  .layout {
    gap: 0 10px;
  }

  .map-container {
    border-radius: 6px;
  }

  .orders-list {
    border-radius: 6px;
  }
}
</style>
