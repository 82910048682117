<template>
  <b-modal id="menuSourceModal" ref="menuSourceModal" :title="$t('selectMenu')" hide-footer>
    <b-button
      v-for="(source, index) in externalSources"
      :key="index"
      class="source-change-btn"
      block
      size="lg"
      :style="{
        'background-color': getSourceColor(source),
        color: getButtonTextColor(source),
      }"
      @click="changeExternalSource(source)"
    >
      <i v-if="selectedSource === source.source" class="fa fa-check mr-2"></i>
      {{ source.name === 'Default' ? $t('message.common.menuDefault') : source.name }}
    </b-button>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { MENU_SOURCE_CHOICES } from '@/common/constants'
import _ from 'lodash'
import { menuSourcesMixins } from '@/mixins/menuSourcesMixins'
const STORE = '_menuEdit'

export default {
  name: 'MenuSourceModal',
  mixins: [menuSourcesMixins],
  props: {
    showDefault: {
      type: Boolean,
      default: false,
    },
    selectedSource: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {}
  },
  computed: {
    ...mapGetters({
      menuSources: 'auth/getMenuSources',
      currentBrandId: 'currentBrandId',
    }),
    externalSources() {
      let sources = this.menuSources[this.currentBrandId]
      return _.omitBy(sources, (source) =>
        [this.showDefault ? '' : MENU_SOURCE_CHOICES.DEFAULT, MENU_SOURCE_CHOICES.NEW_ERESTAURANT].includes(
          source.source.toString()
        )
      )
    },
  },
  methods: {
    ...mapMutations(STORE, ['setActiveMenuId']),
    changeExternalSource(source) {
      this.$emit('changeExternalSource', source)
    },
  },
}
</script>
