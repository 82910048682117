<template>
  <b-modal id="instructionsModal" :title="$t('how_to_get_external_map_link')" size="lg" hide-footer>
    <template #default>
      <b-tabs>
        <b-tab :title="$t('google_maps')">
          <h3 class="my-4">{{ $t('to_get_coordinates_from_google_maps_link') }}:</h3>
          <ol class="mt-4 mb-4">
            <li>
              {{ $t('geo_edit_google_instruction_1') }}
              <a href="https://www.google.com/maps" target="_blank">Google Maps</a>.
            </li>
            <li>{{ $t('geo_edit_google_instruction_2') }}.</li>
            <li>{{ $t('geo_edit_google_instruction_3') }}.</li>
            <li>{{ $t('geo_edit_google_instruction_4') }}.</li>
            <li>{{ $t('geo_edit_google_instruction_5') }}.</li>
            <li>{{ $t('geo_edit_google_instruction_6') }}.</li>
            <li>{{ $t('geo_edit_google_instruction_7') }}.</li>
          </ol>
          <div class="video-container d-flex justify-content-center">
            <video width="448" height="480" controls>
              <source src="/static/videos/geo-edit-google-link-instructions.mp4" type="video/mp4" />
            </video>
          </div>
        </b-tab>
        <b-tab :title="$t('targeo_map')">
          <h3 class="my-4">{{ $t('to_get_coordinates_from_targeo_map_link') }}:</h3>
          <ol class="mt-4 mb-4">
            <li>
              {{ $t('geo_edit_targeo_instruction_1') }}
              <a href="https://mapa.targeo.pl/" target="_blank">Mapy Targeo</a>.
            </li>
            <li>{{ $t('geo_edit_targeo_instruction_2') }}.</li>
            <li>{{ $t('geo_edit_targeo_instruction_3') }}.</li>
            <li>{{ $t('geo_edit_targeo_instruction_4') }}.</li>
            <li>{{ $t('geo_edit_targeo_instruction_5') }}.</li>
            <li>{{ $t('geo_edit_targeo_instruction_6') }}.</li>
            <li>{{ $t('geo_edit_targeo_instruction_7') }}.</li>
          </ol>
          <div class="video-container d-flex justify-content-center">
            <video width="470" height="480" controls>
              <source src="/static/videos/geo-edit-targeo-link-instructions.mp4" type="video/mp4" />
            </video>
          </div>
        </b-tab>
      </b-tabs>
    </template>
    <template #modal-footer>
      <b-button variant="primary" @click="$bvModal.hide('instructionsModal')">
        {{ $t('ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'InstructionsModal',
}
</script>

<style scoped lang="scss">
.video-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  video {
    border: 2px solid #e8e8e8;
    border-radius: 12px;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  }
}
</style>