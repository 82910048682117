<template>
  <div key="map-geo-edit" class="card map-container map-container--geo">
    <div class="map-wrapper">
      <l-map
        ref="map"
        :zoom="zoom"
        :center="center"
        :options="mapOptions"
        style="z-index: 1"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
        @click="setNewCoordsFromMap($event)"
      >
        <l-tile-layer :url="url" />

        <l-control position="topleft">
          <DeliveryAreasToggleButton
            :initial-visible="true"
            :use-local-storage="false"
            @change="(value) => (deliveryAreasVisible = value)"
          />
        </l-control>

        <LocalizationMapMarker />

        <GeolocalizationMapMarker
          v-if="orderCoords"
          id="current-coords-marker"
          :coords="orderCoords"
          :label="$t('coordinates')"
        />

        <GeolocalizationMapMarker
          v-if="newCoords"
          id="new-coords-marker"
          :coords="newCoords"
          :label="$t('new_coordinates')"
          variant="primary"
        />

        <DeliveryAreasPolygons v-if="deliveryAreasVisible" :popup="false" />
      </l-map>
    </div>

    <div class="geo-edit-modal">
      <div class="geo-edit-modal-header">
        <div class="geo-edit-modal-header__title">{{ $t('edit_geolocalization') }}</div>
        <b-button
          v-b-tooltip.hover
          variant="link"
          size="sm"
          class="ml-auto"
          :title="$t('report_sugestion')"
          @click="reportBug()"
        >
          <i class="fas fa-bug mr-1" />
          {{ $t('report_sugestion') }}
        </b-button>
      </div>

      <div class="geo-edit-modal-body">
        <b-alert variant="info" show>
          <small>{{ $t('edit_geo_modal_tip') }}</small>
        </b-alert>

        <div class="row">
          <div class="col-md-6">
            <b-form-group>
              <label>{{ $t('order_number') }}</label>
              <div class="form-control-static">{{ order.number }}</div>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group>
              <label>{{ $t('order_price') }}</label>
              <div class="form-control-static">{{ order.price }} {{ currency }}</div>
            </b-form-group>
          </div>
        </div>

        <hr class="mt-2 mb-4" />

        <b-form>
          <b-form-group>
            <div class="d-flex align-items-center">
              <label for="lat"
                >{{ $t('address') }}

                <span v-if="form.address !== initialOrderData.address" class="text-primary ml-1"
                  >({{ $t('edited') }})</span
                >
              </label>

              <TooltipButton
                id="driver_addresstooltip_btn"
                placement="top"
                button-class="defaultTooltipButton mb-2"
                class="ml-auto"
              >
                ?
                <template #tooltipText>{{ $t('driver_will_get_address_not_coordinates') }}</template>
              </TooltipButton>
            </div>

            <b-form-input
              id="address"
              v-model="form.address"
              v-validate="'required'"
              type="text"
              class="form-control"
              name="address"
            />
          </b-form-group>

          <b-form-group v-if="showCityInput">
            <label for="lat"
              >{{ $t('city')
              }}<span v-if="form.city !== initialOrderData.city" class="text-primary ml-1"
                >({{ $t('edited') }})</span
              ></label
            >
            <b-form-input
              id="city"
              v-model="form.city"
              v-validate="'required'"
              type="text"
              class="form-control"
              name="city"
            />
          </b-form-group>

          <b-form-group>
            <label>{{ $t('open_in_external_map') }}</label>
            <div class="d-flex">
              <b-button variant="outline-primary" size="sm" class="mr-2" @click="openInGoogleMaps">
                Google Maps
                <i class="fas fa-external-link-alt ml-1" />
              </b-button>
              <b-button variant="outline-primary" size="sm" @click="openInMapTargeo">
                Targeo<i class="fas fa-external-link-alt ml-1" />
              </b-button>
            </div>
          </b-form-group>

          <b-form-group>
            <label for="lat"
              >{{ $t('message.orderFormFull.addressComment')
              }}<span v-if="form.address_comment !== initialOrderData.address_comment" class="text-primary ml-1"
                >({{ $t('edited') }})</span
              ></label
            >
            <b-form-textarea
              id="address_comment"
              v-model="form.address_comment"
              type="text"
              class="form-control"
              name="address_comment"
            />
          </b-form-group>

          <hr class="my-3" />
        </b-form>

        <ExternalMapInput @coords-extracted="handleExtractedCoords" />

        <b-form-group>
          <div class="d-flex align-items-center">
            <label>{{ $t('delivery_area') }}</label>
            <TooltipButton
              id="delivery_area_tooltip_btn"
              placement="top"
              button-class="defaultTooltipButton mb-2"
              class="ml-auto"
            >
              ?
              <template #tooltipText>{{ $t('update_delivery_price_info_by_hand') }}</template>
            </TooltipButton>
          </div>
          <div
            v-if="loadingDeliveryArea"
            class="justify-content-center text-center d-flex align-items-center"
            style="height: 74px"
          >
            <b-spinner small />
          </div>
          <div v-else-if="deliveryArea">
            <div>
              <div class="font-weight-bold mb-1">{{ deliveryArea.name }}</div>
              <DeliveryAreaPricelist :delivery-area="deliveryArea" />
            </div>
          </div>
          <div
            v-else
            style="height: 74px; background-color: #f4f4f6"
            class="justify-content-center d-flex align-items-center w-100"
          >
            <div>{{ $t('no_delivery_area') }}</div>
          </div>
        </b-form-group>
      </div>

      <div class="geo-edit-modal-footer">
        <b-button variant="outline-secondary" class="geo-edit-modal-footer__button w-100" @click="cancel()">{{
          $t('message.common.cancel')
        }}</b-button>
        <b-button
          variant="success"
          :disabled="!coordinatesValid || loading || !hasAnyChanges"
          class="geo-edit-modal-footer__button w-100 ml-2"
          @click="save()"
        >
          <i v-if="!loading" class="fas fa-save" />
          <i v-else class="fas fa-spinner fa-spin" />
          <span>{{ $t('message.common.save') }}</span></b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Leaflet from 'leaflet'
import { LControl, LMap, LTileLayer } from 'vue2-leaflet'
import { orderMixins, ordersListMixins } from '@/mixins'
import LocalizationMapMarker from '@/components/map-markers/LocalizationMapMarker'
import GeolocalizationMapMarker from './GeolocalizationMapMarker.vue'
import DeliveryAreasPolygons from '@/components/delivery-areas/DeliveryAreasPolygons'
import DeliveryAreaPricelist from '@/components/delivery-areas/DeliveryAreaPricelist'
import DeliveryAreasToggleButton from '@/components/delivery-areas/DeliveryAreasToggleButton'
import { API } from '@/services/api/api'
import { reportBug, flattenObject } from '@/utils/common'
import ExternalMapInput from './ExternalMapInput.vue'

export default {
  name: 'GeolocalizationEditMap',
  components: {
    LMap,
    LTileLayer,
    LocalizationMapMarker,
    LControl,
    GeolocalizationMapMarker,
    DeliveryAreasPolygons,
    DeliveryAreaPricelist,
    DeliveryAreasToggleButton,
    ExternalMapInput,
  },
  mixins: [ordersListMixins, orderMixins],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      url: 'https://tiles.papu.io/{z}/{x}/{y}.png',
      center: '',
      zoom: 14,
      mapOptions: {
        zoomSnap: 0.5,
      },
      newCoords: null,
      form: {
        lat: null,
        lon: null,
        address: '',
        city: '',
        address_comment: '',
        deliveryPrice: '',
      },
      initialOrderData: {},
      deliveryAreasVisible: true,
      deliveryArea: null,
      //allowChangeDeliveryPrice: false,
      inputMethodStats: {
        mapClicks: 0,
        latLonInputChanges: 0,
        openExternalMapGoogle: 0,
        openExternalMapTargeo: 0,
        getCoordsFromExternalMapLink: 0,
        addressChange: 0,
      },
      loadingDeliveryArea: false,
      loading: false,
      saved: false,
    }
  },
  computed: {
    ...mapGetters({
      currency: 'currency',
      hasAnyActiveRegion: 'deliveryAreas/hasAnyActiveRegion',
    }),
    orderCoords() {
      const { lat, lon } = this.order || {}
      if (!lat || !lon) return null

      return { lat, lon }
    },
    coordinatesValid() {
      return this.form.lat && this.form.lon
    },
    getChanges() {
      const changes = {}

      for (const key in this.initialOrderData) {
        if (this.form[key] !== this.initialOrderData[key]) {
          changes[key] = {
            from: this.initialOrderData[key],
            to: this.form[key],
          }
        }
      }

      return changes
    },
    hasAnyChanges() {
      return Object.keys(this.getChanges).length > 0
    },
    showCityInput() {
      return this.form.city
    },
  },
  watch: {
    newCoords() {
      this.checkDeliveryArea()
    },
    allowChangeDeliveryPrice() {
      if (!this.allowChangeDeliveryPrice) {
        this.form.deliveryPrice = this.order.delivery_price
      }
    },
  },
  beforeMount() {
    if (!this.hasAnyActiveRegion) this.getRegions({ loading: true })
    this.init()
  },
  mounted() {
    this.$posthog.startSessionRecording()
  },
  beforeDestroy() {
    if (!this.saved) this.sendPosthogEvent('canceled')
    this.$posthog.stopSessionRecording()
  },
  methods: {
    ...mapActions('deliveryAreas', {
      getRegions: 'getRegions',
    }),
    init() {
      const { lat, lon } = this.order || {}
      if (lat && lon) {
        this.center = Leaflet.latLng(lat, lon)
        this.form.lat = this.order.lat
        this.form.lon = this.order.lon
      } else {
        this.center = Leaflet.latLng(
          this.$store.getters['auth/getLocalizationLat'],
          this.$store.getters['auth/getLocalizationLon']
        )
      }
      this.form.address = this.order.order_customer?.address
      this.form.city = this.order.order_customer?.city
      this.form.address_comment = this.order.order_customer?.address_comment
      this.form.deliveryPrice = this.order.delivery_price

      // Store initial order data for comparison
      this.initialOrderData = {
        lat: this.order.lat,
        lon: this.order.lon,
        address: this.order.order_customer?.address,
        city: this.order.order_customer?.city,
        address_comment: this.order.order_customer?.address_comment,
      }

      this.checkDeliveryArea()
    },
    zoomUpdate(zoom) {
      this.zoom = zoom
    },
    centerUpdate(center) {
      this.center = center
    },
    setNewCoordsFromMap(event) {
      const { lat, lng } = event.latlng
      if (!lat || !lng) {
        this.newCoords = null
        return
      }
      this.setCoords(lat, lng)
      this.inputMethodStats.mapClicks++
    },
    setCoords(lat, lon) {
      this.newCoords = { lat, lon }
      this.form.lat = lat
      this.form.lon = lon
    },
    async checkDeliveryArea() {
      this.loadingDeliveryArea = true
      const { lat, lon } = this.form
      const { error, data } = await API.getAreaForCoords({ lat, lon })
      if (!error && data) {
        this.deliveryArea = data.area
      } else {
        this.deliveryArea = null
      }
      this.loadingDeliveryArea = false
    },
    updateCoords() {
      this.newCoords = { lat: this.form.lat, lon: this.form.lon }
      this.inputMethodStats.inputChanges++
    },
    cancel() {
      this.close()
    },
    async save() {
      this.loading = true

      const address = {
        phone: this.order.order_customer.phone,
        comment: this.form.address_comment,
        located_manually: true,
      }

      if (this.showCityInput) {
        address.address = this.form.address
        address.city = this.form.city
      } else {
        address.full_address = this.form.address
      }

      let formData = {
        lat: this.form.lat,
        lon: this.form.lon,
        customer: { address },
      }

      if (
        this.form.address !== this.order.order_customer.address ||
        this.form.city !== this.order.order_customer.city
      ) {
        this.inputMethodStats.addressChange = 1
      }

      const { error, data } = await API.updateOrder(this.order.id, formData)

      if (!error && data) {
        this.$toasted.show(this.$t('geolocation_for_order_saved', { order_number: this.order.number }), {
          type: 'success',
          icon: {
            name: 'check',
          },
          duration: 5000,
        })

        this.sendPosthogEvent('edited')
        this.saved = true

        this.$eventBus.$emit('triggerOrdersRefresh')
        this.close()
      } else {
        this.$toasted.show(error.message, {
          type: 'error',
          duration: 5000,
        })
      }
      this.loading = false
    },
    sendPosthogEvent(result = '') {
      if (!result) return

      const posthogData = {
        order_id: this.order.id,
        menu_translated: this.order.menu_translated,
        result,
      }
      const changes = this.getChanges
      if (changes) {
        const changesData = flattenObject(changes, 'changes')
        for (const key in changesData) {
          posthogData[key] = changesData[key]
        }
      }

      const initialOrderData = flattenObject(this.initialOrderData, 'initialOrderData')
      for (const key in initialOrderData) {
        posthogData[key] = initialOrderData[key]
      }

      const inputMethodStats = flattenObject(this.inputMethodStats, 'inputMethodStats')
      for (const key in inputMethodStats) {
        posthogData[key] = inputMethodStats[key]
      }

      this.$posthog.capture('geolocalization_edit', posthogData)
    },
    openInGoogleMaps() {
      const address = this.form.address
      const city = this.form.city
      const encodedAddress = this.encodeAddressForGoogleMaps(`${address}, ${city}`)
      const url = `https://www.google.com/maps/place/${encodedAddress}`
      window.open(url, '_blank')
      this.inputMethodStats.openExternalMapGoogle++
    },
    encodeAddressForGoogleMaps(address) {
      return address
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ł/g, 'l')
        .replace(/Ł/g, 'L')
        .replace(/\s+/g, '+')
        .replace(/,/g, '%2C')
    },
    openInMapTargeo() {
      const address = this.encodeAddressForMapTargeo(`${this.form.address}, ${this.form.city}`)
      const url = `https://mapa.targeo.pl/${address}`
      window.open(url, '_blank')
      this.inputMethodStats.openExternalMapTargeo++
    },
    encodeAddressForMapTargeo(address) {
      return address
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/ł/g, 'l')
        .replace(/Ł/g, 'L')
        .replace(/\s+/g, '%20')
        .replace(/,/g, '%2C')
    },
    handleExtractedCoords(coords) {
      this.setCoords(coords.lat, coords.lon)
      this.centerUpdate(Leaflet.latLng(coords.lat, coords.lon))
      this.inputMethodStats.getCoordsFromExternalMapLink++
    },
    close() {
      this.$emit('close')
    },
    reportBug,
  },
}
</script>
<style lang="scss">
.map {
  &-container {
    &--geo {
      border: 3px dashed $blue-500 !important;
      position: relative;
    }
  }

  &-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }

  &-button {
    padding: 5px 10px;
    margin-bottom: 15px;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
  }
}

.geo-edit-modal {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: #fff;
  border-radius: 8px;
  width: 350px;
  z-index: 100;
  border: 1px solid $gray-200;
  box-shadow: 0 5px 6px #00000029;
  max-height: calc(100% - 20px);
  display: flex;
  flex-direction: column;

  &-header {
    padding: 0.75rem 1.25rem;
    border-bottom: 1px solid $gray-200;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &-body {
    padding: 1.25rem;
    overflow-y: auto;
    height: max-content;
  }

  &-footer {
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $gray-200;
  }
}
</style>
