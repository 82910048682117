var render = function render(){var _vm=this,_c=_vm._self._c;return _c('l-marker',{attrs:{"lat-lng":_vm.coords,"icon":_vm.getOrderIcon()}},[_c('l-tooltip',{attrs:{"options":{
      permanent: true,
      interactive: true,
      direction: 'top',
      offset: [0, -8],
      maxWidth: 200,
      className: 'geolocalization-map-marker-wrapper',
    }}},[_c('div',{class:`geolocalization-map-marker ${_vm.variant ? 'geolocalization-map-marker--' + _vm.variant : ''} ${
        _vm.isAnimating ? 'animating' : ''
      }`},[_c('div',{staticClass:"geolocalization-map-marker__content"},[(_vm.label)?_c('div',{staticClass:"geolocalization-map-marker__label"},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"geolocalization-map-marker__coords"},[_c('div',[_vm._v(_vm._s(_vm.coords.lat))]),_c('div',[_vm._v(_vm._s(_vm.coords.lon))])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }