import Decimal from 'decimal.js'
import { PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT, DISCOUNT_CHOICES, MEALSET_TYPES } from '@/common/constants'
import { UtilsMixins } from '@/mixins'

const cart = (state) => state.cart
const cartNumber = (state) => state.cart?.number
const isEdit = (state) => state.isEdit
const showMealsMenu = (state) => state.show.mealsMenu
const showOrderForm = (state) => state.show.orderForm
const orderOnSite = (state) => state.orderOnSite
const externalOrder = (state) => state.externalOrder
const mealSaved = (state) => state.mealSaved
const courierDeliveryArea = (state) => state.order.courier_delivery_area
const estimatedDeliveryTime = (state) => state.estimatedDeliveryTime
const isAnyMealDiscountOrPromo = (state) => {
  const mealsDiscounts = state.cart.meals.some((meal) => {
    return !!meal.discount
  })
  const mealsPromotionalPrices = state.cart.meals.some((meal) => {
    return !!meal.promotionalPrice
  })
  return mealsDiscounts || mealsPromotionalPrices
}
const isCartDiscountCode = (state) => {
  return !!state.cart.discountChoice && !!state.cart.discountCode
}

const getServiceChargePercent = (state) => {
  if (state.cart.serviceChargePercent) {
    return state.cart.serviceChargePercent
  }
  return 0
}

const cartTotalPrice = (state, getters) => {
  // TOTAL PRICE OF CART - meals + all discounts
  // result is used to calculate deliveryPrice and delivery area warning
  const { meals, discountChoice, discountValue, packagePrice, deliveryPrice } = state.cart
  const { tip } = state.order
  let totalPrice = new Decimal(0)
  if (meals) {
    meals.forEach(function (meal) {
      totalPrice = totalPrice.plus(meal.price)
    })
  }

  // APPLYING DISCOUNTS
  if (discountChoice) {
    if (discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_FREE_DELIVERY && deliveryPrice) {
      totalPrice = totalPrice.minus(deliveryPrice)
    } else {
      if (discountValue) {
        if (discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PERCENT) {
          totalPrice = totalPrice.minus(totalPrice.times(discountValue).dividedBy(100))
        } else if (discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_PROMO_PRICE) {
          totalPrice = new Decimal(discountValue)
        } else if (discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_VALUE) {
          totalPrice = totalPrice.minus(discountValue)
        }
      }
    }
  }

  // Add tip to cart total price
  if (tip) {
    totalPrice = totalPrice.plus(tip)
  }
  // Add package price to cart total price
  if (packagePrice) {
    totalPrice = totalPrice.plus(packagePrice)
  }
  totalPrice = totalPrice.toFixed(2)
  return totalPrice
}

const orderTotalPrice = (state, getters) => {
  // TOTAL PRICE OF ORDER - cart price + delivery + service charge
  let totalPrice = Decimal(getters.cartTotalPrice)

  const { deliveryPrice, serviceChargePercent } = state.cart

  if (deliveryPrice) {
    totalPrice = totalPrice.plus(deliveryPrice)
  }
  if (serviceChargePercent) {
    let scPercent = new Decimal(serviceChargePercent)
    state.cart.serviceChargeValue = scPercent.times(totalPrice).dividedBy(100).toFixed(2)
    totalPrice = totalPrice.plus(state.cart.serviceChargeValue)
  }
  totalPrice = totalPrice.toFixed(2)
  return totalPrice
}

const getDeliveryPriceFromArea = (state, getters) => {
  if (state.deliveryArea) {
    if (state.deliveryArea === 'processing') return 'processing'
    // if cart price reached free delivery value
    else if (
      state.deliveryArea.freeDeliveryFromValue &&
      Decimal(state.deliveryArea.freeDeliveryFromValue).lessThanOrEqualTo(Decimal(getters.cartTotalPrice))
    ) {
      return Decimal('0').toFixed(2)
      // otherwise set price from area data
    } else return state.deliveryArea.deliveryPrice
  } else return null
}

const deliveryPrice = (state, getters) => {
  let deliveryPrice = null
  // if pickup_type changed to on_site/pickup to delivery - should clean areaWarning, deliveryPrice and update cart price
  // empty deliveryPrice
  if ([PICKUP_TYPE_ON_SITE, PICKUP_TYPE_TAKOUT].includes(state.cart.customer.pickupType)) {
    deliveryPrice = null
    // if price was set manually
  } else if (state.cart.manualDeliveryPrice !== null) {
    deliveryPrice = state.cart.manualDeliveryPrice
    // if deliveryArea is null - empty deliveryPrice
  } else if (state.deliveryArea === null) {
    deliveryPrice = null
    // otherwise if deliveryArea is set
  } else if (state.deliveryArea && state.deliveryArea.deliveryPrice) {
    deliveryPrice = getters.getDeliveryPriceFromArea
  }
  return deliveryPrice
}

const activeMenuNormalMealSets = (state, getters, rootState, rootGetters) => {
  return rootGetters['_menu/mealSets'].filter(
    (mealSet) => mealSet.menu === rootGetters.menu.id && mealSet.type === MEALSET_TYPES.MEAL_SET
  )
}

const activeMenuHalfPizzaMealSets = (state, getters, rootState, rootGetters) => {
  return rootGetters['_menu/mealSets'].filter(
    (mealSet) => mealSet.menu === rootGetters.menu.id && mealSet.type === MEALSET_TYPES.HALF_PIZZA
  )
}

const getCartTotalPriceChanged = (state) => {
  return state.cartTotalPriceChanged
}

const mobileViewActive = (state) => state.mobileViewActive

const editOrderRequestStatus = (state) => state.editOrderRequestStatus

const getCartDiscounts = (state) => {
  const { cart } = state
  let discountChoice = null
  let discountValue = new Decimal(0)
  if (cart.discountChoice) {
    if (cart.discountChoice === DISCOUNT_CHOICES.DISCOUNT_CHOICE_FREE_DELIVERY) {
      discountChoice = cart.discountChoice
    } else if (cart.discountValue !== null && cart.discountValue !== '') {
      if (
        cart.discountChoice !== DISCOUNT_CHOICES.DISCOUNT_CHOICE_PROMO_PRICE &&
        new Decimal(cart.discountValue).isZero()
      ) {
        discountChoice = null
      } else {
        discountChoice = cart.discountChoice
      }
      if (discountChoice) discountValue = UtilsMixins.methods.priceDecimal(cart.discountValue)
    }
  }
  return { discountChoice, discountValue }
}

const deliveryAreaLoading = (state) => state.deliveryAreaLoading

export default {
  cart,
  cartNumber,
  isEdit,
  showMealsMenu,
  showOrderForm,
  orderOnSite,
  externalOrder,
  mealSaved,
  courierDeliveryArea,
  isAnyMealDiscountOrPromo,
  isCartDiscountCode,
  getServiceChargePercent,
  cartTotalPrice,
  estimatedDeliveryTime,
  orderTotalPrice,
  getDeliveryPriceFromArea,
  deliveryPrice,
  activeMenuNormalMealSets,
  activeMenuHalfPizzaMealSets,
  mobileViewActive,
  getCartTotalPriceChanged,
  editOrderRequestStatus,
  getCartDiscounts,
  deliveryAreaLoading,
}
